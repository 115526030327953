import React from "react";
// import './Page1.scss'

const Page1Text = () => {
  return (
    <>
       <div className="text_container">
          <div className="text">
            <span>Unlocking small businesses</span>
            <br />
            <span>
              of <span className="color_text">cash flow</span> burden
            </span>
          </div>
        </div>
      
    </>
  );
};

export default Page1Text;
