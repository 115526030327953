import React from "react";
import './Life_1.scss'

import boyiImg from '../../assets/Life/Life-1/boyi 1.png'
import cloud1 from '../../assets/Life/Life-1/cloud-1.png'
import cloud2 from '../../assets/Life/Life-1/cloud-2.png'

const Life_1 = () => {
  return (
    <div className="Life_1_container">
      {/* <div>
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://bulma.io">
              <span>NAKAD</span>
            </a>

            <a
              role="button"
              class="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasicExample" class="navbar-menu">
            <div class="navbar-end">
              <a class="navbar-item">Home</a>
              <a class="navbar-item">Life at Nakad</a>
              <button class="button">Join Us</button>
              
            </div>
          </div>
        </nav>
      </div> */}
      <div className="bottom">
        <div className="left">
          <div className="img_box">
            <img src={cloud2} alt="" className="cloud2" />
            <img src={cloud1} alt="" className="cloud1" />
          </div>
          <div className="text_box">
            <p>
              <span className="color_text">Join us </span>in building a{" "}
            </p>
            <p>hectocorn in </p>
            <p>
              <span className="color_text">supply chain!</span>
            </p>
          </div>
          <div className="text_box_mobile">
            <div className="text">
            <span className="color_text">Join us </span>in building a hectocorn in
            <span className="color_text"> supply chain!</span>
            </div>
            
          </div>
        </div>
        <div className="right">
          <img src={boyiImg} alt="" className="boyiImg" />
        </div>
      </div>
    </div>
  );
};

export default Life_1;
