import React, { Component, useState } from "react";
import Slider from "react-slick";

import ReadMore from "./ReadMore";

import "./Life_4.scss";

import image from "../../assetsSvg/Life/Mind/image-(55).svg";
import twitter from "../../assetsSvg/Life/Mind/twitter.svg";
import linkedin from "../../assetsSvg/Life/Mind/linkedin.svg";



export default class SlideChangeHooks extends Component {
  state = {
    oldSlide: 0,
    activeSlide: 0,
    activeSlide2: 0,
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: (current) => this.setState({ activeSlide2: current }),
    };
    return (
      <div>
        <h2>beforeChange and afterChange hooks</h2>
        <p>
          BeforeChange oldSlide: <strong>{this.state.oldSlide}</strong>
        </p>
        <p>
          BeforeChange activeSlide: <strong>{this.state.activeSlide}</strong>
        </p>
        <p>
          AfterChange activeSlide: <strong>{this.state.activeSlide2}</strong>
        </p>
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div>
            <div className="card1">
              <div className="bottom1">
                <h5 className="name">Ujawal Kalra</h5>
                <p className="text">Co-founder & CEO</p>
                <p className="img_box">
                  <div className="circle">
                    <img src={linkedin} alt="" />
                  </div>
                  <div className="circle" style={{ marginLeft: "10px" }}>
                    <img src={twitter} alt="" />
                  </div>
                </p>
              </div>
              <div>
                <ReadMore index="0" activeSlide={this.activeSlide}>
                  Ujwal Kalra is the CEO building this massive machine, NAKAD.
                  Earlier, he worked at the Boston Consulting Group (BCG), where
                  he was a founding member of Growth Tech – BCG’s foray into the
                  startup world. Ujwal graduated from IIM Ahmedabad where he was
                  an Institute Scholar. He completed engineering from IIT
                  Guwahati and schooling from DPS Vasant Kunj. Ujwal is the
                  co-author of Startup Compass, a HarperCollins publication with
                  foreword from Narayana Murthy. He is recipient of The Economic
                  Times Young Leader Award, O.P. Jindal Engineering and
                  Management Scholarship, Dun & Bradstreet Award and Dunia
                  Scholarship, UAE. He also represented India at Kairos USA, an
                  entrepreneurship society.
                </ReadMore>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="bottom1">
                <h5 className="name">Sambhav Jain</h5>
                <p className="text">Co-founder & COO</p>
                <p className="img_box">
                  <div className="circle">
                    <img src={linkedin} alt="" />
                  </div>
                  <div className="circle" style={{ marginLeft: "10px" }}>
                    <img src={twitter} alt="" />
                  </div>
                </p>
              </div>
              <div>
                <ReadMore index="1" activeSlide={this.activeSlide}>
                  Ujwal Kalra is the CEO building this massive machine, NAKAD.
                  Earlier, he worked at the Boston Consulting Group (BCG), where
                  he was a founding member of Growth Tech – BCG’s foray into the
                  startup world. Ujwal graduated from IIM Ahmedabad where he was
                  an Institute Scholar. He completed engineering from IIT
                  Guwahati and schooling from DPS Vasant Kunj. Ujwal is the
                  co-author of Startup Compass, a HarperCollins publication with
                  foreword from Narayana Murthy. He is recipient of The Economic
                  Times Young Leader Award, O.P. Jindal Engineering and
                  Management Scholarship, Dun & Bradstreet Award and Dunia
                  Scholarship, UAE. He also represented India at Kairos USA, an
                  entrepreneurship society.
                </ReadMore>
              </div>
            </div>
          </div>
          <div  >
            <div className="card1">
              <div className="bottom1">
                <h5 className="name">Avinash Uttav</h5>
                <p className="text">Co-founder & CTO</p>
                <p className="img_box">
                  <div className="circle">
                    <img src={linkedin} alt="" />
                  </div>
                  <div className="circle" style={{ marginLeft: "10px" }}>
                    <img src={twitter} alt="" />
                  </div>
                </p>
              </div>
              <div>
                <ReadMore index="2" activeSlide={this.activeSlide}>
                  Ujwal Kalra is the CEO building this massive machine, NAKAD.
                  Earlier, he worked at the Boston Consulting Group (BCG), where
                  he was a founding member of Growth Tech – BCG’s foray into the
                  startup world. Ujwal graduated from IIM Ahmedabad where he was
                  an Institute Scholar. He completed engineering from IIT
                  Guwahati and schooling from DPS Vasant Kunj. Ujwal is the
                  co-author of Startup Compass, a HarperCollins publication with
                  foreword from Narayana Murthy. He is recipient of The Economic
                  Times Young Leader Award, O.P. Jindal Engineering and
                  Management Scholarship, Dun & Bradstreet Award and Dunia
                  Scholarship, UAE. He also represented India at Kairos USA, an
                  entrepreneurship society.
                </ReadMore>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="bottom1">
                <h5 className="name">4Ujawal Kalra</h5>
                <p className="text">Co-founder & CEO</p>
                <p className="img_box">
                  <div className="circle">
                    <img src={linkedin} alt="" />
                  </div>
                  <div className="circle" style={{ marginLeft: "10px" }}>
                    <img src={twitter} alt="" />
                  </div>
                </p>
              </div>
              <div>
                <ReadMore index="3" activeSlide={this.activeSlide2}>
                  Ujwal Kalra is the CEO building this massive machine, NAKAD.
                  Earlier, he worked at the Boston Consulting Group (BCG), where
                  he was a founding member of Growth Tech – BCG’s foray into the
                  startup world. Ujwal graduated from IIM Ahmedabad where he was
                  an Institute Scholar. He completed engineering from IIT
                  Guwahati and schooling from DPS Vasant Kunj. Ujwal is the
                  co-author of Startup Compass, a HarperCollins publication with
                  foreword from Narayana Murthy. He is recipient of The Economic
                  Times Young Leader Award, O.P. Jindal Engineering and
                  Management Scholarship, Dun & Bradstreet Award and Dunia
                  Scholarship, UAE. He also represented India at Kairos USA, an
                  entrepreneurship society.
                </ReadMore>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="bottom1">
                <h5 className="name">5Ujawal Kalra</h5>
                <p className="text">Co-founder & CEO</p>
                <p className="img_box">
                  <div className="circle">
                    <img src={linkedin} alt="" />
                  </div>
                  <div className="circle" style={{ marginLeft: "10px" }}>
                    <img src={twitter} alt="" />
                  </div>
                </p>
              </div>
              <div>
                <ReadMore index="4" activeSlide={this.activeSlide2}>
                  Ujwal Kalra is the CEO building this massive machine, NAKAD.
                  Earlier, he worked at the Boston Consulting Group (BCG), where
                  he was a founding member of Growth Tech – BCG’s foray into the
                  startup world. Ujwal graduated from IIM Ahmedabad where he was
                  an Institute Scholar. He completed engineering from IIT
                  Guwahati and schooling from DPS Vasant Kunj. Ujwal is the
                  co-author of Startup Compass, a HarperCollins publication with
                  foreword from Narayana Murthy. He is recipient of The Economic
                  Times Young Leader Award, O.P. Jindal Engineering and
                  Management Scholarship, Dun & Bradstreet Award and Dunia
                  Scholarship, UAE. He also represented India at Kairos USA, an
                  entrepreneurship society.
                </ReadMore>
              </div>
            </div>
          </div>
          <div></div>
        </Slider>
      </div>
    );
  }
}
