import React from "react";
import "../PrivacyPolicy/PrivacyPolicy.scss";
import "../Disclaimer/Disclaimer.scss";
import "./Faqs.scss";

const Faqs = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="">
          <div className="card">
            <div className="card-body card_shadow text_align_justify">
              <h1 className="text-center privacy_header">
                Frequently Asked Questions
              </h1>
              <section className="privacy_sub_header_section disclaimer_sec faq_sec">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        What is Invoice Discounting?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Invoice discounting is the practice of using a company's
                        unpaid Invoices to raise working capital and fulfil its
                        Financial needs.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        How Does NAKAD operate?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        NAKAD is a digital Supply Chain Finance Platform. It
                        provides a technology solution to the parties involved
                        and facilitates the entire process, such as providing a
                        dashboard to the User to request for early payment as
                        well as sharing the information with the Lending
                        Partners who are onboarded on our platform. NAKAD
                        provides end to end solution, right from the early
                        payment request till the repayment.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        What is the Eligibility Criteria?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Any business who supplies goods or services to a
                        customer / Anchor who is on our platform and has been
                        recommended by such customer / Anchor can avail Early
                        Payment. Eligibility and amount of discounting is
                        governed by the creditworthiness of the business and
                        therefore they should be willing to share their
                        financial information and other related documents.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        Will my information be shared with Third Parties?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        We have a very stringent Data Privacy Policy. We will
                        only share your information with third parties such as
                        Banks / NBFCs with your prior consent for facilitating
                        the Credit Assessment and Sanction of the Facility/ies.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        What is the maximum Invoice Tenure?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFive"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Tenure will be based on the Payment terms with the
                        customer / Anchor upto a maximum of 120 days.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix"
                        aria-expanded="false"
                        aria-controls="flush-collapseSix"
                      >
                        What are the charges?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingSix"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Applicable charges will be conveyed to the customer
                        through an Offer Letter and Key Fact Statement. This
                        will also be part of the Sanction Letter which the
                        Lender will issue.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSeven"
                        aria-expanded="false"
                        aria-controls="flush-collapseSeven"
                      >
                        How can I benefit from this?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingSeven"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div>
                          Invoice discounting enables businesses to raise
                          working capital at the tap of a button. Some of the
                          benefits of our program are as below.
                        </div>
                        <div>
                          <ul className="fs_16">
                            <li>Access to cheap funds as and when required.</li>
                            <li>Better Risk Management</li>
                            <li>
                              Quick turnaround time (since everything digitized)
                            </li>
                            <li>Collateral Free</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseEight"
                        aria-expanded="false"
                        aria-controls="flush-collapseEight"
                      >
                        Who are the Lenders on NAKAD?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingEight"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        We have multiple Lenders on our platform, such as major
                        private and public sectors Banks, NBFCs and Alternate
                        Investment Platforms providing investment opportunities
                        to HNIs.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseNine"
                        aria-expanded="false"
                        aria-controls="flush-collapseNine"
                      >
                        What is the level of involvement required from the
                        Vendors?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingNine"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        The only involvement required from the Vendors, post
                        onboarding on our platform is to log in to our Portal
                        and raise the request for Early Payment.
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
