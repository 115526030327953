import React, { useState } from "react";
import "../../components/Life/Life_2";
import Carousel from "react-multi-carousel";
import "./Benefits.scss"

import Ellipse15 from "../../assetsSvg/Life/Whats/Ellipse 15.svg";

import zeroLeakageImg from "../../assetsPng/Arp/zero leakage.png"
import compliance from "../../assetsPng/Arp/Compliance.png"
import productivity from "../../assetsPng/Arp/productivity.png"
import Visibility from "../../assetsPng/Arp/Visibility.png"




const Benefits = () => {
    const responsive = {
          superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
          },
          desktop: {
            breakpoint: { max: 3000, min: 481 },
            items: 1,
          },
          tablet: {
            breakpoint: { max: 1024, min: 480 },
            items: 1,
          },
          mobile: {
            breakpoint: { max: 992, min: 0 },
            items: 1,
          },
        };

  const [part, setPart] = useState(true);
  const [change, setChange] = useState(false);
  const [clarity, setClarity] = useState(false);
  const [indep, setIndep] = useState(false);

  const changeHandler1 = () => {
    setPart(true);
    setChange(false);
    setClarity(false);
    setIndep(false);
  };

  const changeHandler2 = () => {
    setPart(false);
    setChange(true);
    setClarity(false);
    setIndep(false);
  };
  const changeHandler3 = () => {
    setPart(false);
    setChange(false);
    setClarity(true);
    setIndep(false);
  };
  const changeHandler4 = () => {
    setPart(false);
    setChange(false);
    setClarity(false);
    setIndep(true);
  };


  return (
    <div className="Benefits_container">
      <div>
        <title className="heading">
         <span className="rect_line_left"></span> <span className="color_text">Benefits</span>  <span className="rect_line_right"></span>
        </title>
      </div>

      <div className="whats_container">

        <div className="first">
          <div className="inside_first" style={{ opacity: part ? 100 : 0 }}>
            <div className="first_text">
            <span className="color_text"> No bottomline</span> <br />{" "}
            <span className="color_text">impact</span> from <br />
            unforeseen errors
            </div>
          </div>
          <div className="inside_first">
            <div className="first_text" style={{ opacity: change ? 100 : 0 }}>
            
              <span className="color_text">
              Faster monthly <br />
              closing </span> and audit
               <br /> completion!
            </div>
          </div>
          <div className="inside_first">
            <div className="first_text" style={{ opacity: clarity ? 100 : 0 }}>

              <span className="color_text">Free up your team</span> <br />
              <span className="color_text">bandwidth </span> for <br />
             for strategic work
            </div>
          </div>
          <div className="inside_first">
            <div className="first_text" style={{ opacity: indep ? 100 : 0 }}>
            <span className="color_text">Insights at your</span><br /> {" "}
            <span className="color_text"> fingertips to </span> aid <br />
data-driven <br /> decision making
            </div>
          </div>
        </div>

        <div className="second">
          <div>
            <img src={Ellipse15} alt="" className="ellipse15" />
          </div>
          <div>
            <div className="inside_second1">
              <div
                className={
                  part ? "circle_active ellipse20" : "circle ellipse20"
                }
                name="part"
                onClick={changeHandler1}
              ></div>
              <div
                className={
                  part
                    ? "circle_text_active ellipse20"
                    : "circle_text ellipse20"
                }
                onClick={changeHandler1}
              >
               Zero Leakages in AR/AP
              </div>
            </div>
            <div className="inside_second2">
              <div
                className={
                  change ? "circle_active ellipse16" : "circle ellipse16"
                }
                name="change"
                onClick={changeHandler2}
              ></div>
              <div
                className={
                  change
                    ? "circle_text_active ellipse16"
                    : "circle_text ellipse16"
                }
                onClick={changeHandler2}
              >
                Compliance and Control
              </div>
            </div>
            <div className="inside_second3">
              <div
                className={
                  clarity ? "circle_active ellipse23" : "circle ellipse23"
                }
                onClick={changeHandler3}
              ></div>
              <div
                className={
                  clarity
                    ? "circle_text_active ellipse23"
                    : "circle_text ellipse23"
                }
                onClick={changeHandler3}
              >
                Improve your team’s productivity
              </div>
            </div>
            <div className="inside_second4">
              <div
                className={
                  indep ? "circle_active ellipse17" : "circle ellipse17"
                }
                onClick={changeHandler4}
              ></div>
              <div
                className={
                  indep
                    ? "circle_text_active ellipse17"
                    : "circle_text ellipse17"
                }
                onClick={changeHandler4}
              >
                Heightened Visibility
              </div>
            </div>
          </div>
        </div>

        <div className="third">
         

          <div className="inside_third">
            <img
              src={zeroLeakageImg}
              alt=""
              className="partImg1"
              style={{ opacity: part ? 100 : 0 }}
            />
          </div>
          <div className="inside_third">
            <img
              src={compliance}
              alt=""
              className="partImg2"
              style={{ opacity: change ? 100 : 0 }}
            />
          </div>
          <div className="inside_third">
            <img
              src={productivity}
              alt=""
              className="partImg3"
              style={{ opacity: clarity ? 100 : 0 }}
            />
          </div>
          <div className="inside_third">
            <img
              src={Visibility}
              alt=""
              className="partImg4"
              style={{ opacity: indep ? 100 : 0 }}
            />
          </div>
        </div>
      </div>

      {/* mobile-------- */}
      <div className="whats_container_mobile">
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={300000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <div>
            <div className="card1">
              <div className="circle_text_active">
              Zero Leakages in AR/AP
              </div>
              <div className="center_align_ben">
                <img src={zeroLeakageImg} alt="" className="partImg1" />
              </div>
              <div className="first_text biggest">
                <span className="color_text">No bottomline impact</span> from{" "}
                <br /> unforeseen errors
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="circle_text_active">Compliance and Control</div>
              <div className="center_align_ben">
                <img src={compliance} alt="" className="partImg2" />
              </div>
              <div className="first_text changing">
                <span className="color_text">Faster monthly closing </span>and <br />
                audit completion!
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="circle_text_active">Improve your team’s productivity</div>
              <div className="center_align_ben">
                <img src={productivity} alt="" className="partImg3" />
              </div>
              <div className="first_text clarity">
              Free up your 
                <span className="color_text"> team bandwidth</span> <br />
                for strategic work
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="circle_text_active">Heightened Visibility</div>
              <div className="center_align_ben">
                <img src={Visibility} alt="" className="partImg4" />
              </div>
              <div className="first_text independence">
              Insights at your fingertips to aid <br />
                <span className="color_text">data-driven decision </span>making
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Benefits;
