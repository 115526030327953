import React from "react";
import "./Page_8.scss";

const Page_8 = () => {
  return (
    <div className="Page_8_container">
      <div>
        <div className="text_container">
          <div className="text">
            <span>Backed by World’s</span>
            <br />
            <span className="color_text">top VC firms</span>  
          </div>
          <div>
              <span>DETAILS COMING SOON</span>
          </div>
        </div>
        <div></div>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Page_8;
