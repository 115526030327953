import React from 'react'
import "./Testimonials.scss"

const Testimonials = () => {
  return (
    <div className='testimonials_container'>
        <div className='center_align'>
            <div className='rect_line'></div>
        </div>
        <div>
        <title className="heading">
         <span className="rect_line_left"></span> <span className="color_text">Client Testimonials</span>  <span className="rect_line_right"></span>
        </title>
      </div>

        <div className="container pb_60">
      <div className="row">
        {/* For large screens (col-lg) */}
        <div className="col-lg-4 mb-2">
          <div className="card">
            <div className="card-body">
               <div>
              <p className="card-text testimonials_text">“After implementing ARP, we have seen reduction in mistakes in our quarterly and yearly closing activities. My team’s bandwidth is unlocked to focus on strategic tasks and our adherence to compliance tasks have never been so high!”</p>
              </div>
              <div>
                <p className='testimonials_name'>Finance Controller</p>
                <p className='testimonials_comp'>@TATA Group Company</p>
              </div>
             
            
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-2">
          <div className="card">
            <div className="card-body">
            <div>
              <p className="card-text testimonials_text">"Balance Confirmation through ARP is fully automated and customizable as per our auditors requirement. They have implemented this product company-wide at large scale within 7 days"</p>
</div>
              <div>
                <p className='testimonials_name'>Head of Finance</p>
                <p className='testimonials_comp'>@Instamart</p>
              </div>
             
            
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-2">
          <div className="card">
            <div className="card-body">
            <div>
              <p className="card-text testimonials_text">"With Section 43 B(h) coming in, ARP has helped us cover the audit requirements for MSME Confirmation and Balance Confirmation within a short span of time"</p>
</div>
              <div>
                <p className='testimonials_name'>VP-Finance</p>
                <p className='testimonials_comp'>@Zetwerk</p>
              </div>
             
            
            </div>
          </div>
        </div>
       
       

    
      </div>
    </div>

    <div className='center_align'>
            <div className='rect_line'></div>
        </div>
    </div>
  )
}

export default Testimonials