import React, {useEffect} from 'react'
import {Helmet} from "react-helmet";
// import { useEffect } from 'react/cjs/react.production.min'
import Life_1 from './Life_1'
import Life_2 from './Life_2'
import Life_3 from './Life_3'
import Life_4 from './Life_4'
import Life_4a from './Life_4a'
import Life_4acl from './Life_4acl'
import Life_4acl2 from './Life_4acl2'
import Life_5 from './Life_5'
import Life_6 from './Life_6'
import Life_7 from './Life_7'
import Media from './Media';

const Life = () => {
  useEffect(() => {
    document.body.style.overflowY = 'auto'
    document.body.style.overflowX = 'hidden'
  },[])
  return (
    <div style={{overflowX:'hidden', overflowY:'auto'}}>
         <Helmet>
            <title>Life at NAKAD</title>
            <meta name="description" content="Unlocking small businesses of cash flow burden with unique and proprietary MicroBill technology enabling lending and loans to MSMEs through invoice discounting, invoice factoring, invoice reverse factoring and thereby getting early payment. The software has in-built reconciliation for account receivable, account payable, payments, debit note, credit note and banking." />
        </Helmet>
        <Life_1 />
        <Life_2 />
        <Life_3 />
        <Life_4 />
        {/* <Life_4a /> */}
        {/* <Life_4acl /> */}
        {/* <Life_4acl2 /> */}
        <Life_5 />
        <Life_6 />
        <Media />
        <Life_7 />
    </div>
  )
}

export default Life