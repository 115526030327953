import React, { useEffect, useState } from "react";
// import Ticker from 'framer-motion-ticker';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useInView } from "react-intersection-observer";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img4 from "./../../assetsPng/Arp/image 4.png";
import img5 from "./../../assetsPng/Arp/image 5.png";
import img6 from "./../../assetsPng/Arp/image 6.png";
import img7 from "./../../assetsPng/Arp/image 7.png";
import img8 from "./../../assetsPng/Arp/image 8.png";
import img9 from "./../../assetsPng/Arp/image 9.png";
import motherson from "./../../assetsPng/Arp/motherson.png";
import purple from "./../../assetsPng/Arp/purple.png";
import zydus from "./../../assetsPng/Arp/zydus.png";
import whole from "./../../assetsPng/Arp/whole.png";
import bombay from "./../../assetsPng/Arp/bombay.png";


import client1 from "./../../assetsPng/Arp/client1.png";
import client2 from "./../../assetsPng/Arp/client2.png";
import client3 from "./../../assetsPng/Arp/client3.png";
import client4 from "./../../assetsPng/Arp/client4.png";

import section_43 from "./../../assetsPng/Arp/error_24px.png";

import "./Client.scss";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
          }
        }
      ]
  };

const Client = () => {
    const imgArr = [motherson, img4, img5, img6, img8, img7, img9, purple, zydus, whole, bombay]
    const [currentValue1, setCurrentValue1] = useState(0);
    const [currentValue2, setCurrentValue2] = useState(0);
    const [currentValue3, setCurrentValue3] = useState(0);
    const [currentValue4, setCurrentValue4] = useState(0);
    const { ref, inView } = useInView({ triggerOnce: true });

    const NumberCounter1 = (endValue ) => {
        useEffect(() => {
          if (inView && currentValue1 < endValue) {
            
            const increment = Math.ceil(endValue / 100); // Increase by a fraction of the end value
            const timer = setInterval(() => {
              setCurrentValue1((prevValue) => Math.min(prevValue + increment, endValue));
            }, 60); // Adjust speed of the counter as needed
      
            return () => clearInterval(timer);
          }
        }, [inView, currentValue1, endValue]);

         // Format the currentValue1 to Indian number format
  const formattedValue = currentValue1.toLocaleString("en-IN");
      
        return <div ref={ref} className="number">{formattedValue}+</div>;
      };
    const NumberCounter2 = (endValue ) => {
        useEffect(() => {
          if (inView && currentValue2 < endValue) {
            
            const increment = Math.ceil(endValue / 100); // Increase by a fraction of the end value
            const timer = setInterval(() => {
              setCurrentValue2((prevValue) => Math.min(prevValue + increment, endValue));
            }, 60); // Adjust speed of the counter as needed
      
            return () => clearInterval(timer);
          }
        }, [inView, currentValue2, endValue]);
        const formattedValue = currentValue2.toLocaleString("en-IN");
      
        return <div ref={ref} className="number">{formattedValue}+</div>;
      };
    const NumberCounter3 = (endValue ) => {
        useEffect(() => {
          if (inView && currentValue3 < endValue) {
          
            const increment = Math.ceil(endValue / 100); // Increase by a fraction of the end value
            const timer = setInterval(() => {
              setCurrentValue3((prevValue) => Math.min(prevValue + increment, endValue));
            }, 60); // Adjust speed of the counter as needed
      
            return () => clearInterval(timer);
          }
        }, [inView, currentValue3, endValue]);
        const formattedValue = currentValue3.toLocaleString("en-IN");
      
        return <div ref={ref} className="number">{formattedValue}+</div>;
      };
    const NumberCounter4 = (endValue ) => {
        useEffect(() => {
          if (inView && currentValue4 < endValue) {
           
            const increment = Math.ceil(endValue / 100); // Increase by a fraction of the end value
            const timer = setInterval(() => {
              setCurrentValue4((prevValue) => Math.min(prevValue + increment, endValue));
            }, 60); // Adjust speed of the counter as needed
      
            return () => clearInterval(timer);
          }
        }, [inView, currentValue4, endValue]);
        // Format the currentValue1 to Indian number format
  const formattedValue = currentValue4 > 91000 ? "2.1L Cr" : currentValue4.toLocaleString("en-IN");
      
        return <div ref={ref} className="number">{formattedValue}+</div>;
      };

  return (
    <div className="client_container">
      {/* <div className="client_center_align" >
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={2000}
          // swipeable={true}
          // draggable={true}
          // // customTransition="transform 1500ms"  // Specify the transition property and duration
          transitionDuration={500} // Set the duration in milliseconds
          // // showDots={true}
          infinite={true}
          arrows={false}
          partialVisible={false}
          // // dotListClass="custom-dot-list-style"

          // infinite: true,
          // autoPlay: true,
          // autoPlaySpeed: 2000, // Adjust autoplay speed as needed
          // transitionDuration: 500, // Adjust transition duration as needed
          // arrows: false, // Hide arrows if needed
          // showDots: false // Hide dots if needed
        >
          <div>
            <img src={img4} alt="" />
          </div>
          <div>
            <img src={img5} alt="" />
          </div>
          <div>
            <img src={img6} alt="" />
          </div>
          <div>
            <img src={img7} alt="" />
          </div>
          <div>
            <img src={img8} alt="" />
          </div>
          <div>
            <img src={img9} alt="" />
          </div>
        </Carousel>
      </div> */}
      {/* <div>
      <Ticker duration={20}>
        {imgArr.map((item, index) => (
          <div
            key={index}
          >
             <img src={item} alt="" />
          </div>
        ))}
      </Ticker>
      </div> */}

<div className="slider-container" style={{overflow:'hidden'}}>
      <Slider {...settings}>
      {imgArr.map((item, index) => (
          <div
            key={index}
            className="slider-container-div"

          >
             <img src={item} alt="" />
          </div>
        ))}
       </Slider>
    </div>


      <div className="bg_prp">
      <div className="container">
      <div className="row">

      <div className="col-xl-3 col-6 mb-4 center_align_client_box">
      <div className="client_box mr_20" style={{ position: "relative" }}>
            <div className="mt_30">
              {/* <div className="number">{NumberCounter(170)}+</div> */}
              <div>{NumberCounter1(170)}</div>
              <div className="mt_12 text">
Clients
              </div>
            </div>
            <img
              src={client1}
              alt=""
              style={{ position: "absolute", bottom: "-3px", right: "-18px" }}
              className="client1"
            />
          </div>
        </div>
      <div className="col-xl-3 col-6 mb-4 center_align_client_box">
      <div className="client_box mr_20" style={{ position: "relative" }}>
            <div className="mt_30">
              {/* <div className="number">{NumberCounter(64000)}+</div> */}
              <div>{NumberCounter2(64000)}</div>
              <div className="mt_12 text">
              Vendors + Customers Onboarded
              </div>
            </div>
            <img
              src={client2}
              alt=""
              style={{ position: "absolute", bottom: "-23px", right: "-19px" }}
              className="client2"
            />
          </div>
        </div>

        <div className="col-xl-3 col-6 mb-4 center_align_client_box">
        <div className="client_box mr_20" style={{ position: "relative" }}>
            <div className="mt_30">
              {/* <div className="number">90,000+</div> */}
              <div>{NumberCounter3(90000)}</div>
              <div className="mt_12 text">
Ledgers Reconciled
              </div>
            </div>
            <img
              src={client3}
              alt=""
              style={{ position: "absolute", bottom: "-19px", right: "-15px" }}
              className="client3"
            />
          </div>
            </div>

        <div className="col-xl-3 col-6 mb-4 center_align_client_box">
        <div className="client_box mr_20" style={{ position: "relative" }}>
            <div className="mt_30">
              {/* <div className="number">2.1L Cr+</div> */}
              <div >{NumberCounter4(92000)}</div>
              <div className="mt_12 text" >
Value Reconciled
              </div>
            </div>
            <img
              src={client4}
              alt=""
              style={{ position: "absolute", bottom: "-25px", right: "-36px" }}
              className="client4"
            />
          </div>
            </div>

      </div>
        </div>

        {/* <div style={{ display: "flex" }}>
          <div className="client_box mr_20" style={{ position: "relative" }}>
            <div className="mt_30">
              <div className="number">170+</div>
              <div className="mt_12 text">
Clients
              </div>
            </div>
            <img
              src={client1}
              alt=""
              style={{ position: "absolute", bottom: "-3px", right: "-18px" }}
              className="client1"
            />
          </div>
          <div className="client_box mr_20" style={{ position: "relative" }}>
            <div className="mt_30">
              <div className="number">64,000+</div>
              <div className="mt_12 text">
MSME Vendors Onboarded
              </div>
            </div>
            <img
              src={client2}
              alt=""
              style={{ position: "absolute", bottom: "-36px", right: "-19px" }}
              className="client1"
            />
          </div>
        </div> */}

        {/* <div style={{ display: "flex" }}>
        <div className="client_box mr_20" style={{ position: "relative" }}>
            <div className="mt_30">
              <div className="number">90,000+</div>
              <div className="mt_12 text">
Ledgers Reconciled
              </div>
            </div>
            <img
              src={client3}
              alt=""
              style={{ position: "absolute", bottom: "-19px", right: "-15px" }}
              className="client1"
            />
          </div>
          <div className="client_box mr_20" style={{ position: "relative" }}>
            <div className="mt_30">
              <div className="number">2.1L Cr+</div>
              <div className="mt_12 text" >
Value Reconciled
              </div>
            </div>
            <img
              src={client4}
              alt=""
              style={{ position: "absolute", bottom: "-25px", right: "-36px" }}
              className="client1"
            />
          </div>
        </div> */}

      </div>

{/* section 43bh commented for now */}
      {/* <div className="bg_white">
        <div className="bg_white_border">
        <div className="center_align">
            <span><img src={section_43} alt="" /></span>
            <span className="section_43_bh">Section 43BH</span>
        </div>
        <div className="section_43_bh_text center_align">
        In in at congue semper rutrum magna. Risus a in aenean amet eleifend gravida in fermentum.
         In in at congue semper rutrum magna. Risus a in aenean amet eleifend gravida in fermentum.
        </div>
        </div>
      </div> */}
    </div>
  );
};

export default Client;
