import React from 'react'
import arp from '../../assetsPng/Arp/Arp.png'
import arpImg from '../../assetsPng/Arp/Group 27.png'
import './ArpService.scss'
// import '@dotlottie/player-component';

const ArpService = () => {
  return (
    <div>
        <div className="Arp_service_container">
      <div className="bottom">
        <div className="left">
          <div className="img_box">
          
            <span className='arp_img_bg'>
            <img src={arp} alt="" />
            </span>
           <span className='arp_text'>ARP</span>
          </div>
          <div className='service_text'>
          Reconciliation-as-a-Service
          </div>
          <div className='service_text_line'></div>
          <div className="text_box">
            <span>India's leading</span><br/>
            
              <span className="color_text">AR/AP <br className="mobile-br" /> Reconciliation</span><br/>
            
            <span>Service Provider</span>
          </div>
          {/* <div className="text_box_mobile">
            <div className="text">
            <span className="color_text">Join us </span>in building a hectocorn in
            <span className="color_text"> supply chain!</span>
            </div>
            
          </div> */}
        </div>
        <div className="right">
          {/* check */}
          {/* <img src={arpImg} alt="" className="boyiImg" /> */}

          {/* <dotlottie-player
      src="https://lottie.host/410e598b-43fb-4bb2-84d3-2e87d7bad8be/nMriDt3Uc4.json"
      background="transparent"
      speed="1"
      style={{ width: '100%', height: '100%' }}
      loop
      autoplay
    ></dotlottie-player> */}
<iframe className='boyiImg' src="
https://lottie.host/embed/410e598b-43fb-4bb2-84d3-2e87d7bad8be/nMriDt3Uc4.json"
width
="100%"
height
="100%"
></iframe>
        </div>
      </div>
      <div >
      
       <title className="heading">
         <span className="rect_line_left"></span> <span className="color_text"> Trusted by the best</span>  <span className="rect_line_right"></span>
        </title>
    </div>
    </div>
   
    </div>
  )
}

export default ArpService