import React, { useState } from 'react'
import "./Sales.scss"
import axios from 'axios'

import Contact from "../../assetsPng/Arp/Frame 96.svg"
import Email from "../../assetsPng/Arp/email.svg"
import Phone from "../../assetsPng/Arp/phone.svg"
import checkCircle from "../../assetsPng/Arp/check_circle.svg"

const Sales = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        orgName: '',
      });
    
      const [errors, setErrors] = useState({});
      const [showSuccessMsg, setShowSuccessMsg] = useState(false)
      const [disableSubmit, setDisableSubmit] = useState(false);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors({})
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm(formData);
        if (Object.keys(errors).length === 0) {
          // Form submission logic goes here
          console.log(formData);
          submitContactInfo(formData)
        } else {
          setErrors(errors);
        }
      };

      const submitContactInfo = async (formData) => {
        setDisableSubmit(true);
        try {
            const response = await axios.post('https://sheetdb.io/api/v1/av3155iqm158o', {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                orgName: formData.orgName
            });
            setDisableSubmit(false);
            setFormData({
              name: '',
              email: '',
              phone: '',
              orgName: '',
            })
            setShowSuccessMsg(true);
            setTimeout(() => {
                setShowSuccessMsg(false);
            }, 20000); // 20 seconds
        } catch (error) {
            console.error('Error:', error); // Handle error
            setDisableSubmit(false);
        }
    };
    
      const validateForm = (data) => {
        let errors = {};
        if (!data.name) {
          errors.name = 'Name is required';
        }
        if (!data.orgName) {
          errors.orgName = 'Organization name is required';
        }
        if (!data.email) {
          errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
          errors.email = 'Email is invalid';
        }
        if (!data.phone) {
          errors.phone = 'Phone number is required';
        } else if (!/^\d{10}$/.test(data.phone)) {
          errors.phone = 'Phone number must be 10 digits';
        }
        return errors;
      };

  return (
    <div id="contact-us" className='sales_container pb_80'>
      <div className="pb_60">
<div>
        <title className="heading">
         <span className="rect_line_left"></span> <span className="color_text">Connect with our sales team</span>  <span className="rect_line_right"></span>
        </title>
      </div>
      
      <div className="container">
      <div className="row">

      <div className="col-lg-5 mb-2 ">

      <div className='chat_text'>
      Let's Connect...
</div>

    {/* <div className="card mb_8">
            <div className="card-body">
                <div>
                    <img src={Contact} alt="" />
                </div>
                <div className='contact_details'>
                    <div className='contact_head'>
                    Contact
                    </div>
                    <div className='contact_name'>
                    Arun Yadav
                    </div>
                </div>
                </div>
                </div> */}

<h4 className='connect_section_title'>For ARP</h4>
                <div className="card mb_8">
            <div className="card-body">
                <div>
                    <img src={Email} alt="" />
                </div>
                <div className='contact_details'>
                    <div className='contact_head'>
                    Email
                    </div>
                    <div className='contact_name'>
                    bd@nakad.co
                    </div>
                </div>
                </div>
                </div>

                <div className="card">
            <div className="card-body">
                <div>
                    <img src={Phone} alt="" />
                </div>
                <div className='contact_details'>
                    <div className='contact_head'>
                    Phone
                    </div>
                    <div className='contact_name'>
                    +91 98992 59679
                    </div>
                </div>
                </div>
                </div>
                <h4 className='connect_section_title'>For Lending & SCF</h4>
                <div className="card mb_8">
            <div className="card-body">
                <div>
                    <img src={Email} alt="" />
                </div>
                <div className='contact_details'>
                    <div className='contact_head'>
                    Email
                    </div>
                    <div className='contact_name'>
                    scf@nakad.co
                    </div>
                </div>
                </div>
                </div>

                <div className="card mb_8">
                <div className="card-body">
                <div>
                    <img src={Phone} alt="" />
                </div>
                <div className='contact_details'>
                    <div className='contact_head'>
                    Phone
                    </div>
                    <div className='contact_name'>
                    +91 93546 73957
                    </div>
                </div>
                </div>
                </div>

                <h4 className='connect_section_title'>For Strategic Partnership & Alliances</h4>
                <div className="card mb_8">
                  
            <div className="card-body">
                <div>
                    <img src={Email} alt="" />
                </div>
                <div className='contact_details'>
                    <div className='contact_head'>
                    Email
                    </div>
                    <div className='contact_name'>
                    tahseen.faraz@nakad.co
                    </div>
                </div>
                </div>
                </div>

                <div className="card mb_8">
                <div className="card-body">
                <div>
                    <img src={Phone} alt="" />
                </div>
                <div className='contact_details'>
                    <div className='contact_head'>
                    Phone
                    </div>
                    <div className='contact_name'>
                    +91 96785 54594
                    </div>
                </div>
                </div>
                </div>
        </div>
        
      <div className="col-lg-2 mb-2 center_align">
    <div className='ver_line'>

    </div>
        </div>

        <div className="col-lg-5 mb-2 ">

<div className='details_text'>
...or share your details and we'll get back to you
</div>
<form onSubmit={handleSubmit} className="">
      <div className="mb-3">
        {/* <label htmlFor="name" className="form-label">Name</label> */}
        <input type="text" className={`form-control ${errors.name && 'is-invalid'}`} id="name" name="name" value={formData.name} onChange={handleChange} placeholder='Name'/>
        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
      </div>
      <div className="mb-3">
        <input type="text" className={`form-control ${errors.orgName && 'is-invalid'}`} id="orgName" name="orgName" value={formData.orgName} onChange={handleChange} placeholder='Organization Name'/>
        {errors.orgName && <div className="invalid-feedback">{errors.orgName}</div>}
      </div>
      <div className="mb-3">
        {/* <label htmlFor="email" className="form-label">Email</label> */}
        <input type="email" className={`form-control ${errors.email && 'is-invalid'}`} id="email" name="email" value={formData.email} onChange={handleChange} placeholder='Email' />
        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
      </div>
      <div className="mb-3">
        {/* <label htmlFor="phone" className="form-label">Phone Number</label> */}
        <input type="tel" className={`form-control ${errors.phone && 'is-invalid'}`} id="phone" name="phone" value={formData.phone} onChange={handleChange} placeholder='Phone No.'/>
        {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
      </div>
      <button type="submit" className="btn btn-primary submit_btn" disabled={disableSubmit}>Submit</button>
    </form>
    {showSuccessMsg &&
    <div className='success_msg'>
      <span><img src={checkCircle} alt="" /></span>
    We will get back to you shortly
    </div>
}

<div className="card" style={{marginTop:'50px'}}>
            <div className="card-body">
                {/* <div>
                    <img src={Phone} alt="" />
                </div> */}
                <div className='contact_details'>
                    <div className='contact_head_address'>
                    For Grievances
                    </div>
                    <div className='contact_name_address'>
                    Rocky D’Mary
                    </div>
                    <div className='contact_name_address'>
                    Email: scf@nakad.co
                    </div>
                    <div className='contact_name_address'>
                    Address: 204, ABW Tower, Iffco Chowk, MG Road, Gurugram – 122002.
                    </div>
                </div>
                </div>
                </div>
    </div>

        </div>
        </div>

        </div>
    </div>
  )
}

export default Sales
