import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation, } from "react-router-dom";
import "./Home_Header.scss";

// import Nakad from '../../assetsPng/NAKAD-.png'
// import Nakad from '../../assetsPng/NAKAD FINAL.png'
import Nakad from '../../assetsPng/NAKAD-LOGO-dark.png'
const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const Header = ({username}) => {
  const [isActive, setisActive] = useState(true);

  const ref = useRef();

  useOutsideClick(ref, () => {
    // alert('You clicked outside')
    console.log('You clicked outside')
    console.log(isActive)
    if(isActive === false){

      const navCloseBtn = document.getElementById('menu_btn_nav');
      console.log(navCloseBtn)
      navCloseBtn.click()
      setisActive(!isActive)
    }
  });

  // const [black , setBlack] =  useState(false)

  // const [navbar, setNavbar] = useState(false);

  // const changeBackground = ()=> {
  //  if(window.scrollY >= (window.innerHeight - 12 )* 7){
  //    setNavbar(true);
  //  }else{
  //    setNavbar(false)
  //  }
  // }
  // window.addEventListener('scroll', changeBackground)

  // const changeBackground = ()=> {
  //   if(window.scrollY >= 720*7){
  //     setNavBg("#000614");
  //   }else{
  //     setNavBg("red");
  //   }
  //  }
  //  window.addEventListener('scroll', changeBackground)


  // const {pathname}= useLocation();

  const [navBg, setNavBg] = useState();
  const location = useLocation();
  React.useEffect(() => {
    if (window.location.pathname === "/life-at-nakad" || window.location.pathname === "/privacy-policy" || window.location.pathname === "/terms-of-use" || window.location.pathname === "/disclaimer" || window.location.pathname === "/faqs") {
      setNavBg("#000614");
      document.body.style.overflowY = "auto";
    } else if(window.location.pathname === "/"){
      setNavBg('linear-gradient(90deg, #7A1C72 0%, #581953 100%)');
      document.body.style.overflowY = "auto";
    }else {
      
      setNavBg();
      
    }
  }, [location]);


  // background:"linear-gradient(298.59deg, #45108A -5.2%, #3D065F 42.6%, #200554 168.28%)",
  // setNavBg("#390b62");

  const changeOverflowLife = () => {
    if (location.state && location.state.from === '/scf') {
    document.body.style.overflowY = "auto";
    document.body.style.overflowX = "hidden";
    document.body.style.position = 'absolute';
    }
    closeNavMenu()
  }
  const changeOverflowHome = () => {
    document.body.style.overflow = "hidden";
    closeNavMenu()
  }

  const closeNavMenu = () => {
    if(isActive === false){

      const navCloseBtn = document.getElementById('menu_btn_nav');
      navCloseBtn.click()
      setisActive(!isActive)
    }
  }

//   const navLinks = document.querySelectorAll('.nav-item')
// const menuToggle = document.getElementById('navbarSupportedContent')
// const bsCollapse = new bootstrap.Collapse(menuToggle)
// navLinks.forEach((l) => {
//     l.addEventListener('click', () => { bsCollapse.toggle() })
// })

  const changeBackground = () => {
    setisActive(!isActive)
    console.log("isActive", isActive)
    if(isActive === true){
      if (window.location.pathname === "/life-at-nakad" || window.location.pathname === "/privacy-policy" || window.location.pathname === "/terms-of-use" || window.location.pathname === "/disclaimer" || window.location.pathname === "/faqs") {
        setNavBg("#000614");
      }else if (window.location.pathname === "/scf"){
        if(username === 7 || username === 8){
          console.log("username", username)
          setNavBg("#000614");
        }else{

          setNavBg("#390b62");
        }
        // setNavBg("#390b62");
      }else if(window.location.pathname === "/"){
        setNavBg('linear-gradient(90deg, #7A1C72 0%, #581953 100%)');
      }
    }else{
      setNavBg();
    }
  }

  return (
    // <div className="header_container" style={{}}>
    //   <div>
    //     <nav className="navbar" role="navigation" aria-label="main navigation">
    //       <div className="navbar-brand">
    //         <Link to="/">
    //           <a className="navbar-item">
    //             <span>NAKAD</span>
    //           </a>
    //         </Link>

    //         <a
    //           role="button"
    //           className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
    //           aria-label="menu"
    //           aria-expanded="false"
    //           data-target="navMenu"
    //           onClick={() => {
    //             setisActive(!isActive);
    //           }}
    //         >
    //           <span aria-hidden="true"></span>
    //           <span aria-hidden="true"></span>
    //           <span aria-hidden="true"></span>
    //         </a>
    //       </div>

    //       <div className={`navbar-menu ${isActive ? "is-active" : ""}`} id="navMenu">
    //         <div className="navbar-end">
    //           <Link to="/">
    //             <a className="navbar-item">Home</a>
    //           </Link>
    //           <Link to="/life-at-nakad">
    //             <a className="navbar-item">Life at Nakad</a>
    //           </Link>
    //           <Link to="">
    //             <button className="button">Join Us</button>
    //           </Link>

    //           {/* <a className="navbar-item">Home</a>
    //           <a className="navbar-item">Life at Nakad</a>
    //           <button className="button">Join Us</button> */}
    //         </div>
    //       </div>
    //     </nav>
    //   </div>
    // </div>

    // style={{backgroundColor:pathname==="/life-at-nakad"? "#000614" :''}}

    <nav className="navbar fixed-top navbar-expand-lg" style={{ background: navBg }}>
      {/* <nav className="navbar fixed-top navbar-expand-lg" style={{ backgroundColor:navbar ? '#000614' : navBg }}>*/}
      <div className="container" 
      ref={ref}
      >
        <a href="/">
          <span className="navbar-brand" >
            <img src={Nakad} alt="" className="nakad_logo"/>
            {/* NAKAD */}
          </span>
        </a>
        <button
        id="menu_btn_nav"
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={changeBackground}
        >
          {/* <span className="navbar-toggler-icon"></span> */}
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item" onClick={changeOverflowLife}>
              <Link to="/" className="nav-link">
                  <span className="text">ARP</span>
                  </Link>
            </li>
            <li className="nav-item" onClick={changeOverflowHome}>
                <Link to="/scf" className="nav-link">
                  <span className="text">SCF</span>
                  </Link>
            </li>
            <li className="nav-item" onClick={changeOverflowLife}>
                <Link to="/life-at-nakad" className="nav-link">
                  <span className="text">Life at NAKAD</span>
                  </Link>
            </li>
            <li className="nav-item" >
            <a className="nav-link" href="/#contact-us" onClick={closeNavMenu}>
                  <span className="text">Contact Us</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://nakad.turbohire.co/" target='_blank'>
                {/* <NavLink exact to="/"> */}
                  <button className="button">
                    
                    <span className="text">Join Us</span>
                   
                  </button>
                {/* </NavLink> */}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
