import React from 'react'
import './Life_7.scss'

//footer
import footerImg1 from '../../assetsSvg/footer/Saly-10.svg';
import footerImg from '../../assetsSvg/footer/Saly-17.svg';

import insta from '../../assetsSvg/footer/insta.svg';
import browser from '../../assetsSvg/footer/browser.svg';
import twitter from '../../assetsSvg/footer/twitter.svg';
import youtube from '../../assetsSvg/footer/youtube.svg';

import linkedin from "../../assetsSvg/Life/Mind/linkedin.svg";
import { Link } from 'react-router-dom';
import '../Common/Common.scss'
import Footer from '../Footer/Footer';

const Life_7 = () => {
  return (
    <>
    <section className='life_last_page'>
    <div className="media_page">
        <div className="join_nakad container">
          <div className="rect row">
            <div className="left_rect col-md-6 col">
              <img src={footerImg1} alt="" className="footerImg1"/>
            </div>
            <div className="right_rect col-md-6 col">
              <div className="text_box">
                <p className="text">Join the NAKAD tribe!</p>
                <p className="footer_text">We are looking for talented and highly driven folks on the journey to achieve 4000X growth!</p>
              </div>
              <div>
                <button className="footerBtn">
                 <a href="https://nakad.turbohire.co/" target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                  <p className="color_text">Are you one? Find a role <i className="bi bi-arrow-up-right"></i></p> 
                  </a>
                  </button>
              </div>
            </div>
          </div>
        </div>
    </div>
    </section>
    {/* <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-xs-12">
            <div className="copyright">
            <div className="copyright_text">
            © 2022 NAKAD. All rights reserved
            </div>
            </div>
           </div>
            <div className="col-md-4 col-xs-12">
            <div className="footer_img">
            <img src={footerImg} alt="footer" className='img-fluid' />
            </div>
           </div>
            <div className="col-md-4 col-xs-12">
              <div>
                <div className='d_flex text_center mb_10 quick_links'>
              <div className='mr_20 fs_12_quick_links'>
              <Link to="/privacy-policy" target="_blank" style={{ color: 'wheat' }}>Privacy Policy</Link>
                </div>
              <div className='mr_20 fs_12_quick_links'>
                  <Link to="/terms-of-use" target="_blank" style={{ color: 'wheat' }}>Terms of Use</Link>
                </div>
                <div className='mr_20 fs_12_quick_links'>
                  <Link to="/disclaimer" target="_blank" style={{ color: 'wheat' }}>Disclaimer</Link>
                </div>
                <div className='fs_12_quick_links'>
                  <Link to="/faqs" target="_blank" style={{ color: 'wheat' }}>FAQs</Link>
                </div>
                </div>
            <div className="social">
                <div className="icon_div">
                <div className="icon">   
                <a href="https://www.linkedin.com/company/nakadco/" target='_blank' rel="noreferrer">
                    <img src={linkedin} alt="" />
                </a>
              </div>
                  <div className="icon">
                  <a href="https://www.instagram.com/nakad.co/" target='_blank' rel="noreferrer">
                      <img src={insta} alt="" />
                   </a>
                  </div>
               
                    
             
                  <div className="icon">
                   <a href="https://twitter.com/NAKADaaj?s=20&t=TQNoi8vsZDO0mXy0hQ3YeQ" target='_blank' rel="noreferrer">
                   <img src={twitter} alt="" />
                   </a>
                  </div>
             
            </div>
          </div>
          </div>
         </div>
          </div>
     </div>
    </footer> */}
    <Footer />
    </>
  )
}

export default Life_7