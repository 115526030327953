import React from "react";
import "../PrivacyPolicy/PrivacyPolicy.scss";
import "./Disclaimer.scss"

const Disclaimer = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="">
          <div className="card">
            <div className="card-body card_shadow text_align_justify">
              <h1 className="text-center privacy_header">DISCLAIMER</h1>
              <section className="privacy_sub_header_section disclaimer_sec">
                <p className="fs_18">
                  Please read these Terms of Use & Privacy Policy (hereinafter
                  referred to as "Agreements",) carefully before using our
                  services and website <a href="https://www.nakad.co/" className="cursor_pointer" target="_blank" rel="noreferrer">https://www.nakad.co/</a>  (hereinafter
                  referred to as "us", "we", or "our" "the Site") operated by
                  AlphaStar Technologies Private Limited (CIN -
                  U72900DL2021PTC389698) (“<b>Company</b>”). These Agreements sets
                  forth the legally binding terms and conditions for your use of
                  the service and site at <a href="https://www.nakad.co/" className="cursor_pointer" target="_blank" rel="noreferrer">https://www.nakad.co/</a>. 
                </p>
                <p className="fs_18">
                  The use of this website by the User including prospective
                  registered Users, Service Providers, Vendors, Lenders etc. is
                  solely governed by this policy and any policy so mentioned by
                  terms of reference. Moving past home page or using any of the
                  services shall be taken to mean that User have read and agreed
                  to all of the agreements or policies so binding upon User and
                  that User are contracting with the Company and have undertaken
                  binding obligations with the Company.
                </p>
                <p className="fs_18">
                  User will be subject to the rules, guidelines, policies,
                  terms, and conditions applicable to any service that is
                  provided by this site, and they shall be deemed to be
                  incorporated into this Terms of Use & Privacy Policy and shall
                  be considered as part and parcel of these Agreements.
                </p>
                <p className="fs_18">
                  NAKAD does not recommend or support the services of any 3rd
                  Party like Service Providers or their opinions, or any other
                  information that may be referred on the Website. Though the
                  Company/Website acts as a platform that provides invoice
                  discounting services to the Lenders and the User, including
                  but not limited to, facilitating the transfer of funds,
                  verification of invoices, and managing the repayment process,
                  yet it takes no responsibility nor guarantee regarding any
                  service and/or product and disclaims all warranties, express
                  or implied, statutory or otherwise, including but not limited
                  to the implied warranties of merchantability, non-infringement
                  of third parties' rights, and fitness for particular purpose.
                </p>
                <p className="fs_18">
                  We hold the sole right to modify these Agreements without
                  prior permission from User or informing the User. The
                  relationship creates on User a duty to periodically check the
                  terms and stay updated on its requirements. If User continues
                  to use the website following such a change, this is deemed as
                  consent by User to the so amended Agreement. As long as User
                  complies with these Agreements, we grant the User a personal,
                  non-exclusive, non-transferable, limited privilege to enter
                  and use the Website.
                </p>
                <p className="fs_18">
                  NAKAD or its owners, managers, directors, employees, agents,
                  partners, advertisers or affiliates will not be liable for any
                  loss , damage, injury, delay, inaccessibility, failure,
                  interruption, alteration or damage of any data, third party
                  information or other information caused to a User arising out
                  of use of the Website , its Online services including legal
                  actions arising out of negligence or other third party actions
                  due to misconduct or omissions/acts of other Users on the
                  Website such as registered Service providers/Vendors/Partners.
                </p>
                <p className="fs_18">
                  By impliedly or expressly accepting these Terms of Use, User
                  also accept and agree to be bound by other Company Policies,
                  inter alia Privacy Policy, which would be amended from time to
                  time.
                </p>
                <p className="fs_18">
                  In case User finds any information objectionable or beyond
                  scope of such purpose, please feel free to contact our
                  Grievance Redressed Officer. If you are not satisfied with
                  resolution of your query/complaint, you have the option to
                  discontinue use of the Website or cancel your account with <a href="https://www.nakad.co/" className="cursor_pointer" target="_blank" rel="noreferrer">https://www.nakad.co/</a>.
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
