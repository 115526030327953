import React, { useState } from "react";
import "./Life_2.scss";
import Carousel from "react-multi-carousel";

import Ellipse15 from "../../assetsSvg/Life/Whats/Ellipse 15.svg";
import Ellipse16 from "../../assetsSvg/Life/Whats/Ellipse 16.svg";

// import doodleUp from "../../assetsSvg/Life/Whats/doodle-up.svg";
// import doodleDown from "../../assetsSvg/Life/Whats/doodle-down.svg";

import doodleUp from "../../assetsSvg/Life/Whats/doodle-up.png";
import doodleDown from "../../assetsSvg/Life/Whats/doodle-down.png";

// import partImg from "../../assetsSvg/Life/Whats/part.svg";
// import changeImg from "../../assetsSvg/Life/Whats/change.svg";
// import clarityImgSvg from "../../assetsSvg/Life/Whats/clarity.svg";
import indepImgSvg from "../../assetsSvg/Life/Whats/indep.svg";
// import contiImg from "../../assetsSvg/Life/Whats/conti.svg";
// import helpImg from "../../assetsSvg/Life/Whats/help.svg";
// import funImg from "../../assetsSvg/Life/Whats/fun.svg";
// import wealthImg from "../../assetsSvg/Life/Whats/wealth.svg";

import partImg from "../../assetsPng/Life/Whats/part.png";
import changeImg from "../../assetsPng/Life/Whats/change.png";
import clarityImg from "../../assetsPng/Life/Whats/clarity.png";
import indepImg from "../../assetsPng/Life/Whats/indep.png";
import contiImg from "../../assetsPng/Life/Whats/conti.png";
import helpImg from "../../assetsPng/Life/Whats/help.png";
import funImg from "../../assetsPng/Life/Whats/fun.png";
import wealthImg from "../../assetsPng/Life/Whats/wealth.png";

// import indep2xImg from "../../assetsPng/Life/Whats/indep4x.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 481 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 480 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 1,
  },
};

const Life_2 = () => {
  const [part, setPart] = useState(true);
  const [change, setChange] = useState(false);
  const [clarity, setClarity] = useState(false);
  const [indep, setIndep] = useState(false);
  const [conti, setConti] = useState(false);
  const [help, setHelp] = useState(false);
  const [fun, setFun] = useState(false);
  const [wealth, setWealth] = useState(false);

  // const [allValues, setAllValues] = useState({
  //   part:true,
  //   change:false,
  //   clarity:false,
  //   indep:false,
  //   conti:false,
  //   help:false,
  //   fun:false,
  //   wealth:false
  // });

  const changeHandler1 = () => {
    setPart(true);
    setChange(false);
    setClarity(false);
    setIndep(false);
    setConti(false);
    setHelp(false);
    setFun(false);
    setWealth(false);
  };

  const changeHandler2 = () => {
    // setAllValues({...allValues, [e.target.name] : true})

    setPart(false);
    setChange(true);
    setClarity(false);
    setIndep(false);
    setConti(false);
    setHelp(false);
    setFun(false);
    setWealth(false);
  };
  const changeHandler3 = () => {
    // setAllValues({...allValues, [e.target.name] : true})

    setPart(false);
    setChange(false);
    setClarity(true);
    setIndep(false);
    setConti(false);
    setHelp(false);
    setFun(false);
    setWealth(false);
  };
  const changeHandler4 = () => {
    // setAllValues({...allValues, [e.target.name] : true})

    setPart(false);
    setChange(false);
    setClarity(false);
    setIndep(true);
    setConti(false);
    setHelp(false);
    setFun(false);
    setWealth(false);
  };
  const changeHandler5 = () => {
    // setAllValues({...allValues, [e.target.name] : true})

    setPart(false);
    setChange(false);
    setClarity(false);
    setIndep(false);
    setConti(true);
    setHelp(false);
    setFun(false);
    setWealth(false);
  };
  const changeHandler6 = () => {
    // setAllValues({...allValues, [e.target.name] : true})

    setPart(false);
    setChange(false);
    setClarity(false);
    setIndep(false);
    setConti(false);
    setHelp(true);
    setFun(false);
    setWealth(false);
  };
  const changeHandler7 = () => {
    // setAllValues({...allValues, [e.target.name] : true})

    setPart(false);
    setChange(false);
    setClarity(false);
    setIndep(false);
    setConti(false);
    setHelp(false);
    setFun(true);
    setWealth(false);
  };
  const changeHandler8 = () => {
    // setAllValues({...allValues, [e.target.name] : true})

    setPart(false);
    setChange(false);
    setClarity(false);
    setIndep(false);
    setConti(false);
    setHelp(false);
    setFun(false);
    setWealth(true);
  };

  return (
    <div className="Life_2_container">
      <div>
        <title className="heading">
          What’s In It<span className="color_text">&nbsp;For You</span>
        </title>
      </div>

      <div className="whats_container">
        <div className="first">
          <div className="inside_first" style={{ opacity: part ? 100 : 0 }}>
            <div className="first_text">
              Solving for <br />
              insanely <span className="color_text">massive</span> <br />{" "}
              <span className="color_text">financing</span> <br /> crunch,
              across <br /> the world
            </div>
          </div>
          <div className="inside_first">
            <div className="first_text" style={{ opacity: change ? 100 : 0 }}>
              Transformimg <br />
              lives of <span className="color_text">
                billions of
              </span> <br /> <span className="color_text">people</span> who got
              <br /> left behind
            </div>
          </div>
          <div className="inside_first">
            <div className="first_text" style={{ opacity: clarity ? 100 : 0 }}>
              Never will there <br /> be a{" "}
              <span className="color_text">lack of</span> <br />
              <span className="color_text">direction</span>
            </div>
          </div>
          <div className="inside_first">
            <div className="first_text" style={{ opacity: indep ? 100 : 0 }}>
              Full autonomy to <br /> take charge and <br /> be{" "}
              <span className="color_text">entrepreneurial</span>
            </div>
          </div>
          <div className="inside_first">
            <div className="first_text" style={{ opacity: conti ? 100 : 0 }}>
              With business and <br /> tech innovation, <br /> no dearth to{" "}
              <span className="color_text">learn</span> <br />{" "}
              <span className="color_text">and upgrade</span>
            </div>
          </div>
          <div className="inside_first">
            <div className="first_text" style={{ opacity: help ? 100 : 0 }}>
              <span className="color_text">Help will always</span> <br />{" "}
              <span className="color_text">be available</span>, for <br />{" "}
              anything <br /> professional or <br /> personal
            </div>
          </div>
          <div className="inside_first">
            <div className="first_text" style={{ opacity: fun ? 100 : 0 }}>
              Let loose with the <br /> gang, life is <br />{" "}
              <span className="color_text">beyond just work</span>
            </div>
          </div>
          <div className="inside_first">
            <div className="first_text" style={{ opacity: wealth ? 100 : 0 }}>
              Your wealth grows <br /> in proportion to as <br /> we sky-rocket
              and <br /> <span className="color_text">build a hectocorn</span>
            </div>
          </div>
        </div>
        <div className="second">
          <div>
            <img src={Ellipse15} alt="" className="ellipse15" />
          </div>
          {/* <img src={Ellipse16} alt="" className='ellipse20'/>
            <img src={Ellipse16} alt="" className='ellipse16'/>
            <img src={Ellipse16} alt="" className='ellipse23'/>
            <img src={Ellipse16} alt="" className='ellipse17'/>
            <img src={Ellipse16} alt="" className='ellipse22'/>
            <img src={Ellipse16} alt="" className='ellipse18'/>
            <img src={Ellipse16} alt="" className='ellipse19'/>
            <img src={Ellipse16} alt="" className='ellipse21'/> */}
          <div>
            <div className="inside_second1">
              <div
                className={
                  part ? "circle_active ellipse20" : "circle ellipse20"
                }
                name="part"
                onClick={changeHandler1}
              ></div>
              <div
                className={
                  part
                    ? "circle_text_active ellipse20"
                    : "circle_text ellipse20"
                }
                onClick={changeHandler1}
              >
                Part of the biggest thing
              </div>
            </div>
            <div className="inside_second2">
              <div
                className={
                  change ? "circle_active ellipse16" : "circle ellipse16"
                }
                name="change"
                onClick={changeHandler2}
              ></div>
              <div
                className={
                  change
                    ? "circle_text_active ellipse16"
                    : "circle_text ellipse16"
                }
                onClick={changeHandler2}
              >
                Changing Lives of MSMEs
              </div>
            </div>
            <div className="inside_second3">
              <div
                className={
                  clarity ? "circle_active ellipse23" : "circle ellipse23"
                }
                onClick={changeHandler3}
              ></div>
              <div
                className={
                  clarity
                    ? "circle_text_active ellipse23"
                    : "circle_text ellipse23"
                }
                onClick={changeHandler3}
              >
                Clarity in ambiguity
              </div>
            </div>
            <div className="inside_second4">
              <div
                className={
                  indep ? "circle_active ellipse17" : "circle ellipse17"
                }
                onClick={changeHandler4}
              ></div>
              <div
                className={
                  indep
                    ? "circle_text_active ellipse17"
                    : "circle_text ellipse17"
                }
                onClick={changeHandler4}
              >
                Independence
              </div>
            </div>
            <div className="inside_second5">
              <div
                className={
                  conti ? "circle_active ellipse22" : "circle ellipse22"
                }
                onClick={changeHandler5}
              ></div>
              <div
                className={
                  conti
                    ? "circle_text_active ellipse22"
                    : "circle_text ellipse22"
                }
                onClick={changeHandler5}
              >
                Continuous Learning
              </div>
            </div>
            <div className="inside_second6">
              <div
                className={
                  help ? "circle_active ellipse18" : "circle ellipse18"
                }
                onClick={changeHandler6}
              ></div>
              <div
                className={
                  help
                    ? "circle_text_active ellipse18"
                    : "circle_text ellipse18"
                }
                onClick={changeHandler6}
              >
                A helping hand
              </div>
            </div>
            <div className="inside_second7">
              <div
                className={fun ? "circle_active ellipse19" : "circle ellipse19"}
                onClick={changeHandler7}
              ></div>
              <div
                className={
                  fun ? "circle_text_active ellipse19" : "circle_text ellipse19"
                }
                onClick={changeHandler7}
              >
                Fun Times
              </div>
            </div>
            <div className="inside_second8">
              <div
                className={
                  wealth ? "circle_active ellipse21" : "circle ellipse21"
                }
                onClick={changeHandler8}
              ></div>
              <div
                className={
                  wealth
                    ? "circle_text_active ellipse21"
                    : "circle_text ellipse21"
                }
                onClick={changeHandler8}
              >
                Wealth Creation
              </div>
            </div>
          </div>
        </div>
        <div className="third">
          <div>
            <img src={doodleUp} alt="" className="doodleUp" />
          </div>

          <div className="inside_third">
            <img
              src={partImg}
              alt=""
              className="partImg1"
              style={{ opacity: part ? 100 : 0 }}
            />
          </div>
          <div className="inside_third">
            <img
              src={changeImg}
              alt=""
              className="partImg2"
              style={{ opacity: change ? 100 : 0 }}
            />
          </div>
          <div className="inside_third">
            <img
              src={clarityImg}
              alt=""
              className="partImg3"
              style={{ opacity: clarity ? 100 : 0 }}
            />
          </div>
          <div className="inside_third">
            <img
              src={indepImgSvg}
              alt=""
              className="partImg4"
              style={{ opacity: indep ? 100 : 0 }}
            />
          </div>
          <div className="inside_third">
            <img
              src={contiImg}
              alt=""
              className="partImg5"
              style={{ opacity: conti ? 100 : 0 }}
            />
          </div>
          <div className="inside_third">
            <img
              src={helpImg}
              alt=""
              className="partImg6"
              style={{ opacity: help ? 100 : 0 }}
            />
          </div>
          <div className="inside_third">
            <img
              src={funImg}
              alt=""
              className="partImg7"
              style={{ opacity: fun ? 100 : 0 }}
            />
          </div>
          <div className="inside_third">
            <img
              src={wealthImg}
              alt=""
              className="partImg8"
              style={{ opacity: wealth ? 100 : 0 }}
            />
          </div>
          <div>
            <img src={doodleDown} alt="" className="doodleDown" />
          </div>
          {/* <div className="inside_third">
            <img src={wealthImg} alt="" className="partImg"  style={{opacity:wealth ? 100 : 0}}/>
            <div className=" partImg"></div>
          </div> */}
        </div>
      </div>

      {/* mobile-------- */}
      <div className="whats_container_mobile">
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={300000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <div>
            <div className="card1">
              <div className="circle_text_active">
                Part of the biggest thing
              </div>
              <div>
                <img src={partImg} alt="" className="partImg1" />
              </div>
              <div className="first_text biggest">
                Solving for insanely <span className="color_text">massive</span>{" "}
                <br /> <span className="color_text">financing</span> crunch, across the world
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="circle_text_active">Changing Lives of MSMEs</div>
              <div>
                <img src={changeImg} alt="" className="partImg2" />
              </div>
              <div className="first_text changing">
                Transformimg lives of{" "}
                <span className="color_text">billions of</span>{" "}
                <span className="color_text">people</span> who got left behind
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="circle_text_active">Clarity in ambiguity</div>
              <div>
                <img src={clarityImg} alt="" className="partImg3" />
              </div>
              <div className="first_text clarity">
                Never will there be a{" "}
                <span className="color_text">lack of </span>
                <span className="color_text">direction</span>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="circle_text_active">Independence</div>
              <div>
                <img src={indepImgSvg} alt="" className="partImg4" />
              </div>
              <div className="first_text independence">
                Full autonomy to take charge and be{" "}
                <span className="color_text">entrepreneurial</span>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="circle_text_active">Continuous Learning</div>
              <div>
                <img src={contiImg} alt="" className="partImg5" />
              </div>
              <div className="first_text continuous">
                With business and tech innovation, no dearth to{" "}
                <span className="color_text">learn</span>{" "}
                <span className="color_text">and upgrade</span>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="circle_text_active">A helping hand</div>
              <div>
                <img src={helpImg} alt="" className="partImg6" />
              </div>
              <div className="first_text helping">
                <span className="color_text">Help will always</span>{" "}
                <span className="color_text">be available</span>, for anything
                professional or personal
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="circle_text_active">Fun Times</div>
              <div>
                <img src={funImg} alt="" className="partImg7" />
              </div>
              <div className="first_text fun">
                Let loose with the gang, life is
                <span className="color_text"> beyond just work</span>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="circle_text_active">Wealth Creation</div>
              <div>
                <img src={wealthImg} alt="" className="partImg8" />
              </div>
              <div className="first_text wealth">
                Your wealth grows in proportion to as we sky-rocket and{" "}
                <span className="color_text">build a hectocorn</span>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Life_2;
