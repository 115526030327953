import React from 'react'
import './Pricing.scss'

const Pricing = () => {
  return (
    <div className='pricing_container_outer'>
    <div className='pricing_container'>
         <div>
        <title className="heading">
         <span className="rect_line_left"></span> <span className="color_text">Pricing</span>  <span className="rect_line_right"></span>
        </title>
      </div>
      <div>
        <p className='pricing_text'>You pay only when we deliver the results to you!</p>
      </div>
      <div className='center_align'>
            <div className='rect_line'></div>
        </div>
    </div>
    </div>
  )
}

export default Pricing