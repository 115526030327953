import React from "react";
import "./Life_3.scss";
import Hustle from "../../assets/Life/Life-3/Hustle.png";
import Shiddat from "../../assets/Life/Life-3/Shiddat.png";
import Integrity from "../../assets/Life/Life-3/Integrity.png";
import Team from "../../assets/Life/Life-3/Team.png";
import Caring from "../../assets/Life/Life-3/Caring.png";
import Ownership from "../../assets/Life/Life-3/Ownership.png";
import Target8020 from "../../assets/Life/Life-3/80-20.png";
import Speak from "../../assets/Life/Life-3/Speak.png";
import Family from "../../assets/Life/Life-3/Family.png";
import Communication from "../../assets/Life/Life-3/Communication.png";

const Life_3 = () => {
  return (
    <div className="Life_3_container">
      <title className="heading">
        Our <span className="color_text">&nbsp;Values</span>
      </title>
      <div className="value_container">
        <div className="ver_box1">
          <div className="inside_box top">
            <div className="hustle_div">
              <img src={Hustle} alt="" className="hustle" />
            </div>
            <div className="name1">Hustle</div>
            <div className="text">
              <span>Roll up your sleeves and get things done</span>
            </div>
            <div className="name2Hustle">Hustle</div>
          </div>
          <div className="inside_box bottom">
            <div className="name2Ownership">Ownership</div>
            <div className="ownership_div">
              <img src={Ownership} alt="" className="ownership" />
            </div>
            <div className="textOwnership">
              <span>You are an OWNER, not an employee</span>
            </div>
            <div className="name1Ownership">Ownership</div>
          </div>
        </div>
        <div className="ver_box2">
          <div className="inside_box1 top">
            <div className="hustle_div">
              <img src={Shiddat} alt="" className="hustle" />
            </div>
            <div className="name1Shiddat" >Shiddat</div>
            <div className="textShiddat" >
              <span>Work with full dedication, party with full dedication</span>
            </div>
            <div className="name2Shiddat">Shiddat</div>
          </div>
          <div className="inside_box2 bottom">
            <div className="name2Target">80-20</div>
            <div className="target8020_div">
              <img src={Target8020} alt="" className="target8020" />
            </div>
            <div className="textTarget">
              <span>Prioritize with eyes on the prize, ALWAYS</span>
            </div>
            <div className="name1Target">80-20</div>
          </div>
        </div>
        <div className="ver_box1">
          <div className="inside_box top">
            <div className="hustle_div">
              <img src={Integrity} alt="" className="hustle" />
            </div>
            <div className="name1">Integrity</div>
            <div className="text">
              <span>Strong moral</span>
              <br />
              <span>principles</span>
            </div>
            <div className="name2">Integrity</div>
          </div>
          <div className="inside_box bottom">
            <div className="speak_div">
              <img src={Speak} alt="" className="speak" />
            </div>
            <div className="name1Speak">Speak Up</div>
            <div className="textSpeak">
              <span>Unhesitatingly share your thoughts</span>
            </div>
            <div className="name2Speak">Speak Up</div>
          </div>
        </div>
        <div className="ver_box2">
          <div className="inside_box1 top">
            <div className="name2Top">A+ Team</div>
            <div className="team_div">
              <img src={Team} alt="" className="team" />
            </div>
            <div className="textTop">
              <span>Nothing less than the best</span>
            </div>
            <div className="name1Top">A+ Team</div>
          </div>
          <div className="inside_box2 bottom">
            <div className="name2Top">Family</div>
            <div className="family_div">
              <img src={Family} alt="" className="family" />
            </div>
            <div className="textTop">
              <span>It is WE, not I</span>
            </div>
            <div className="name1Top">Family</div>
          </div>
        </div>
        <div className="ver_box1">
          <div className="inside_box top">
            <div className="name2Caring">
              Caring <br />
              Meritocracy
            </div>
            <div className="caring_div">
              <img src={Caring} alt="" className="caring" />
            </div>
            <div className="textCaring">
              <span>You take care of business, we take care of you</span>
            </div>
            <div className="name1Caring">
              Caring <br /> Meritocracy
            </div>
          </div>
          <div className="inside_box bottom">
            <div className="name2Communication">Communication</div>
            <div className="communication_div">
              <img src={Communication} alt="" className="communication" />
            </div>
            <div className="textCommunication">
              <span>Full transparency among all</span>
            </div>
            <div className="name1Communication">Communication</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Life_3;
