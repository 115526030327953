import React, { useState, useRef, useEffect } from "react";
import "./Life_4.scss";

import Carousel from "react-multi-carousel";
import ReactReadMoreReadLess from "react-read-more-read-less";

import image from "../../assetsSvg/Life/Mind/image-(55).svg";
import twitter from "../../assetsSvg/Life/Mind/twitter.svg";
import linkedin from "../../assetsSvg/Life/Mind/linkedin.svg";

// import Sambhav from "../../assetsPng/Life/Mind/sambhav-.png"
// import Sambhav from "../../assetsPng/Life/Mind/sambhav (3).png"
// import Sambhav from "../../assetsPng/Life/Mind/sambhav new.png"
import Sambhav from "../../assetsPng/Life/Mind/sambhav new(3).png"
// import Chinmaya from "../../assetsPng/Life/Mind/chinmay (2).png"
// import Chinmaya from "../../assetsPng/Life/Mind/chinmay-new.png"
import Chinmaya from "../../assetsPng/Life/Mind/chinmaya- (4).png"
// import Ujwal from "../../assetsPng/Life/Mind/ujwal.png"
// import Ujwal from "../../assetsPng/Life/Mind/ujwal-new.png"
// import Ujwal from "../../assetsPng/Life/Mind/ujwal (3).png"
import Ujwal from "../../assetsPng/Life/Mind/ujwal new.png"
import Avinash from "../../assetsPng/Life/Mind/avinash.png"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1440 },
    items: 3.5,
  },
  smalldesktop: {
    breakpoint: { max: 1439, min: 1024 },
    items: 2.5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 481 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 1.3,
  },
};

function ReadMore({ children, index, moveSlide }) {
  const text = children;
  console.log("hello");
  console.log("read", index);

  const myRef = useRef();
  const [isShow, setIsShow] = useState(true);
  const result = isShow ? text.slice(0, 165) : text;

  function toggleIsShow0() {
    setIsShow(!isShow);

    // console.log("self",self)
    console.log("inside togg", index);

    const item = document.getElementsByClassName("react-multi-carousel-item");

    console.log("item", item[0]);

    item[0].style.zIndex = "5";

    console.log("function that is passed is this", moveSlide);
    console.log("reached in toggleShow0");

    // if (curr)
    // moveSlide();
  }

  function toggleIsShow1() {
    setIsShow(!isShow);

    const item = document.getElementsByClassName("react-multi-carousel-item");

    console.log("item", item[1]);

    item[1].style.zIndex = "4";
  }

  function toggleIsShow2() {
    setIsShow(!isShow);

    const item = document.getElementsByClassName("react-multi-carousel-item");

    console.log("item", item[2]);

    item[2].style.zIndex = "3";

    moveSlide(1, 1);
  }

  function toggleIsShow3() {
    setIsShow(!isShow);

    const item = document.getElementsByClassName("react-multi-carousel-item");

    console.log("item", item[3]);

    item[3].style.zIndex = "2";

    // if (currentSlide < 2) {
    //   moveSlide(2);
    // }
    moveSlide(2, 2);
  }
  function toggleIsShow4() {
    setIsShow(!isShow);

    const item = document.getElementsByClassName("react-multi-carousel-item");

    console.log("item", item[4]);

    item[4].style.zIndex = "1";

    // if (currentSlide < 2) {
    //   moveSlide(3);
    // }

    moveSlide(3, 3);
  }

  let onClickHandler = toggleIsShow0;

  if (index === "1") {
    onClickHandler = toggleIsShow1;
  } else if (index === "2") {
    onClickHandler = toggleIsShow2;
  } else if (index === "3") {
    onClickHandler = toggleIsShow3;
  } else if (index === "4") {
    onClickHandler = toggleIsShow4;
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (!isShow && myRef.current && !myRef.current.contains(e.target)) {
        setIsShow(true);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isShow]);

  return (
    <p className={isShow ? "text2" : "text3"} ref={myRef}>
      {result}
      <span onClick={onClickHandler} className="color_text">
        {isShow ? " Read More" : " Read Less"}
      </span>
    </p>
  );
}

const Life_4 = () => {
  const [show, setShow] = useState(true);
  let carousel = useRef(null);

  function moveSlide(slideToCheck, slideToGo) {
    let currentSlide = carousel.state.currentSlide;
    if (currentSlide < slideToCheck) carousel.goToSlide(slideToGo);
  }

  return (
    <div className="Life_4_container">
      <div>
        <title className="heading">
          <span className="color_text">Minds&nbsp;</span> Behind NAKAD
        </title>
      </div>
      <div className="minds_container">
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000000}
          autoPlay={false}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
          ref={(el) => (carousel = el)}
        >
          <div>
            <div className="card1">
              <div className="top">
                <img src={Ujwal} alt="" className="ujwal" />
                <div className="box"></div>
              </div>
              <div className="bottom1">
                <h5 className="name">Ujwal Kalra</h5>
                <p className="text">Co-founder & CEO</p>
                <p className="img_box">
                  <div className="circle">
                    <a
                      href="https://www.linkedin.com/in/kalraujwal/"
                      target="_blank"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </div>
                  <div className="circle" style={{ marginLeft: "10px" }}>
                    <a href=" https://twitter.com/ujwal_kalra" target="_blank">
                      <img src={twitter} alt="" />
                    </a>
                  </div>
                </p>
              </div>
              <div className="readMore_box">
                <ReadMore index="0" moveSlide={moveSlide}>
                  Ujwal Kalra is the CEO building this massive machine, NAKAD.
                  Earlier, he worked at the Boston Consulting Group (BCG), where
                  he was a founding member of Growth Tech – BCG’s foray into the
                  startup world. Ujwal graduated from IIM Ahmedabad where he was
                  an Institute Scholar. He completed engineering from IIT
                  Guwahati and schooling from DPS Vasant Kunj. Ujwal is the
                  co-author of Startup Compass, a HarperCollins publication with
                  foreword from Narayana Murthy. He is recipient of The Economic
                  Times Young Leader Award, O.P. Jindal Engineering and
                  Management Scholarship, Dun & Bradstreet Award and Dunia
                  Scholarship, UAE. He also represented India at Kairos USA, an
                  entrepreneurship society.
                </ReadMore>
              </div>
              <div className="readMore_box_mobile">
                <p className="trim_text">
                  Ujwal Kalra is the CEO building this massive machine, NAKAD.
                  Earlier, he worked at the Boston Consulting Group (BCG), where he was a founding member of Growth Tech{" "}
                  <span
                    className="color_text"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalUjwal"
                  >
                    Read More
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div style={{ zIndex: 3 }}>
            <div className="card1">
              <div className="top">
                <img src={Sambhav} alt="" className="sambhav"/>
                <div className="box"></div>
              </div>
              <div className="bottom1">
                <h5 className="name">Sambhav Jain</h5>
                <p className="text">Co-founder & COO</p>
                <div className="img_box">
                  <div className="circle">
                    <a
                      href=" https://www.linkedin.com/in/sambhavjain1"
                      target="_blank"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </div>
                  <div className="circle" style={{ marginLeft: "10px" }}>
                    <a
                      href="https://twitter.com/sambhavjain975"
                      target="_blank"
                    >
                      <img src={twitter} alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="readMore_box">
                <ReadMore index="1" moveSlide={moveSlide}>
                  Sambhav is the COO at NAKAD, where he focuses on solving
                  MSMEs' working capital problem at scale. Prior to NAKAD, he
                  worked at BCG, where he was rated best consultant across the
                  firm for 2 consecutive years. He also did brief stints at HUL,
                  Blume Ventures, FinMechanics and Fidelity Investments. Sambhav
                  studied Electrical Engineering from IIT Bombay, where he was
                  deeply involved with the start-up community. He has authored a
                  book for JEE chemistry which sold over 10,000 copies prior to
                  being provided for free on the internet. Sambhav is also a
                  contributor to the YouTube channel Labour Law Advisor, with
                  his videos garnering over 30 lakh views.
                </ReadMore>
              </div>
              <div className="readMore_box_mobile">
                <p className="trim_text">
                  Sambhav is the COO at NAKAD, where he focuses on solving
                  MSMEs' working capital problem at scale. Prior to NAKAD, he
                  worked at BCG, where he was rated best consultant{" "}
                  <span
                    className="color_text"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalSambhav"
                  >
                    Read More
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div style={{ zIndex: 2 }}>
            <div className="card1">
              <div className="top">
                <img src={Avinash} alt="" className="avinash"/>
                <div className="box"></div>
              </div>
              <div className="bottom1">
                <h5 className="name">Avinash Uttav</h5>
                <p className="text">Co-founder & CTO</p>
                <p className="img_box">
                  <div className="circle">
                    <a
                      href=" https://www.linkedin.com/in/avinash-uttav-a2619647/"
                      target="_blank"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </div>
                  <div className="circle" style={{ marginLeft: "10px" }}>
                    <a href="https://twitter.com/a_uttav" target="_blank">
                      <img src={twitter} alt="" />
                    </a>
                  </div>
                </p>
              </div>
              <div className="readMore_box">
                <ReadMore index="2" moveSlide={moveSlide}>
                  Avinash is the CTO at NAKAD, where he is focusing on building
                  multiple innovative software products utilizing latest
                  cutting-edge technologies. He is passionate about solving the
                  financial problems for MSMEs of India with technology. Before
                  NAKAD, he has worked at Oracle where he was part of the team
                  that has built one of the best Blockchain Platform in the
                  Enterprise space. He has also built world class no code/low
                  code applications which complemented the Blockchain Platform
                  offerings. Prior to Oracle, he has also worked in Samsung
                  where he was part of a lean team that had built the massive
                  backend systems for Bixby Voice Assistant, which is being used
                  daily, by millions of Samsung users worldwide. He was awarded
                  for outstanding contribution 5 times during his stint at
                  Samsung and published 2 research papers and 1 patent. Avinash
                  has done his B.Tech. in Computer Science and Engineering from
                  IIT Guwahati.
                </ReadMore>
              </div>
              <div className="readMore_box_mobile">
                <p className="trim_text">
                  Avinash is the CTO at NAKAD, where he is focusing on building
                  multiple innovative software products utilizing latest cutting-edge technologies. He is passionate about{" "}
                  <span
                    className="color_text"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalAvinash"
                  >
                    Read More
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="top">
                <img src={Chinmaya} alt="" className="chinmaya"/>
                <div className="box"></div>
              </div>
              <div className="bottom1">
                <h5 className="name">Chinmaya Gawde</h5>
                <p className="text">VP & Head of Sales</p>
                <p className="img_box">
                  <div className="circle">
                    <a
                      href="https://www.linkedin.com/in/chinmayagawde"
                      target="_blank"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </div>
                  <div className="circle" style={{ marginLeft: "10px" }}>
                    <a
                      href="https://twitter.com/cOOlchinmaya?t=Hwd5iRVzPxzxiZUWCQjjlg&s=08"
                      target="_blank"
                    >
                      <img src={twitter} alt="" />
                    </a>
                  </div>
                </p>
              </div>
              <div className="readMore_box">
                <ReadMore index="3" moveSlide={moveSlide}>
                  Chinmaya leads the Sales Organization at NAKAD. Earlier, he
                  was working with the Godrej Group, leading Strategy and
                  Business Development initiatives for its Food Businesses. He
                  has also co-led the Venture Development Office at Innoterra,
                  an Indo-Swiss startup incubator. Chinmaya graduated from IIM
                  Ahmedabad with top honours ( Industry Scholar, Batch
                  All-rounder) and has completed his B.Tech from Institute of
                  Chemical Technology (UDCT), Mumbai. He is an avid duathlete
                  (running and cycling) and participates actively in endurance
                  events.
                </ReadMore>
              </div>
              <div className="readMore_box_mobile">
                <p className="trim_text">
                  Chinmaya leads the Sales Organization at NAKAD. Earlier, he
                  was working with the Godrej Group, leading Strategy and
                  Business Development initiatives for its Food Businesses.{" "}
                  <span
                    className="color_text"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalChinmaya"
                  >
                    Read More
                  </span>
                </p>
              </div>
            </div>
          </div>
          {/* <div>
            <div className="card1">
              <div className="top">
                <img src={image} alt="" />
                <div className="box"></div>
              </div>
              <div className="bottom1">
                <h5 className="name">5Ujawal Kalra</h5>
                <p className="text">Co-founder & CEO</p>
                <p className="img_box">
                  <div className="circle">
                    <img src={linkedin} alt="" />
                  </div>
                  <div className="circle" style={{ marginLeft: "10px" }}>
                    <img src={twitter} alt="" />
                  </div>
                </p>
              </div>
              <div>
                <ReadMore index="4" moveSlide={moveSlide}>
                  Ujwal Kalra is the CEO building this massive machine, NAKAD.
                  Earlier, he worked at the Boston Consulting Group (BCG), where
                  he was a founding member of Growth Tech – BCG’s foray into the
                  startup world. Ujwal graduated from IIM Ahmedabad where he was
                  an Institute Scholar. He completed engineering from IIT
                  Guwahati and schooling from DPS Vasant Kunj. Ujwal is the
                  co-author of Startup Compass, a HarperCollins publication with
                  foreword from Narayana Murthy. He is recipient of The Economic
                  Times Young Leader Award, O.P. Jindal Engineering and
                  Management Scholarship, Dun & Bradstreet Award and Dunia
                  Scholarship, UAE. He also represented India at Kairos USA, an
                  entrepreneurship society.
                </ReadMore>
              </div>
            </div>
          </div> */}
          {/* <div></div> */}
        </Carousel>
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal "
        id="exampleModalUjwal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-body">
              Ujwal Kalra is the CEO building this massive machine, NAKAD.
              Earlier, he worked at the Boston Consulting Group (BCG), where he
              was a founding member of Growth Tech – BCG’s foray into the
              startup world. Ujwal graduated from IIM Ahmedabad where he was an
              Institute Scholar. He completed engineering from IIT Guwahati and
              schooling from DPS Vasant Kunj. Ujwal is the co-author of Startup
              Compass, a HarperCollins publication with foreword from Narayana
              Murthy. He is recipient of The Economic Times Young Leader Award,
              O.P. Jindal Engineering and Management Scholarship, Dun &
              Bradstreet Award and Dunia Scholarship, UAE. He also represented
              India at Kairos USA, an entrepreneurship society.
            </div>
            <div className="modal-footer">
              <span className="color_text" data-bs-dismiss="modal">
                Read Less
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal "
        id="exampleModalSambhav"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-body">
              Sambhav is the COO at NAKAD, where he focuses on solving MSMEs'
              working capital problem at scale. Prior to NAKAD, he worked at
              BCG, where he was rated best consultant across the firm for 2
              consecutive years. He also did brief stints at HUL, Blume
              Ventures, FinMechanics and Fidelity Investments. Sambhav studied
              Electrical Engineering from IIT Bombay, where he was deeply
              involved with the start-up community. He has authored a book for
              JEE chemistry which sold over 10,000 copies prior to being
              provided for free on the internet. Sambhav is also a contributor
              to the YouTube channel Labour Law Advisor, with his videos
              garnering over 30 lakh views.
            </div>
            <div className="modal-footer">
              <span className="color_text" data-bs-dismiss="modal">
                Read Less
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal "
        id="exampleModalAvinash"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-body">
              Avinash is the CTO at NAKAD, where he is focusing on building
              multiple innovative software products utilizing latest
              cutting-edge technologies. He is passionate about solving the
              financial problems for MSMEs of India with technology. Before
              NAKAD, he has worked at Oracle where he was part of the team that
              has built one of the best Blockchain Platform in the Enterprise
              space. He has also built world class no code/low code applications
              which complemented the Blockchain Platform offerings. Prior to
              Oracle, he has also worked in Samsung where he was part of a lean
              team that had built the massive backend systems for Bixby Voice
              Assistant, which is being used daily, by millions of Samsung users
              worldwide. He was awarded for outstanding contribution 5 times
              during his stint at Samsung and published 2 research papers and 1
              patent. Avinash has done his B.Tech. in Computer Science and
              Engineering from IIT Guwahati.
            </div>
            <div className="modal-footer">
              <span className="color_text" data-bs-dismiss="modal">
                Read Less
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal "
        id="exampleModalChinmaya"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-body">
              Chinmaya leads the Sales Organization at NAKAD. Earlier, he was
              working with the Godrej Group, leading Strategy and Business
              Development initiatives for its Food Businesses. He has also
              co-led the Venture Development Office at Innoterra, an Indo-Swiss
              startup incubator. Chinmaya graduated from IIM Ahmedabad with top
              honours ( Industry Scholar, Batch All-rounder) and has completed
              his B.Tech from Institute of Chemical Technology (UDCT), Mumbai.
              He is an avid duathlete (running and cycling) and participates
              actively in endurance events.
            </div>
            <div className="modal-footer">
              <span className="color_text" data-bs-dismiss="modal">
                Read Less
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Life_4;
