import React from 'react'
import "./Solutions.scss"
import card1 from "../../assetsPng/Arp/card1.png"
import msme from "../../assetsPng/Arp/msme.png"
import balance2 from "../../assetsPng/Arp/balance2.png"
import ledger from "../../assetsPng/Arp/ledger.png"
import noc from "../../assetsPng/Arp/noc.png"
import service1 from "../../assetsPng/Arp/service1.png"
import service2 from "../../assetsPng/Arp/service2.png"
const Solutions = () => {
  return (
    <div className='Solutions_container'>
    <div className='pb_80'>
        <div>
        <title className="heading">
         <span className="rect_line_left"></span> <span className="color_text">Our Solutions</span>  <span className="rect_line_right"></span>
        </title>
      </div>

        <div className="container">
            <div className='text_subhead'>
            Partner Communication
            </div>
      <div className="row">
        {/* For large screens (col-lg) */}
        <div className="col-lg-6 mb-2">
          <div className="card card_bg_1">
            <div className="card-body">
                <div style={{width:'60%'}}>
              <h5 className="card-title">MSME Confirmation</h5>
              <p className="card-text">Confirm MSME status from suppliers</p>
              </div>
              <div style={{width:'40%'}}>
                <img src={msme} alt="" className='card1img'/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="card card_bg_1">
            <div className="card-body">
                <div style={{width:'60%'}}>
              <h5 className="card-title">Balance Confirmation</h5>
              <p className="card-text">Periodically confirm outstanding balances from customers and suppliers</p>
              </div>
              <div style={{width:'40%'}}>
                <img src={balance2} alt="" className='card1img'/>
              </div>
            </div>
          </div>
        </div>

    
      </div>
      <div className="row">
        {/* For large screens (col-lg) */}
        <div className="col-lg-6 mb-2">
          <div className="card card_bg_1">
            <div className="card-body">
                <div style={{width:'60%'}}>
              <h5 className="card-title">Ledger Request</h5>
              <p className="card-text">Procure ledgers from suppliers and customers for periodic reconciliation</p>
              </div>
              <div style={{width:'40%'}}>
                <img src={ledger} alt="" className='card1img'/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="card card_bg_1">
            <div className="card-body">
                <div style={{width:'60%'}}>
              <h5 className="card-title">NDC / NOC</h5>
              <p className="card-text">Signed letters from customers and suppliers in your auditor's format</p>
              </div>
              <div style={{width:'40%'}}>
                <img src={noc} alt="" className='card1img'/>
              </div>
            </div>
          </div>
        </div>

    
      </div>
    </div>


        <div className="container mt_40 ">
            <div className='text_subhead'>
           Reconciliation-as-a-Service
            </div>
      <div className="row">
        {/* For large screens (col-lg) */}
        <div className="col-lg-6 mb-2">
          <div className="card card_bg_2">
            <div className="card-body">
                <div style={{width:'60%'}}>
              <p className="card-text-service">100% accurate reconciliation as per your accounting standards</p>
              </div>
              <div style={{width:'40%'}}>
                <img src={service1} alt="" className='card1img'/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="card card_bg_2">
            <div className="card-body">
                <div style={{width:'60%'}}>
              <p className="card-text-service">Complete your reconciliation at lightning speed</p>
              </div>
              <div style={{width:'40%'}}>
                <img src={service2} alt="" className='card1img'/>
              </div>
            </div>
          </div>
        </div>

    
      </div>
      {/* <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="card card_bg_green">
            <div className="card-body">
                <div style={{width:'70%'}}>
              <h5 className="card-title">MSME Confirmation</h5>
              <p className="card-text">Signed confirmation from MSME suppliers in pre-aligned format</p>
              </div>
              <div style={{width:'30%'}}>
                <img src={card1} alt="" className='card1img'/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="card card_bg_blue">
            <div className="card-body">
                <div style={{width:'70%'}}>
              <h5 className="card-title">Ledger Request</h5>
              <p className="card-text">Letter from MSME suppliers in your company’s format</p>
              </div>
              <div style={{width:'30%'}}>
                <img src={card1} alt="" className='card1img'/>
              </div>
            </div>
          </div>
        </div>

    
      </div> */}
    </div>

    
    </div>
    </div>
  )
}

export default Solutions