import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Frame935 from "./components/Frame935";
import Header from "./components/Header";
import Page1 from "./components/Page1";
import "./components/Styleguide.css";

// import './components/Page_1.scss'

import Page1Text from "./components/Page1Text";
import Page2Text from "./components/Page2Text";
import Page3Text from "./components/Page3Text";

import {
  ScrollContainer,
  Animator,
  ScrollPage,
  batch,
  Fade,
  Sticky,
  MoveOut,
} from "react-scroll-motion";
import Page4Text from "./components/Page4Text";

// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'

// new
import Page_1 from "./components/Page_1";
import Page_6 from "./components/Page_6";
import Page_2 from "./components/Page_2";
import Page_7 from "./components/Page_7";
import Page_3 from "./components/Page_3";
import Page_4 from "./components/Page_4";
import Page_5 from "./components/Page_5";
import Page_8 from "./components/Page_8";

import Life_1 from "./components/Life/Life_1";
import Life_2 from "./components/Life/Life_2";
import Life_3 from "./components/Life/Life_3";
import Life_4 from "./components/Life/Life_4";

import HomeHeader from "./components/Home/Home_Header";
import Home_1 from "./components/Home/Home_1";
import Home_2 from "./components/Home/Home_1";
import Life from "./components/Life/Life";
import ScrollToTop from "./components/ScrollToTop";
import Home_1_gsap from "./components/Home/Home_1_gsap";
import Home from "./components/Home/Home";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse/TermsOfUse";
import Disclaimer from "./components/Disclaimer/Disclaimer";
import Faqs from "./components/Faqs/Faqs";
import ArpService from "./components/Arp/ArpService";
import Arp from "./components/Arp/Arp";

function App() {
  // useEffect(() => {
  //   AOS.init();
  // });
  const [username, setUsername] = useState();
  return (
    // <div className='App'>
    //   {/* <Frame935 /> */}
    //   <Header />
    //   {/* <Page1 /> */}
    //   <Page1 data-aos="fade-up"/>
    //   <Page1 />
    // </div>

    // <div className="App">
    //   <Header />
    //   <Page_1 />
    //   {/* <Page1Text /> */}
    //   <Page_2 />
    //   <Page_3 />
    //   {/* <Page_1 /> */}
    // </div>

    // 1----HOME Page with scroll as per figma dimension---

    // <div className="App">
    //   <Header />
    //   <Page_1 />

    //   {/* <Page_2 /> */}
    //   <Page_3 />
    //   <Page_4 />
    //   <Page_5 />
    //   <Page_6 />
    //   <Page_7 />
    //   {/* <Page_8/> */}
    // </div>

    // 2---Life at nakad---
    // <div>
    //   <Life_1 />
    //   <Life_2 />
    //   <Life_3 />
    //   <Life_4 />
    // </div>

    // // 3---- Home page as per new dimension
    // <div className="App">
    //   <HomeHeader />
    //   <Home_1 />
    //   {/* <Home_2 />
    //   <Home_2 />
    //   <Home_2 /> */}
    //  </div>

    <>
      <BrowserRouter>
        <ScrollToTop>
          <HomeHeader username={username} />
          <Routes>
            <Route exact path="/" element={<Arp />} />
            <Route exact path="/scf" element={<Home_1 setUsername={setUsername}/>} />
            {/* <Route exact path="/" element={<Home />} /> */}
            <Route exact path="/life-at-nakad" element={<Life />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms-of-use" element={<TermsOfUse />} />
            <Route exact path="/disclaimer" element={<Disclaimer />} />
            <Route exact path="/faqs" element={<Faqs />} />
            {/* <Route exact path="/" element={< />} /> */}
            <Route path="*" element={<Navigate to="/" />} /> {/* Handle 404 Not Found */}
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>

    // <div className="App">
    //   <Header />
    //   {/* <Page1 /> */}
    //   <ScrollContainer>
    //     <ScrollPage page={0}>
    //       <Animator animation={batch(Fade(-1),Sticky, MoveOut(0, -200))}>
    //         {/* <Header /> */}
    //         <Page1 Text={<Page1Text />} />
    //         {/* <Page1Text /> */}
    //       </Animator>
    //     </ScrollPage>
    //     <ScrollPage page={1}>
    //       <Animator>
    //         <Page1 Text={<Page2Text/>}/>
    //       </Animator>
    //     </ScrollPage>
    //     <ScrollPage page={2}>
    //       <Animator>
    //         <Page1 Text={<Page3Text/>} style3={{opacity:0.2}}/>
    //       </Animator>
    //     </ScrollPage>
    //     <ScrollPage page={3}>
    //       <Animator>
    //         <Page1 Text={<Page4Text/>} style4={{opacity:0.2}}/>
    //       </Animator>
    //     </ScrollPage>
    //   </ScrollContainer>
    // </div>
  );
}

export default App;
