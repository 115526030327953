import React from "react";
import "./Media.scss";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Rocket from "../../assetsSvg/vcfirm/Saly-43-1.png";
import Accel from "../../assetsPng/Home/Accel-1.png";
import Advant from "../../assetsPng/Home/Advant-1.png";
import matrixPartners from "../../assetsPng/Home/matrix-2.png";
import ETlogo from "../../assetsSvg/media/et.svg";
import TechAsialogo from "../../assetsSvg/media/tech-in-asia.svg";
import Inc42logo from "../../assetsSvg/media/inc42.svg";
import yourstory from "../../assetsSvg/media/yourstory.svg";
import vcCirclelogo from "../../assetsSvg/media/vccircle.png";
import cnbc from "../../assetsSvg/media/cnbc.png";
import paymnts from "../../assetsSvg/media/paymnts.svg";
import startupstorymedia from "../../assetsSvg/media/startupstorymedia.svg";



const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const Media = () => {
  return (
    <div className="media_page_new ">
      <div className="media_page_section container">
        <div className="row ">
          <div className="col-12 media">
            <div className="heading">
              <span>Backed by World’s</span>{" "}{" "}
              <span className="media_color_text">&nbsp;top VC firms</span>
              <img src={Rocket} alt="" className="rocket" />
            </div>
          </div>
        </div>
        <div className="vcfirm row">
          <div className="vcfirm_circle col">
            <a
              href="https://www.matrixpartners.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={matrixPartners} className="matrix" alt="matrix" />
            </a>
          </div>
          <div className="vcfirm_circle col">
            <a
              href="https://www.accel.com/india-home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Accel} className="accel" alt="accel" />
            </a>
          </div>
          <div className="vcfirm_circle col">
            <a
              href="https://www.advantedge.vc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Advant} className="advant" alt="advant" />
            </a>
          </div>
        </div>

        <div className="media">
          <div className="heading">
            <span>NAKAD in </span>
            <span className="media_color_text">&nbsp;Media</span>
          </div>
          <div className="row">
            <ul className="media_logo col">
              <li>
                <a
                  href="https://economictimes.indiatimes.com/tech/funding/supply-chain-financing-provider-nakad-raises-7-million-funding/articleshow/91773253.cms"
                  target="_blank"
                >
                  <div className="media_img">
                    <img src={ETlogo} alt="" />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://www.techinasia.com/exclusive-accel-matrix-colead-7m-indian-fintech-firm"
                  target="_blank"
                >
                  <div className="media_img">
                    <img src={TechAsialogo} alt="" />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://www.vccircle.com/matrix-parners-accel-bet-on-msme-fintech-startup-nakad"
                  target="_blank"
                >
                  <div className="media_img">
                    <img src={vcCirclelogo} alt="" />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://inc42.com/buzz/fintech-startup-nakad-raises-7-mn-to-offer-working-capital-to-msmes/"
                  target="_blank"
                >
                  <div className="media_img">
                    <img src={Inc42logo} alt="" style={{ maxHeight: "45px" }} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://yourstory.com/2022/05/msme-supply-chain-fintech-startup-nakad-seed-funding-round-accel-matrix-partners-india/amp"
                  target="_blank"
                >
                  <div className="media_img">
                    <img src={yourstory} alt="yourstory" />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://www.cnbctv18.com/business/companies/funding-rundown-inventus-india-launches-120m-fund-iv-country-delight-raises-108m-dallas-venture-capital-closes-80m-fund-ii-innoviti-raises-addition-96m-shopse-bags-61m-13628372.htm"
                  target="_blank"
                >
                  <div className="media_img">
                    <img src={cnbc} alt="" style={{ width: "200px" }} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://www.pymnts.com/smbs/2022/supply-chain-financing-startup-nakad-raises-7m/"
                  target="_blank"
                >
                  <div className="media_img">
                    <img src={paymnts} alt="" style={{ width: "200px" }} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://startupstorymedia.com/supply-chain-fintech-startup-nakad-funding-accel/?utm_source=rss&utm_medium=rss&utm_campaign=supply-chain-fintech-startup-nakad-funding-accel"
                  target="_blank"
                >
                  <div className="media_img">
                    <img src={startupstorymedia} alt="" />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Media;
