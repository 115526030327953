import React, { Component,useState } from "react";
import Slider from "react-slick";
import "./Life_4.scss";

import image from "../../assetsSvg/Life/Mind/image-(55).svg";
import twitter from "../../assetsSvg/Life/Mind/twitter.svg";
import linkedin from "../../assetsSvg/Life/Mind/linkedin.svg";


function ReadMore({ children, index }) {
    const text = children;
    console.log("hello");
    console.log("read", index);
    
  
    const [isShow, setIsShow] = useState(true);
    const result = isShow ? text.slice(0, 165) : text;
  
    function toggleIsShow0() {
      setIsShow(!isShow);
  
      // console.log("self",self)
      console.log("inside togg", index);
      // self.style.zIndex="3"
      // const item = document.getElementsByClassName("react-multi-carousel-item")
      const item = document.getElementsByClassName("react-multi-carousel-item");
  
      console.log("item", item[0]);
  
      item[0].style.zIndex = "5";
  
      const elmnt = document.getElementById("myDIV");
    console.log("offset1",elmnt.offsetWidth)
    }
  
    function toggleIsShow1() {
      setIsShow(!isShow);
  
      // console.log("self",self)
      // console.log("inside togg",index)
      // self.style.zIndex="3"
      // const item = document.getElementsByClassName("react-multi-carousel-item")
      const item = document.getElementsByClassName("react-multi-carousel-item");
  
      console.log("item", item[1]);
  
      item[1].style.zIndex = "4";
  
      const elmnt = document.getElementById("myDIV");
    console.log("offset2",elmnt.offsetWidth)
    }
  
    function toggleIsShow2() {
      setIsShow(!isShow);
  
      // console.log("self",self)
      // console.log("inside togg",index)
      // self.style.zIndex="3"
      // const item = document.getElementsByClassName("react-multi-carousel-item")
      const item = document.getElementsByClassName("react-multi-carousel-item");
  
      console.log("item", item[2]);
  
      item[2].style.zIndex = "3";
  
  
      const elmnt = document.getElementById("myDIV");
    console.log("offset3",elmnt.offsetWidth)
  
    }
  
    function toggleIsShow3() {
      setIsShow(!isShow);
  
      // console.log("self",self)
      // console.log("inside togg",index)
      // self.style.zIndex="3"
      // const item = document.getElementsByClassName("react-multi-carousel-item")
      const item = document.getElementsByClassName("react-multi-carousel-item");
  
      console.log("item", item[3]);
  
      item[3].style.zIndex = "2";
    }
    function toggleIsShow4() {
      setIsShow(!isShow);
  
      // console.log("self",self)
      // console.log("inside togg",index)
      // self.style.zIndex="3"
      // const item = document.getElementsByClassName("react-multi-carousel-item")
      const item = document.getElementsByClassName("react-multi-carousel-item");
  
      console.log("item", item[4]);
  
      item[4].style.zIndex = "1";
      
    }
  
    let onClickHandler = toggleIsShow0;
  
    if (index === "1") {
      onClickHandler = toggleIsShow1;
    } else if (index === "2") {
      onClickHandler = toggleIsShow2;
    } else if (index === "3") {
      onClickHandler = toggleIsShow3;
    } else if (index === "4") {
      onClickHandler = toggleIsShow4;
    }
  
    // function toggleIsShow(first) {
    //   setIsShow(!isShow);
  
    //   // console.log("self",self)
    //   console.log("inside togg",first)
    //   // self.style.zIndex="3"
    //   // const item = document.getElementsByClassName("react-multi-carousel-item")
    //   const item = document.getElementsByClassName("react-multi-carousel-item")
  
    // console.log("item",item[first])
  
    // // item[first].style.zIndex="3"
    // }
  
    // const item = document.getElementsByClassName("react-multi-carousel-item")
  
    // console.log(item[0])
  
    // item[0].style.zIndex="3"
    
  
    return (
      <p className={isShow ? "text2" : "text3"} id="myDIV">
        {result}
        <span onClick={onClickHandler} className="color_text">
          {isShow ? " Read More" : " Read Less"}
        </span>
      </p>
    );
  }

export default class Life_4acl extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="Life_4_container">
        <div>
          <title className="heading">
            <span className="color_text">Minds&nbsp;</span> Behind NAKAD
          </title>
        </div>
        <div className="minds_container" >
          {/* <div>
            <div className="card1">
              <div className="top">
                <img src={image} alt="" />
                <div className="box"></div>
              </div>
              <div className="bottom1">
                <h5 className="name">Ujawal Kalra</h5>
                <p className="text">Co-founder & CEO</p>
                <p className="img_box">
                  <img src={linkedin} alt="" />
                  <img src={twitter} alt="" style={{ marginLeft: "5px" }} />
                </p>
              </div>
              <div className={show ? "bottom2" : 'bottom3'}>
                {show ? (
                  <div>
                    <p className="text2">
                      Ujwal Kalra is the CEO building this massive machine, NAKAD.
                      Earlier, he worked at the{" "}
                      <span className="color_text" onClick={() => {setShow(!show)}}>Read More</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="text2">
                    Ujwal Kalra is the CEO building this massive machine, NAKAD. Earlier, he worked at the Boston Consulting Group (BCG), where he was a founding member of Growth Tech – BCG’s foray into the startup world. Ujwal graduated from IIM Ahmedabad where he was an Institute Scholar. He completed engineering from IIT Guwahati and schooling from DPS Vasant Kunj. Ujwal is the co-author of Startup Compass, a HarperCollins publication with foreword from Narayana Murthy. He is recipient of The Economic Times Young Leader Award, O.P. Jindal Engineering and Management Scholarship, Dun & Bradstreet Award and Dunia Scholarship, UAE. He also represented India at Kairos USA, an entrepreneurship society. <span className="color_text" onClick={() => {setShow(!show)}}>Read More</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div> */}

          <Slider ref={c => (this.slider = c)}  {...settings}>
            <div>
              <div className="card1">
                <div className="top">
                  <img src={image} alt="" />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">Ujawal Kalra</h5>
                  <p className="text">Co-founder & CEO</p>
                  <p className="img_box">
                    <div className="circle">
                      <img src={linkedin} alt="" />
                    </div>
                    <div className="circle" style={{ marginLeft: "10px" }}>
                      <img src={twitter} alt="" />
                    </div>
                  </p>
                </div>
                <div>
                  <ReadMore index="0">
                    Ujwal Kalra is the CEO building this massive machine, NAKAD.
                    Earlier, he worked at the Boston Consulting Group (BCG),
                    where he was a founding member of Growth Tech – BCG’s foray
                    into the startup world. Ujwal graduated from IIM Ahmedabad
                    where he was an Institute Scholar. He completed engineering
                    from IIT Guwahati and schooling from DPS Vasant Kunj. Ujwal
                    is the co-author of Startup Compass, a HarperCollins
                    publication with foreword from Narayana Murthy. He is
                    recipient of The Economic Times Young Leader Award, O.P.
                    Jindal Engineering and Management Scholarship, Dun &
                    Bradstreet Award and Dunia Scholarship, UAE. He also
                    represented India at Kairos USA, an entrepreneurship
                    society.
                  </ReadMore>
                </div>
                {/* <div className={show ? "bottom2" : "bottom3"}>
                  {show ? (
                    <div>
                      <p className="text2">
                        Ujwal Kalra is the CEO building this massive machine,
                        NAKAD. Earlier, he worked at the{" "}
                        <span
                          className="color_text"
                          onClick={() => {
                            setShow(!show);
                          }}
  
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="text2">
                        Ujwal Kalra is the CEO building this massive machine,
                        NAKAD. Earlier, he worked at the Boston Consulting Group
                        (BCG), where he was a founding member of Growth Tech –
                        BCG’s foray into the startup world. Ujwal graduated from
                        IIM Ahmedabad where he was an Institute Scholar. He
                        completed engineering from IIT Guwahati and schooling from
                        DPS Vasant Kunj. Ujwal is the co-author of Startup
                        Compass, a HarperCollins publication with foreword from
                        Narayana Murthy. He is recipient of The Economic Times
                        Young Leader Award, O.P. Jindal Engineering and Management
                        Scholarship, Dun & Bradstreet Award and Dunia Scholarship,
                        UAE. He also represented India at Kairos USA, an
                        entrepreneurship society.{" "}
                        <span
                          className="color_text"
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top">
                  <img src={image} alt="" />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">Sambhav Jain</h5>
                  <p className="text">Co-founder & COO</p>
                  <p className="img_box">
                    <div className="circle">
                      <img src={linkedin} alt="" />
                    </div>
                    <div className="circle" style={{ marginLeft: "10px" }}>
                      <img src={twitter} alt="" />
                    </div>
                  </p>
                </div>
                <div>
                  <ReadMore index="1">
                    Ujwal Kalra is the CEO building this massive machine, NAKAD.
                    Earlier, he worked at the Boston Consulting Group (BCG),
                    where he was a founding member of Growth Tech – BCG’s foray
                    into the startup world. Ujwal graduated from IIM Ahmedabad
                    where he was an Institute Scholar. He completed engineering
                    from IIT Guwahati and schooling from DPS Vasant Kunj. Ujwal
                    is the co-author of Startup Compass, a HarperCollins
                    publication with foreword from Narayana Murthy. He is
                    recipient of The Economic Times Young Leader Award, O.P.
                    Jindal Engineering and Management Scholarship, Dun &
                    Bradstreet Award and Dunia Scholarship, UAE. He also
                    represented India at Kairos USA, an entrepreneurship
                    society.
                  </ReadMore>
                </div>
                {/* <div className={show ? "bottom2" : "bottom3"}>
                  {show ? (
                    <div>
                      <p className="text2">
                        Ujwal Kalra is the CEO building this massive machine,
                        NAKAD. Earlier, he worked at the{" "}
                        <span
                          className="color_text"
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="text2">
                        Ujwal Kalra is the CEO building this massive machine,
                        NAKAD. Earlier, he worked at the Boston Consulting Group
                        (BCG), where he was a founding member of Growth Tech –
                        BCG’s foray into the startup world. Ujwal graduated from
                        IIM Ahmedabad where he was an Institute Scholar. He
                        completed engineering from IIT Guwahati and schooling from
                        DPS Vasant Kunj. Ujwal is the co-author of Startup
                        Compass, a HarperCollins publication with foreword from
                        Narayana Murthy. He is recipient of The Economic Times
                        Young Leader Award, O.P. Jindal Engineering and Management
                        Scholarship, Dun & Bradstreet Award and Dunia Scholarship,
                        UAE. He also represented India at Kairos USA, an
                        entrepreneurship society.{" "}
                        <span
                          className="color_text"
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
            <div style={{ zIndex: 2 }}>
              <div className="card1">
                <div className="top">
                  <img src={image} alt="" />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">Avinash Uttav</h5>
                  <p className="text">Co-founder & CTO</p>
                  <p className="img_box">
                    <div className="circle">
                      <img src={linkedin} alt="" />
                    </div>
                    <div className="circle" style={{ marginLeft: "10px" }}>
                      <img src={twitter} alt="" />
                    </div>
                  </p>
                </div>
                <div>
                  <ReadMore index="2">
                    Ujwal Kalra is the CEO building this massive machine, NAKAD.
                    Earlier, he worked at the Boston Consulting Group (BCG),
                    where he was a founding member of Growth Tech – BCG’s foray
                    into the startup world. Ujwal graduated from IIM Ahmedabad
                    where he was an Institute Scholar. He completed engineering
                    from IIT Guwahati and schooling from DPS Vasant Kunj. Ujwal
                    is the co-author of Startup Compass, a HarperCollins
                    publication with foreword from Narayana Murthy. He is
                    recipient of The Economic Times Young Leader Award, O.P.
                    Jindal Engineering and Management Scholarship, Dun &
                    Bradstreet Award and Dunia Scholarship, UAE. He also
                    represented India at Kairos USA, an entrepreneurship
                    society.
                  </ReadMore>
                </div>
                {/* <div className={show ? "bottom2" : "bottom3"}>
                  {show ? (
                    <div>
                      <p className="text2">
                        Ujwal Kalra is the CEO building this massive machine,
                        NAKAD. Earlier, he worked at the{" "}
                        <span
                          className="color_text"
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="text2">
                        Ujwal Kalra is the CEO building this massive machine,
                        NAKAD. Earlier, he worked at the Boston Consulting Group
                        (BCG), where he was a founding member of Growth Tech –
                        BCG’s foray into the startup world. Ujwal graduated from
                        IIM Ahmedabad where he was an Institute Scholar. He
                        completed engineering from IIT Guwahati and schooling from
                        DPS Vasant Kunj. Ujwal is the co-author of Startup
                        Compass, a HarperCollins publication with foreword from
                        Narayana Murthy. He is recipient of The Economic Times
                        Young Leader Award, O.P. Jindal Engineering and Management
                        Scholarship, Dun & Bradstreet Award and Dunia Scholarship,
                        UAE. He also represented India at Kairos USA, an
                        entrepreneurship society.{" "}
                        <span
                          className="color_text"
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top">
                  <img src={image} alt="" />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">4Ujawal Kalra</h5>
                  <p className="text">Co-founder & CEO</p>
                  <p className="img_box">
                    <div className="circle">
                      <img src={linkedin} alt="" />
                    </div>
                    <div className="circle" style={{ marginLeft: "10px" }}>
                      <img src={twitter} alt="" />
                    </div>
                  </p>
                </div>
                <div>
                  <ReadMore index="3">
                    Ujwal Kalra is the CEO building this massive machine, NAKAD.
                    Earlier, he worked at the Boston Consulting Group (BCG),
                    where he was a founding member of Growth Tech – BCG’s foray
                    into the startup world. Ujwal graduated from IIM Ahmedabad
                    where he was an Institute Scholar. He completed engineering
                    from IIT Guwahati and schooling from DPS Vasant Kunj. Ujwal
                    is the co-author of Startup Compass, a HarperCollins
                    publication with foreword from Narayana Murthy. He is
                    recipient of The Economic Times Young Leader Award, O.P.
                    Jindal Engineering and Management Scholarship, Dun &
                    Bradstreet Award and Dunia Scholarship, UAE. He also
                    represented India at Kairos USA, an entrepreneurship
                    society.
                  </ReadMore>
                </div>
                {/* <div className={show ? "bottom2" : "bottom3"}>
                  {show ? (
                    <div>
                      <p className="text2">
                        Ujwal Kalra is the CEO building this massive machine,
                        NAKAD. Earlier, he worked at the{" "}
                        <span
                          className="color_text"
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="text2">
                        Ujwal Kalra is the CEO building this massive machine,
                        NAKAD. Earlier, he worked at the Boston Consulting Group
                        (BCG), where he was a founding member of Growth Tech –
                        BCG’s foray into the startup world. Ujwal graduated from
                        IIM Ahmedabad where he was an Institute Scholar. He
                        completed engineering from IIT Guwahati and schooling from
                        DPS Vasant Kunj. Ujwal is the co-author of Startup
                        Compass, a HarperCollins publication with foreword from
                        Narayana Murthy. He is recipient of The Economic Times
                        Young Leader Award, O.P. Jindal Engineering and Management
                        Scholarship, Dun & Bradstreet Award and Dunia Scholarship,
                        UAE. He also represented India at Kairos USA, an
                        entrepreneurship society.{" "}
                        <span
                          className="color_text"
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top">
                  <img src={image} alt="" />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">5Ujawal Kalra</h5>
                  <p className="text">Co-founder & CEO</p>
                  <p className="img_box">
                    <div className="circle">
                      <img src={linkedin} alt="" />
                    </div>
                    <div className="circle" style={{ marginLeft: "10px" }}>
                      <img src={twitter} alt="" />
                    </div>
                  </p>
                </div>
                <div>
                  <ReadMore index="4">
                    Ujwal Kalra is the CEO building this massive machine, NAKAD.
                    Earlier, he worked at the Boston Consulting Group (BCG),
                    where he was a founding member of Growth Tech – BCG’s foray
                    into the startup world. Ujwal graduated from IIM Ahmedabad
                    where he was an Institute Scholar. He completed engineering
                    from IIT Guwahati and schooling from DPS Vasant Kunj. Ujwal
                    is the co-author of Startup Compass, a HarperCollins
                    publication with foreword from Narayana Murthy. He is
                    recipient of The Economic Times Young Leader Award, O.P.
                    Jindal Engineering and Management Scholarship, Dun &
                    Bradstreet Award and Dunia Scholarship, UAE. He also
                    represented India at Kairos USA, an entrepreneurship
                    society.
                  </ReadMore>
                </div>
                {/* <div className={show ? "bottom2" : "bottom3"}>
                  {show ? (
                    <div>
                      <p className="text2">
                        Ujwal Kalra is the CEO building this massive machine,
                        NAKAD. Earlier, he worked at the{" "}
                        <span
                          className="color_text"
                          onClick={(e) => {
                            setShow(!show);
                          }}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="text2">
                        Ujwal Kalra is the CEO building this massive machine,
                        NAKAD. Earlier, he worked at the Boston Consulting Group
                        (BCG), where he was a founding member of Growth Tech –
                        BCG’s foray into the startup world. Ujwal graduated from
                        IIM Ahmedabad where he was an Institute Scholar. He
                        completed engineering from IIT Guwahati and schooling from
                        DPS Vasant Kunj. Ujwal is the co-author of Startup
                        Compass, a HarperCollins publication with foreword from
                        Narayana Murthy. He is recipient of The Economic Times
                        Young Leader Award, O.P. Jindal Engineering and Management
                        Scholarship, Dun & Bradstreet Award and Dunia Scholarship,
                        UAE. He also represented India at Kairos USA, an
                        entrepreneurship society.{" "}
                        <span
                          className="color_text"
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
            <div></div>
          </Slider>
          <div style={{ textAlign: "center" }}>
            <button className="button" onClick={this.previous}>
              Previous
            </button>
            <button className="button" onClick={this.next}>
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}
