import React, { useEffect, useRef } from "react";
// import './Page1.scss'
import "./Page_1.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

const Page3Text = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    // console.log(sectionRef);
    gsap.from(sectionRef.current, {
      duration: 2,
      autoAlpha: 0,
      ease: "none",
      delay: 2,
    });

    gsap.fromTo(
      sectionRef.current,
      { autoAlpha: 0 },
      { duration: 1,
        autoAlpha: 1,
        ease:'fade out',
        scrollTrigger: {
          id:'section-1',
          trigger:sectionRef.current,
          start:'top center-=200',
          toggleActions:'play none none reverse',
          markers:true
        }
      }
    );
  }, []);

  return (
    <div ref={sectionRef} id='section-1'>
      <div className="text_container">
        <div className="text">
          <span>
            <span className="color_text">Working Capital</span> Constraint
          </span>{" "}
          <br />
          <span>of Tier 2</span> <br />
        </div>
      </div>
      <div className="text_box">
        <div className="ver_box"></div>
        {/* <div className='hr_box'> */}
        <ul className="text">
          {/* <li>Tap into $20 Billion informal market</li>
                    <li>Aquire new MSME customers</li>
                    <li>Lend on Anchor's risk profile</li>
                    <li>Fully LMS integrated solution</li> */}
          <li>High cost of credit</li>
          <li>Inability to procure and supply on time</li>
        </ul>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Page3Text;
