import React, { useEffect } from 'react'
import ArpService from './ArpService'
import Client from './Client'
import Benefits from './Benefits'
import Solutions from './Solutions'
import Testimonials from './Testimonials'
import Pricing from './Pricing'
import Sales from './Sales'
import Footer from '../Footer/Footer'

const Arp = () => {
  // useEffect(() => {
  //   document.body.style.overflowY = 'auto'
  //   document.body.style.overflowX = 'hidden'
  // },[])
  return (
    <>
<ArpService />
<Client />
<Benefits />
<Solutions />
<Testimonials />
<Pricing />
<Sales />
<Footer />
    </>
  )
}

export default Arp