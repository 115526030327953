import React from 'react'
import './TermsOfUse.scss'
import '../PrivacyPolicy/PrivacyPolicy.scss'

const TermsOfUse = () => {
  return (
    <div className="container">
    <div className="row">
    <div className="">

<div className="card">
    <div className="card-body card_shadow text_align_justify">
    <h1 className="text-center privacy_header">TERMS OF USE</h1>
    <ol type="1" className='mr_30'>

    <li>
    <section className="privacy_sub_header_section">
    <h4 className="privacy_sub_header">GENERAL</h4>
    <ol type="a">
        <li>
        This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.

        </li>

        <li>
        This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of <a href="https://www.nakad.co/" className="cursor_pointer" target="_blank" rel="noreferrer">https://www.nakad.co/</a> 

        </li>

        <li>
        The domain name  <a href="https://www.nakad.co/" className="cursor_pointer" target="_blank" rel="noreferrer">https://www.nakad.co/</a>(<b>"Website"</b>), including its online services, such as use of software on Website and information, articles , news, blogs and text, graphics, images and information obtained from service providers and any other material contained on the Website is owned and operated by AlphaStar Technologies Private Limited (CIN - U72900DL2021PTC389698), a private limited company incorporated under the Companies Act, 2013, having its registered office at Plot No. 27, 3rd Floor, Community Centre, Naraina Vihar, Phase-I, DELHI, South West Delhi, India, 110028, (<b>Company/NAKAD</b>) where such expression shall, unless repugnant to the context thereof, be deemed to include its respective representatives, administrators, employees, directors, officers, agents and their successors and assigns.

        </li>

        <li>
        For the purpose of these Terms of Use (<b>“Terms”</b>), wherever the context so requires, 
        <ol type='i'>
            <li>
            The term <b>‘You’ or “User”</b> shall mean any legal person or entity accessing using this Website to services provided or registered on this Website, who is competent to enter into binding contracts, as per the provisions of the Indian Contract Act, 1872;

            </li>
            <li>
            The terms <b> ‘We’, ‘Us’ & ‘Our’ </b> shall mean the Website and/or the Company, as the context so requires.

            </li>
            <li>
            The terms <b> ‘Party’& ‘Parties’ </b> shall respectively be used to refer to the User and the Company individually and collectively, as the context so requires. 

            </li>
            
        </ol>
        </li>

        <li>
            The headings of each section in these Terms are only for the purpose of organizing the various provisions under these Terms in an orderly manner, and shall not be used by either Party to interpret the provisions contained herein in any manner. Further, it is specifically agreed to by the Parties that the headings shall have no legal or contractual value.

            </li>
            <li>
            The use of the Website by the user is solely governed by these Terms as well as the Privacy Policy (<b>“Policy”</b>), available on Website and any modifications or amendments made thereto by the Company from time to time, at its sole discretion. Visiting the home page of the Website and/or using any of the services provided on the Website shall be deemed to signify the User’s unequivocal acceptance of these Terms and the aforementioned Policy, and the User expressly agrees to be bound by the same. The User expressly agrees and acknowledges that the Terms and Policy are co-terminus, and that expiry / termination of either one will lead to the termination of the other, save as provided in Clause 4 hereunder.

            </li>
            <li>
            The User unequivocally agrees that these Terms and the aforementioned Policy constitute a legally binding agreement between the User and the Company, and that the User shall be subject to the rules, guidelines, policies, terms, and conditions applicable to any service that is provided by the Website, and that the same shall be deemed to be incorporated into these Terms, and shall be treated as part and parcel of the same. The User acknowledges and agrees that no signature or express act is required to make these Terms and the Policy binding on the User, and that the User’s act of visiting the any part of the Website constitutes the User’s full and final acceptance of these Terms and the aforementioned Policy.

            </li>
            <li>
            The Company reserves the sole and exclusive right to amend or modify these Terms without any prior permission or intimation to the User, and the User expressly agrees that any such amendments or modifications shall come into effect immediately. The User has a duty to periodically check the terms and stay updated on its requirements.  If the User continues to use the Website following such a change, the User will be deemed to have consented to any and all amendments / modifications made to the Terms. In so far as the User complies with these Terms, he/she is granted a personal, non-exclusive, non-transferable, revocable, limited privilege to enter and use the Website.

            </li>

    </ol>
    </section>
    </li>

    <li>
    <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">WHO WE ARE</h4>
                <p className="without_li_text">
                <b>“NAKAD- Unlocking small businesses of cash flow burden”</b>- Founded in 2021, NAKAD is an innovative online portal that specializes in providing technology solutions to facilitate working capital financing solution for Anchors/Borrowers. Our platform serves as a seamless bridge between businesses in need of capital and lenders and other registered parties looking to invest in such opportunities.

                </p>
                <p className="without_li_text">
                Our online portal serves as a centralized hub where Anchors/Borrowers can connect with a network of lenders and other financial entities registered on our platform. We provide a secure and efficient environment for businesses to find the right funding solutions.

                </p>
                <p className="without_li_text">
                Through our advanced technology solutions, we facilitate the seamless exchange of information and funds between Anchors/Borrowers and Lenders. Our platform automates the entire financing process, reducing the time it takes to access funds. This allows businesses to focus on their core operations while we handle the financing logistics.

                </p>
                <p className="without_li_text">
                By bringing together Anchors/Borrowers and Lenders, we create a thriving platform that benefits both parties. Businesses gain access to a diverse range of financing options, enabling them to optimize their working capital and seize growth opportunities. Lenders, on the other hand, can diversify their investment portfolio and earn attractive returns by participating in financing transactions.

                </p>
                <p className="without_li_text">
                At NAKAD, we believe that financial empowerment is crucial for businesses to reach their full potential. We are dedicated to revolutionizing the way working capital financing is obtained by leveraging technology and fostering meaningful connections between businesses and lenders. Together, we can drive economic growth and empower businesses to thrive in today's dynamic environment.

                </p>
               
                </section>
    </li>

    <li>
    <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">ELIGIBILITY</h4>
                <p className="without_li_text">
                The User represents and warrants that he/she is competent and eligible to enter into legally binding agreements and that he/she has the requisite authority to bind himself/herself to these Terms, as determined solely by the provisions of the Indian Contract Act, 1872. The User may not use this website if he/she is not competent to contract under the Indian Contract Act, 1872, or is disqualified from doing so by any other applicable law, rule or regulation currently in force.

                </p>
                </section>
    </li>

    <li>
<section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">TERM</h4>
                <p className="without_li_text">
                These Terms shall continue to form a valid and binding contract between the Parties, and shall continue to be in full force and effect until: 
<ol type='a'>
    <li>
    The User continues to access and use the Website; or

    </li>
    <li>
    The Transaction between the Parties, if any, concludes to the satisfaction of both Parties;

    </li>
    <li>
    You agree and acknowledge that <a href="https://www.nakad.co/" className="cursor_pointer" target="_blank" rel="noreferrer">https://www.nakad.co/</a> control in any manner any transactions on the Website. Accordingly, the contract of sale of services on the Website shall be a strictly bipartite contract between you and the Website on <a href="https://www.nakad.co/" className="cursor_pointer" target="_blank" rel="noreferrer">https://www.nakad.co/</a>.

    </li>
</ol>
                </p>
                </section>
    </li>

    <li>
    <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">TERMINATION</h4>
                <p className="without_li_text">
                The Company reserves the right, in its sole discretion, to unilaterally terminate the User’s access to the services offered on the Website, or any portion thereof, at any time, without notice or cause. The User shall continue to be bound by these Terms, and it is expressly agreed to by the Parties that the User shall not have the right to terminate these Terms till the expiry of the same, as described in Section 3 herein above. 

                </p>
                </section>
    </li>

    <li>
    <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">FACILITIES/SERVICES TO BE RENDERED BY THE COMPANY</h4>
                <p className="without_li_text">
                As a trusted technology platform, we offer a comprehensive range of services in strict compliance with regulatory guidelines and industry best practices. We strive to uphold the highest service standards to ensure a seamless and efficient experience for our valued clients.

                </p>
                <p className="without_li_text">
                <b>Vendor Customers:</b> We identify the Anchors / Borrowers in need of working capital finance and tie up with them to provide our Innovative solution in partnership with a Lender.

                </p>
                <p className="without_li_text">
                <b>Relationship Management:</b> We establish, manage, and maintain necessary relationships with Anchors and their Vendors. Our team ensures effective communication and coordination between the Anchor and all relevant parties involved in the financing process.

                </p>
                <p className="without_li_text">
                <b>Application Processing:</b> We facilitate the entire process, right from receiving the application, sharing of details required for completing the Due Diligence to execution of agreements as per the requirement of the Lender.

                </p>
                <p className="without_li_text">
                <b>Data Sharing:</b> With proper consent obtained from Interested Anchors and Vendors and in compliance with applicable laws, we share customer data with Lenders. This allows Lenders to make informed decisions based on the provided information while adhering to privacy and data protection regulations.

                </p>
                <p className="without_li_text">
                <b>Risk Assessment:</b> We facilitate the process of due diligence and preliminary risk assessments for Anchors/Borrowers seeking to avail themselves of financing facilities by providing all the necessary details required by the Lender/s. This assessment helps lenders evaluate the creditworthiness and suitability of potential borrowers, supporting responsible lending practices.

                </p>
                <p className="without_li_text">
                <b>Document Storage:</b> We do not store any sensitive Customer information with us. At NAKAD, we prioritize the confidentiality and protection of customer information, handling it with the same level of care and security as we do with our own data. When sharing such data with any third party, including banks, we ensure that strict confidentiality measures are in place to safeguard sensitive information.

                </p>
                <p className="without_li_text">
                We maintain a robust framework to protect customer data from unauthorized access, disclosure, or misuse

                </p>
                <p className="without_li_text">
                <b>Repayment Solutions:</b> On behalf of lenders, we provide repayment mechanisms and solutions to Borrower/s for the payment of owed amounts as per the terms agreed upon between the parties. These solutions streamline the repayment process, making it convenient for both parties involved.

                </p>
                <p className="without_li_text">
                <b>Technical Integration:</b> We offer necessary technical integration services to define eligibility criteria, display relevant information regarding the financing facility, and present repayment mechanisms for owed amounts. This integration ensures a seamless user experience and efficient access to financing options.

                </p>
                <p className="without_li_text">
                At NAKAD, we are committed to delivering these services with the utmost professionalism and in compliance with all applicable laws and RBI regulations. Our goal is to empower businesses, lenders, and vendors by providing a robust platform that facilitates transparent and efficient working capital financing.

                </p>
                <p className="without_li_text">
                It is expressly agreed to by the Parties that any information shared by the User with the Company shall be governed by the Policy.

                </p>
                </section>
                </li>

                <li>
    <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">TERMS, CONDITIONS AND DUTIES OF THE USER</h4>
                <ol type='a'>
                    <li>
                    It is the duty of User to provide one’s true/accurate/complete information, and all the details relating thereof on the Website. If any User details are found to be false then Company shall reserve the right on its sole discretion to reject the registration and debar the User from using the Services available on its Website, without prior intimation whatsoever.

                    </li>
                    <li>
                    The User agrees to comply with all notices or instructions given by Company from time to time to enable the use of the Services.

                    </li>
                    <li>
                    User shall indemnify and hold the Company, harmless for any loss or damage arising out of User’s to comply with any applicable laws or regulations and for breach of the following warranties and representations.

                    </li>
                    <li>
                    The User shall at all times during the pendency of this agreement endeavor to protect and promote the interest of the Company and ensure that there will be no damage to third party (client/customer) due to act/ omission on the part of the User.

                    </li>
                    <li>
                    User is bound not to cut, copy, distribute, modify, recreate, reverse engineer, distribute, disseminate post, publish or create derivative works from, transfer, or sell any information or software obtained from the Website. Any such use / limited use of the Website will only be allowed with the prior express written permission of the Company. For the removal of doubt, it is clarified that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modification of data and information contained on the Website is expressly prohibited.

                    </li>
                    <li>
                    The user will be responsible for maintaining the confidentiality of the account and password for restricting access to your computer to prevent unauthorized access to the account.

                    </li>
                    <li>
                    The User further undertakes not to: 
                        <ol type='i'>
                            <li>
                            Engage in any activity that interferes with or disrupts access to the Website or the services provided therein (or the servers and networks which are connected to the Website);
                            </li>
                            <li>
                            Publish, post, disseminate, any information which is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever under any law, rule or regulation currently in force; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;

                            </li>
                            <li>
                            Post any image/file/data that infringes the copyright, patent or trademark of another person or legal entity;

                            </li>
                            <li>
                            Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website;

                            </li>
                            <li>
                            Download any file posted/uploaded by another User of the Website that the User is aware, or should reasonably be aware, cannot be legally distributed in such a manner;

                            </li>
                            <li>
                            Probe, scan or test the vulnerability of the Website or any network connected to the Website, nor breach the security or authentication measures on the Website or any network connected to the Website. The User may not reverse look-up, trace or seek to trace any information relating to any other User of, or visitor to, the Website, or any other customer of the Website, including any User account maintained on the Website not operated/managed by the User, or exploit the Website or information made available or offered by or through the Website, in any manner;

                            </li>
                            <li>
                            Disrupt or interfere with the security of, or otherwise cause harm to, the Website, systems resources, accounts, passwords, servers or networks connected to or accessible through the Mobile Websites or any affiliated or linked Mobile Websites;

                            </li>
                            <li>
                            Use the Website or any material or content therein for any purpose that is unlawful or prohibited by these Terms, or to solicit the performance of any illegal activity or other activity which infringes the rights of this Website or any other third party/ies;

                            </li>
                            <li>
                            Violate any code of conduct or guideline which may be applicable for or to any particular service offered on the Website;

                            </li>
                            <li>
                            Violate any applicable laws, rules or regulations currently in force within or outside India;

                            </li>
                            <li>
                            Violate any portion of these Terms or the Policy, including but not limited to any applicable additional terms of the Website contained herein or elsewhere, whether made by amendment, modification, or otherwise;

                            </li>
                            <li>
                            Threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order, or cause incitement to the commission of any cognizable offence, or prevent the investigation of any offence, or insult any other nation.

                            </li>
                            <li>
                            Publish, post, or disseminate information that is false, inaccurate or misleading; 

                            </li>
                            <li>
                            Commit any act that causes the Company to lose (in whole or in part) the services of its internet service provider (<b>"ISP"</b>) or in any manner disrupts the services of any other supplier/service provider of the Company/Website;

                            </li>
                            <li>
                            The User hereby expressly authorises the Company/Website to disclose any and all information relating to the User in the possession of the Company /Website to law enforcement or other government officials, as Company may in its sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those involve personal injury and theft / infringement of intellectual property. The User further understands that the Company/Website might be directed to disclose any information (including the identity of persons providing information or materials on the Website as necessary to satisfy any judicial order, law, regulation or valid governmental request.

                            </li>
                        </ol>
                    </li>
                </ol>
                </section>
                </li>

                <li>
    <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">TERMS, CONDITIONS, AND DUTIES OF COMPANY</h4>
                    <ol type='a'>
                        <li>
                        The Company shall list its services on the Website in accordance with the policies.
                        </li>
                        <li>
                        The Company shall be legally authorized to display services(s) listed for sale on Website and have all the necessary licenses and permits required for such sale.

                        </li>
                        <li>
                        Duty of Company not to mislead the description of any service or item and describe actual terms and conditions of the same.

                        </li>
                        <li>
                        Company grants you a limited license to access and make personal use of this Website, but not to download (other than page caching) or modify it, or any portion of it, except with express written consent of Company and / or its affiliates, as may be applicable.

                        </li>
                    </ol>
                </section>
                </li>

                <li>
    <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">SUSPENSION OF USER ACCESS AND ACTIVITY</h4>
                <p className="without_li_text">
                Notwithstanding other legal remedies that may be available to it, Company may in its sole discretion limit the User’s access and/ or activity by immediately removing the User’s access credentials either temporarily or indefinitely, or suspend / terminate the User’s membership, and/or refuse to provide User with access to the Website, without being required to provide the User with notice or cause:
                </p>
                <ol type='a'>
                    <li>
                    If the User is in breach any of these Terms or the Policy;

                    </li>
                    <li>
                    If the User has provided wrong, inaccurate, incomplete or incorrect information;

                    </li>
                    <li>
                    If the User’s actions may cause any harm, damage or loss to the other Users or to the Website/Company, at the sole discretion of the Company.

                    </li>
                    <li>
                    If User’ action ccopying or duplicating in any manner any of content or other information available from the Website.
                    </li>
                </ol>
                </section>
                </li>
<li>
<section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">SUSPENSION OF USER ACCESS AND ACTIVITY</h4>
                <p className="without_li_text">
                The User hereby expressly agrees to defend, indemnify and hold harmless the Website and the Company, its directors, affiliate Users, employees, officers, agents and their successors and assigns and against any and all claims, liabilities, damages, losses, costs and expenses, including attorney's fees, caused by or arising out of claims based upon the User's actions or inactions, including but not limited to any warranties, representations or undertakings, or in relation to the non-fulfilment of any of the User’s obligations under this Agreement, or arising out of the User's infringement of any applicable laws, rules and regulations, including but not limited to infringement of intellectual property rights, payment of statutory dues and taxes, claims of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers, or the infringement of any other rights of a third party. 

                    </p>
                <p className="without_li_text">
                In no event shall the Company/Website be liable to compensate the User or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not the Company/Website had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with the User’s use of or access to the Website and/or the services, products or materials contained therein.

                    </p>

                <p className="without_li_text">
                The limitations and exclusions in this section apply to the maximum extent permitted by applicable law, and the Parties expressly agree that in the event of any statute, rule, regulation or amendment coming into force that would result in the Company/Website incurring any form of liability whatsoever, these Terms and the Policy will stand terminated one (1) day before the coming into effect of such statute, rule, regulation or amendment. It is further agreed to by the Parties that the contents of this clause shall survive even after the termination or expiry of the Terms and/or Policy.

                    </p>
               
                    </section>
</li>

<li>
<section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">INTELLECTUAL PROPERTY RIGHTS</h4>
                <p className="without_li_text">
                Unless expressly agreed to in writing, nothing contained herein shall give the User a right to use any of the Website’s trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports, images, videos and other distinctive brand features, save according to the provisions of these Terms. All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by the Website and other distinctive brand features of the Website are the property of the Company. Furthermore, with respect to the Website created by the Company the Company shall be the exclusive owner of all the designs, graphics and the like, related to the Website. 

                </p>
                <p className="without_li_text">
                The User may not use any of the intellectual property displayed on the Website in any manner that is likely to cause confusion among existing or prospective Users of the Website, or that in any manner disparages or discredits the Company/Website, to be determined in the sole discretion of the Company.

                </p>
                <p className="without_li_text">
                The User is further aware that any reproduction or infringement of the intellectual property of the aforementioned owners by the User will result in legal action being initiated against the User by the respective owners of the intellectual property so reproduced / infringed upon. It is agreed to by the Parties that the contents of this clause shall survive even after the termination or expiry of the Terms and/or Policy.

                </p>
                </section>
                </li>

                <li>
                <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">DISCLAIMER OF WARRANTIES AND LIABILITIES</h4>
                <ol type='a'>
                    <li>
                    Except as otherwise expressly stated on the Website, all services offered on the Website are offered on an "as is" basis without any warranty whatsoever, either express or implied.

                    </li>
                    <li>
                    Information accessed through Company Website and other touch points is presented in summary form and is not exhaustive. Company makes no warranties or representations as to its accuracy or completeness. This information is provided "as is" without warranty of any kind, either express or implied, including but not limited to implied warranties of merchantability, fitness for particular purpose, or non-infringement. 

                    </li>
                    <li>
                    The Company/Website does not guarantee that the functions and services contained in the Website will be uninterrupted or error-free, or that the Website or its server will be free of viruses or other harmful components, and the User hereby expressly accepts any and all associated risks involved with the User’s use of the Website.

                    </li>
                    <li>
                    It is further agreed to by the Parties that the contents of this Clause shall survive even after the termination or expiry of the Terms and/or Policy.

                    </li>
                </ol>
                </section>
                </li>

                <li>
                <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">SUBMISSIONS</h4>
                <p className="without_li_text">
                Any comments, feedback,  ideas, suggestions, initiation, or any other content contributed by the User to the Company or this Website will be deemed to include a royalty-free, perpetual, irrevocable, nonexclusive right and license for the Company to adopt, publish, reproduce, disseminate, transmit, distribute, copy, use, create derivative works, display worldwide, or act on such content, without additional approval or consideration, in any media, or technology now known or later developed, for the full term of any rights that may exist in such content, and the User hereby waives any claim to the contrary. The User hereby represents and warrants that he/she owns or otherwise controls all of the rights to the content contributed to the Website, and that use of such content by the Company/Website does not infringe upon or violate the rights of any third party. In the event of any action initiated against the Company/Website by any such affected third party, the User hereby expressly agrees to indemnify and hold harmless the Company/Website, for its use of any such information provided to it by the User. The Company reserves its right to defend itself in any such legal disputes that may arise, and recover the costs incurred in such proceedings from the User.

                </p>
                </section>
                </li>

                <li>
                <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">DISPUTE RESOLUTION AND JURISDICTION</h4>
                <p className="without_li_text">
                It is expressly agreed to by the Parties hereto that the formation, interpretation and performance of these Terms and any disputes/claim/refund/compensation arising here from will be resolved through a two-step Alternate Dispute Resolution (<b>“ADR”</b>) mechanism. It is further agreed to by the Parties that the contents of this Clause shall survive even after the termination or expiry of the Terms and/or Policy.

                </p>
                <ol type='a'>
                    <li>
                    <b>Mediation:</b> In case of any dispute/claim/refund/compensation between the parties, the Parties will attempt to resolve the same amicably amongst themselves, to the mutual satisfaction of both Parties. In the event that the Parties are unable to reach such an amicable solution within thirty (30) days of one Party communicating the existence of a dispute to the other Party, the dispute will be resolved by arbitration, as detailed herein below; 

                    </li>
                    <li>
                    <b>Arbitration:</b> In the event that the Parties are unable to amicably resolve a dispute by mediation, said dispute will be referred to arbitration under Arbitration and Conciliation Act, 1996 by a sole arbitrator to be appointed by the Company, and the award passed by such sole arbitrator will be valid and binding on both Parties. The Parties shall bear their own costs for the proceedings, although the sole arbitrator may, in his/her sole discretion, direct either Party to bear the entire cost of the proceedings. The seat of Arbitration shall be in the New Delhi, India. 

                    </li>
                    <li>
                    This Agreement shall be governed by and construed in accordance with the laws of India. The Parties hereby agree to submit to the jurisdiction of the competent Courts at New Delhi.

                    </li>
                </ol>
                </section>
                </li>

                <li>
                <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">NOTICES</h4>
                <p className="without_li_text">
                Any or all kind of communication relating to any dispute or grievance experienced by the User may be communicated to the Company by the User reducing the same to writing, and sending the same to the registered office of the Company by Registered Post Acknowledgement Due / Speed Post Acknowledgement Due (<b>RPAD / SPAD</b>).

                </p>
                </section>
                </li>

                <li>
                <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">MISCELLANEOUS PROVISIONS</h4>
                <ol type='a'>
                    <li>
                    <b>Entire Agreement:</b> These Terms, read with the Policy form the complete and final contract between the User and the Company with respect to the subject matter hereof and supersedes all other communications, representations and agreements (whether oral, written or otherwise) relating thereto.

                    </li>
                    <li>
                    <b>Waiver:</b> The failure of either Party at any time to require performance of any provision of these Terms shall in no manner affect such Party's right at a later time to enforce the same. No waiver by either Party of any breach of these Terms, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms. 

                    </li>
                    <li>
                    <b>Severability:</b> If any provision/clause of these Terms is held to be invalid, illegal or unenforceable by any court or authority of competent jurisdiction, the validity, legality and enforceability of the remaining provisions/clauses of these Terms shall in no way be affected or impaired thereby, and each such provision /clause of these Terms shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein.
                    </li>
                </ol>
                </section>
                </li>



    </ol>
        </div>
        </div>

        </div>
        </div>
        </div>
  )
}

export default TermsOfUse