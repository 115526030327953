import React, { useState } from "react";
import "./Life_6.scss";

import holy from "../../assetsSvg/Life/Journey/holy.svg";
import future from "../../assetsSvg/Life/Journey/future.svg";

const Life_6 = () => {
  const [show, setShow] = useState(true);
  return (
    <div className="Life_6_container">
      <div>
        <title className="heading">
          Our<span className="color_text">&nbsp;Journey</span>
        </title>
      </div>
      {/* <div className="journey_container">
        {show ? (
          <div>
            <div className="title">
              <h2>Discovery of Holy Grail</h2>
            </div>
            <div className="img_box">
              <img src={holy} alt="" className="image" />
            </div>
            <div className="btn_box">
              <button className="btn" onClick={() => setShow(!show)}>
                <span className="text">
                  Stairway to Heaven(Future)
                  <span>
                    &nbsp; &nbsp;<i className="bi bi-arrow-right"></i>
                  </span>
                </span>
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="title">
              <h2>Stairway to Heaven(Future)</h2>
            </div>
            <div className="img_box">
              <img src={future} alt="" className="image" />
            </div>
            <div className="btn_box">
              <button className="btn" onClick={() => setShow(!show)}>
                <span className="text">
                  <span>
                    <i className="bi bi-arrow-left"></i> &nbsp; &nbsp;
                  </span>
                  Discovery of holy grail
                </span>
              </button>
            </div>
          </div>
        )}
      </div> */}
      <div className="journey_container">
        <div>
          {show ? (
            <div className="title">
              <h2>Discovery of Holy Grail</h2>
            </div>
          ) : (
            <div className="title">
              <h2>Stairway to Heaven(Future)</h2>
            </div>
          )}

          <div className={show ? "img_box_holy" : "img_box_future"}>
            <img src={holy} alt="" className="image" />
            <img src={future} alt="" className="image" />
          </div>

          {/* <div className="btn_box">
            <button className="btn" onClick={() => setShow(!show)}>
              {show ? (
                <span className="text">Stairway to Heaven(Future)</span>
              ) : (
                <span className="text">Discovery of holy grail</span>
              )}

              <span className={show ? "arrow_end" : "arrow_start"}>
                <i
                  className={show ? "bi bi-arrow-right" : "bi bi-arrow-left "}
                ></i>
              </span>
            </button>
          </div> */}

          {show ? (
            <div className="btn_box">
              <button className="btn" onClick={() => setShow(!show)}>
                <span className="text">
                  Stairway to Heaven(Future)
                  <span>
                    &nbsp; &nbsp;<i className="bi bi-arrow-right"></i>
                  </span>
                </span>
              </button>
            </div>
          ) : (
            <div className="btn_box">
              <button className="btn" onClick={() => setShow(!show)}>
                <span className="text">
                  <span>
                    <i className="bi bi-arrow-left"></i> &nbsp; &nbsp;
                  </span>
                  Discovery of holy grail
                </span>
              </button>
            </div>
          )}
        </div>
      </div>

      {/*------- mobile -------*/}
      <div className="journey_container_mobile">
          <div className="img_container">
            <img src={holy} alt="" className="image" />
            <img src={future} alt="" className="image" />
          </div>
      </div>


    </div>
  );
};

export default Life_6;
