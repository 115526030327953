import React,{useRef} from 'react'
// import './Page_2.scss'
import {useIntersection} from 'react-use';
import gsap from 'gsap';

const Page2Text = () => {
  const sectionRef = useRef(null);
  const intersection = useIntersection(sectionRef , {
    root:null,
    rootMargin:'0px',
    threshold:0.9
  });

  const fadeIn = element => {
    gsap.to(element,  {
      duration:1,
      opacity:1,
      y:-60,
      ease:'power4.out',
      stagger:{
        amount:0.3
      }
    })
  }

  const fadeOut = element => {
    gsap.to(element,  {
      duration:1,
      opacity:0,
      y:-20,
      ease:'power4.out'
    });
  }

  intersection && intersection.intersectionRatio < 0.5
  ? fadeOut('.fadeIn')
  : fadeIn('.fadeout')

  return (
    <>
       <div className="text_container" ref={sectionRef}>
          <div className="text" className="fadeIn">
            <span><span className="color_text">Working Capital</span> Constraint</span> <br />
            <span>of Tier 2</span> <br />
            <span>Impacts the <span className="color_text">Supply chain </span>of</span>
            <br />
            <span>Tier-1 and Anchor</span>
          </div>
        </div>
      
    </>
  )
}

export default Page2Text