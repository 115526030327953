import React from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="row">
        {/* col-md-8 offset-md-2 */}
        

          
        <div className="">

        <div className="card">
            <div className="card-body card_shadow text_align_justify">

          <h1 className="text-center privacy_header">PRIVACY POLICY</h1>
          <ol type="1" className="mr_30">
            <li>
              <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">GENERAL</h4>
                <ol type="a">
                  <li>
                    We value safety and privacy of your personal data. We have
                    formulated this Privacy Policy to make the users understand
                    our process of collecting information and parameters for
                    non-disclosure. This document is an electronic record in
                    terms of Information Technology Act, 2000 and rules there
                    under as applicable and the amended provisions pertaining to
                    electronic records in various statutes as amended by the
                    Information Technology Act, 2000. This electronic record is
                    generated by a computer system and does not require any
                    physical or digital signatures.
                  </li>
                  <li>
                    This document is published in accordance with the provisions
                    of Rule 3 (1) of the Information Technology (Intermediaries
                    guidelines) Rules, 2011 that require publishing the Rules
                    and Regulations, Privacy Policy and Terms of Use for access
                    or usage <a href="https://www.nakad.co/" className="cursor_pointer" target="_blank" rel="noreferrer">https://www.nakad.co/</a>
                  </li>
                  <li>
                    The domain name <a href="https://www.nakad.co/" className="cursor_pointer" target="_blank" rel="noreferrer">https://www.nakad.co/</a> (<b>"website"</b>), is owned
                    and operated by AlphaStar Technologies Private Limited (CIN
                    - U72900DL2021PTC389698), a private limited company
                    incorporated under the Companies Act, 2013, having its
                    registered office at Plot No. 27, 3rd Floor, Community
                    Centre, Naraina Vihar, Phase-I, DELHI, South West Delhi,
                    India, 110028 (“Company”). Where such expression shall,
                    unless repugnant to the context thereof, be deemed to
                    include its respective representatives, partners,
                    administrators, employees, officers, agents and their
                    successors and assignees.
                  </li>
                  <li>
                    For the purpose of these Terms of Use (<b>“Terms”</b>), wherever
                    the context so requires,
                    <ol type="i">
                      <li>
                        The term <b>‘You’ & ‘User’</b> shall mean any legal person or
                        entity accessing or using the services provided on this
                        Website, who is competent to enter into binding
                        contracts, as per the provisions of the Indian Contract
                        Act, 1872;
                      </li>
                      <li>
                        The terms <b>‘We’, ‘Us’ & ‘Our’</b> shall mean the Website
                        and/or the Company, as the context so requires.
                      </li>
                      <li>
                        The terms <b>‘Party’& ‘Parties’</b> shall respectively be used
                        to refer to the User and the Company individually and
                        collectively, as the context so requires.
                      </li>
                    </ol>
                  </li>
                  <li>
                    The headings of each section in this Policy are only for the
                    purpose of organizing the various provisions under this
                    Policy in an orderly manner, and shall not be used by either
                    Party to interpret the provisions contained herein in any
                    manner. Further, it is specifically agreed to by the Parties
                    that the headings shall have no legal or contractual value.
                  </li>
                  <li>
                    The use of Website by the User is solely governed by this
                    Policy as well as the Terms of Use of Website (<b>“Terms of
                    Use”</b>, available at <a href="https://www.nakad.co/" className="cursor_pointer" target="_blank" rel="noreferrer">https://www.nakad.co/</a>), and any
                    modifications or amendments made thereto by the Company from
                    time to time, at its sole discretion. Visiting the home page
                    of the Website and/or using any of the services provided on
                    the Website shall be deemed to signify the User’s
                    unequivocal acceptance of this Policy and the aforementioned
                    Terms, and the User expressly agrees to be bound by the
                    same. The User expressly agrees and acknowledges that the
                    Terms and Policy are co-terminus, and that expiry /
                    termination of either one will lead to the termination of
                    the other.
                  </li>
                  <li>
                    The User unequivocally agrees that this Policy and the
                    aforementioned Terms constitute a legally binding agreement
                    between the User and the Website, and that the User shall be
                    subject to the rules, guidelines, policies, terms, and
                    conditions applicable to any service that is provided by the
                    Website, and that the same shall be deemed to be
                    incorporated into the Terms, and shall be treated as part
                    and parcel of the same. The User acknowledges and agrees
                    that no signature or express act is required to make these
                    Terms and the Policy binding on the User, and that the
                    User’s act of visiting any part of the Website constitutes
                    the User’s full and final acceptance of the Policy and the
                    aforementioned Terms.
                  </li>
                  <li>
                    The Parties expressly agree that the Company retains the
                    sole and exclusive right to amend or modify the Policy and
                    the aforementioned Terms without any prior permission or
                    intimation to the User and the User expressly agrees that
                    any such amendments or modifications shall come into effect
                    immediately. The User has a duty to periodically check the
                    Policy and Terms, and stay updated on their policies.
                  </li>
                  <li>
                    If the User continues to use the Website following such a
                    change, the User will be deemed to have consented to any and
                    all amendments / modifications made to the Policy and Terms.
                    In so far as the User complies with the Policy and Terms,
                    he/she is granted a personal, non-exclusive,
                    non-transferable, revocable, limited privilege to enter,
                    access and use the Website.
                  </li>
                </ol>
              </section>
            </li>

            <li>
              <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">
                  COLLECTION OF PERSONAL AND OTHER INFORMATION
                </h4>
                <ol type="a">
                  <li>
                    The User expressly agrees and acknowledges that the
                    Company/Website collects and stores the User’s personal
                    information, which is provided by the User from time to
                    time, including but not limited to the User’s username,
                    password, email address, Registered address, Service
                    address, identification numbers, documents, Account details,
                    Financial information such as Audited Financials results,
                    GST returns, browsing history, etc., as well as any images
                    or other information uploaded/submitted by the User on the
                    Website. The User is aware that this information will be
                    used by the Company/Website to provide services and features
                    targeted at the User, that are most likely to meet the
                    User’s business finance needs, and also to customize and
                    improve the Website to make its users’ experiences safer and
                    easier. The Company on timely basis will evaluate the
                    personal information of the User and keep it safe, accurate
                    and up to date. If User identify any error in personal
                    information or need to make a change to that information,
                    then User can make it updated by contact the Company via
                    e-mail at  <a href="mailto:support@nakad.co" className="cursor_pointer" target="_blank" rel="noreferrer">support@nakad.co</a>.
                  </li>
                  <li>
                    If the User chooses to buy services from the Website, the
                    User consents to allowing the Website to collect information
                    about the User’s behaviour and trends.
                  </li>
                  <li>
                    If the User chooses to post messages, ads, promotion,
                    reviews, feedback anywhere on the Website, including but not
                    limited to message boards, chat rooms, testimonials or other
                    message areas, etc., the User is aware that any and all such
                    information provided or uploaded will be collected and
                    stored by the Company for the time as required under the
                    applicable laws and guidelines issued by Reserve Bank of
                    India from time to time, and that such retained information
                    may be used to resolve disputes, provide customer support,
                    troubleshoot problems, etc., and that such information, if
                    requested, may be provided to judicial or governmental
                    authorities of requisite jurisdiction, or otherwise used by
                    the Company or Website as permitted by applicable laws.
                  </li>
                  <li>
                    The User is aware that any and all information pertaining to
                    the User collected by the Website, whether or not directly
                    provided by the User to the Company or Website, including
                    but not limited to personal correspondence such as emails or
                    letters, feedback from other users or third parties
                    regarding the User’s activities or postings on the Website,
                    etc., may be collected and compiled by the Company or
                    Website into a file or folder specifically created for /
                    allotted to the User, and the User hereby expressly consents
                    to the same.
                  </li>
                  <li>
                    The User is aware that while he can browse some sections of
                    the Website without being a registered user, certain
                    activities (such as bill discounting etc.) require the User
                    to provide valid personal information to the Company or
                    Website for the purpose of registration.
                  </li>
                </ol>
              </section>
            </li>

            <li>
              <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">USER’S RESPONSIBILITIES</h4>
                {/* <ol type="a">
                  <li> */}
                   <p className="without_li_text">
                    It is the user’s responsibility to provide correct
                    information about its contact particulars. Company shall
                    send emails or call at the email addresses/ phone numbers
                    mentioned by a User at the time of registration. Therefore,
                    if a user has inadvertently registered a wrong email id or
                    phone number for correspondence, Company offers a facility
                    to cross check information submitted at time of registration
                    or even correct the errors later. Company will not be liable
                    for any incorrect information submitted by a user at any
                    point of time on <a href="https://www.nakad.co/" className="cursor_pointer" target="_blank" rel="noreferrer">https://www.nakad.co/</a>.
                    </p>
                  {/* </li>
                </ol> */}
              </section>
            </li>

            <li>
              <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">
                  DIVULGING/SHARING OF PERSONAL INFORMATION
                </h4>
                <ol type="a">
                  <li>
                    "NAKAD may disclose the personal and confidential
                    information of the customer to third parties, including
                    banks and non-banking financial companies (NBFCs), for the
                    purpose of conducting necessary due diligence and risk
                    assessment to facilitate the customer's desired facility,
                    such as bill discounting. Such disclosure shall be carried
                    out in accordance with applicable laws and regulations
                    governing the protection of personal information and
                    confidentiality. By availing the desired facility, the
                    customer acknowledges and consents to the sharing of their
                    personal and confidential information for the aforementioned
                    purposes. Further the Company may also disclose the User’s
                    personal information to law enforcement offices, third party
                    rights owners, or other third parties if it believes that
                    such disclosure is reasonably necessary to enforce the Terms
                    or Policy; respond to claims that an advertisement, posting
                    or other content violates the rights of a third party; or
                    protect the rights, property or personal safety of its
                    users, or the general public.
                  </li>
                  <li>
                    The User is further aware that the Company and its
                    affiliates may share/sell some or all of the User’s personal
                    information with other business entities should the Company
                    (or its assets) plan to merge with, or be acquired by such
                    business entity, or in the event of re-organization,
                    amalgamation, or restructuring of the Company’s business.
                    Such business entity or new entity will continue to be bound
                    by the Terms and Policy, as may be amended from time to
                    time.
                  </li>
                  <li>
                    The User must be aware that Company may provide some of the
                    personal information of the User to the third party
                    companies for their marketing of services or products. But
                    such third party companies cannot retain or use User’s
                    personal information than necessary to provide the product,
                    service, or information, unless User specifically grants
                    permission to Company for doing so.
                  </li>
                  <li>
                    The Company may disclose information if legally required to,
                    in pursuant to an order from a governmental entity or in
                    good faith. We will disclose the information bases to:
                    <ol type="i">
                      <li>
                        Comply with legal requirements or comply with legal
                        process;
                      </li>
                      <li>
                        protect our rights or property or our affiliated
                        companies;
                      </li>
                      <li>prevent a crime or protect national security; or</li>
                      <li>
                        protect the personal safety of users or the public.
                      </li>
                    </ol>
                  </li>
                </ol>
              </section>
            </li>

            <li>
              <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">USER’S CONSENT</h4>
                {/* <ol type="a">
                  <li> */}
                   <p className="without_li_text">
                    By using the Website or by providing information to the
                    Company through the Website or otherwise, the User consents
                    to the collection and use of the information disclosed by
                    the User in accordance with this Policy, including but not
                    limited to the User’s consent the Company sharing/divulging
                    the User’s information, as per the terms contained herein
                    above in clause 2 of the Policy. Further, it enables NAKAD
                    to
                    </p>
                    <ul style={{listStyleType:'disc'}}>
                      <li>Process applications, requests and transactions</li>
                      <li>
                        Facilitate the Lenders in performing the KYC & risk
                        assessment.
                      </li>
                      <li>
                        Maintain internal records as per regulatory guidelines.
                      </li>
                      <li>
                        Provide services to customers, including responding to
                        customer requests.
                      </li>
                      <li>Comply with all applicable laws and regulations.</li>
                      <li>
                        Recognize the customer when he conducts online
                        transactions.
                      </li>
                      <li>
                        Understand the needs and provide relevant product and
                        service offers.
                      </li>
                      <li>To send periodic mails</li>
                      <li>To improve our site and services</li>
                    </ul>
                  {/* </li>
                </ol> */}
              </section>
            </li>

            <li>
              <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">GRIEVANCE OFFICER</h4>
                {/* <ol type="a">
                  <li> */}
                   <p className="without_li_text">
                    In case User has any grievances in connection with the use
                    of the Website, a user may write to the grievance redressal
                    officer. The grievance redressal officer is authorized to
                    attend to any user complaints or queries and resolve any
                    matter within 15 days from date of receipt of complaint.
                    </p>
                    <p className="without_li_text">
                      In accordance with Information Technology Act 2000 and
                      rules made there under, the name and contact details of
                      the Grievance Officer are provided below:
                    </p>
                    <p className="without_li_text">Mr. Rocky DMary </p>
                    <p className="without_li_text"> Email: <a href="mailto:rocky.dmary@nakad.co" className="cursor_pointer" target="_blank" rel="noreferrer">rocky.dmary@nakad.co</a> .</p>
                  {/* </li>
                </ol> */}
              </section>
            </li>

            <li>
              <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">CONFIDENTIAL INFORMATION</h4>
                <ol type="a">
                  <li>
                    “Confidential Information” shall mean any and all
                    information relating to the business and affairs of the
                    disclosing Party which is disclosed to us in writing
                    electronically, or in a written or other tangible form which
                    is identified/ marked to be confidential or proprietary; and
                    shall include such information to which the Receiving Party
                    may be privy to in the course of performance of the its
                    obligations including but not limited to sensitive personal
                    data/information of the personnel, technical information,
                    formulae, specifications, methods, know-how, computer
                    programs, processes, inventions, discoveries, designs,
                    devices, knowledge, creations, formulations, recipes,
                    methods, techniques, data, rights, devices, drawings,
                    instructions, expertise, trade practices, trade secrets,
                    commercial information, machines, research or development
                    projects, strategic business plans, plans for future
                    development, marketing concepts, credit/business policies,
                    procedures, API, source codes, content, Platform’s portal or
                    any other information relating to the design, manufacture,
                    application, inspection, testing, maintenance, packaging,
                    sale and trade secrets, whether registered as Intellectual
                    Property, and whether capable of such registration or not,
                    and including the Intellectual Property owned by Disclosing
                    Party and all and every kind of information that Disclosing
                    Party may specify (prior to its disclosure) as confidential
                    in relation to this Agreement, from time to time. It is
                    clarified that Customer Data shall not constitute
                    Confidential Information.
                  </li>
                </ol>
              </section>
            </li>

            <li>
              <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">FORCE MAJEURE EVENT</h4>
                {/* <ol type="a">
                  <li> */}
                  <p className="without_li_text">
                    “Force Majeure Event” means any event which is beyond our
                    reasonable control, including without limitation, acts of
                    god or elements, fire, wars, outbreak of pandemics or
                    epidemics, sabotage, civil unrest, labour unrest, Computer
                    hacking, breach of Security and Encryption, action of
                    statutory authorities or local or central governments
                    including government-imposed lockdowns, shutdown of business
                    for any reason beyond our Control.
                    </p>
                  {/* </li>
                </ol> */}
              </section>
            </li>

            <li>
              <section className="privacy_sub_header_section">
                <h4 className="privacy_sub_header">
                  DISPUTE RESOLUTION AND JURISDICTION
                </h4>
                {/* <ol type="a">
                  <li> */}
                   <p className="without_li_text">
                    It is expressly agreed to by the Parties hereto that the
                    formation, interpretation and performance of this Policy and
                    any disputes arising here from will be resolved through a
                    two-step Alternate Dispute Resolution (<b>“ADR”</b>) mechanism. It
                    is further agreed to by the Parties that the contents of
                    this Section shall survive even after the termination or
                    expiry of the Policy and/or Terms.
                    </p>
                    <ol type="a">
                      <li>
                        <b>Mediation:</b> In case of any dispute between the parties,
                        the Parties will attempt to resolve the same amicably
                        amongst themselves, to the mutual satisfaction of both
                        Parties. In the event that the Parties are unable to
                        reach such an amicable solution within thirty (30) days
                        of one Party communicating the existence of a dispute to
                        the other Party, the dispute will be resolved by
                        arbitration, as detailed here in below;
                      </li>
                      <li>
                        <b>Arbitration:</b> In the event that the Parties are unable to
                        amicably resolve a dispute by mediation, said dispute
                        will be referred to arbitration by a sole arbitrator to
                        be appointed by the Company, and the award passed by
                        such sole arbitrator will be valid and binding on both
                        Parties. The Parties shall bear their own costs for the
                        proceedings, although the sole arbitrator may, in
                        his/her sole discretion, direct either Party to bear the
                        entire cost of the proceedings. The arbitration shall be
                        conducted in English, and the seat of Arbitration shall
                        be at New Delhi, India.
                      </li>
                    </ol>
                  {/* </li>
                </ol> */}
              </section>
            </li>
          </ol>

          </div>
        </div>
        
        </div>

      
      </div>
    </div>
  );
};

export default PrivacyPolicy;
