import React from "react";
import "./Life_5.scss";

import Carousel from "react-multi-carousel";

import image from "../../assetsSvg/Life/Mind/image-(55).svg";

// import Abhinav from "../../assetsPng/Life/Mind/abhinav_bansal.png";
// import Abhinav from "../../assetsPng/Life/Mind/abhinav_bansal(1).png";
import Abhinav from "../../assetsPng/Life/Mind/abhinav_bansal_o.png";
import Aniket from "../../assetsPng/Life/Mind/Aniket_Deb.png";
// import Ankit from "../../assetsPng/Life/Mind/Ankit_Tomar.png";
import Ankit from "../../assetsPng/Life/Mind/Ankit_Tomar (3).png";
// import Amit from "../../assetsPng/Life/Mind/Amit_Karna.png";
import Amit from "../../assetsPng/Life/Mind/Amit_Karna (1).png";
import Kunal from "../../assetsPng/Life/Mind/Kunal_Upadhyay.png";
import Nitin from "../../assetsPng/Life/Mind/NitinGupta-new.png";
import Pranay from "../../assetsPng/Life/Mind/Pranay_Mehrotra.png";
import Raghunandan from "../../assetsPng/Life/Mind/Raghunandan_G.png";
import Rajeev from "../../assetsPng/Life/Mind/Rajeev_Kapoor.png";
import Rangarajan from "../../assetsPng/Life/Mind/Rangarajan_Krishnan.png";
import Sachin from "../../assetsPng/Life/Mind/Sachin_Agrawal.png";
import Sharad from "../../assetsPng/Life/Mind/Sharad_Verma.png";
// import Sandeep from "../../assetsPng/Life/Mind/Sandeep-Bhalla.png";
// import Sandeep from "../../assetsPng/Life/Mind/sandeep.png";
import Sandeep from "../../assetsPng/Life/Mind/sandeep (3).png";
// import Arvind from "../../assetsPng/Life/Mind/arvind.png";
import Arvind from "../../assetsPng/Life/Mind/arvind new (2).png";
import Amrit from "../../assetsPng/Life/Mind/Amrit.png";
import Rahul from "../../assetsPng/Life/Mind/Rahul (1).png";

// import Abhinav from "../../assetsPng/Life/Mind/abhinav_bansal_o up.png";
// import Aniket from "../../assetsPng/Life/Mind/Aniket_Deb up.png";
// import Ankit from "../../assetsPng/Life/Mind/Ankit_Tomar (3) up.png";
// import Amit from "../../assetsPng/Life/Mind/Amit_Karna (1) up.png";
// import Kunal from "../../assetsPng/Life/Mind/Kunal_Upadhyay up.png";
// import Nitin from "../../assetsPng/Life/Mind/NitinGupta-new up.png";
// import Pranay from "../../assetsPng/Life/Mind/Pranay_Mehrotra up.png";
// import Raghunandan from "../../assetsPng/Life/Mind/Raghunandan_G up.png";
// import Rajeev from "../../assetsPng/Life/Mind/Rajeev_Kapoor up.png";
// import Rangarajan from "../../assetsPng/Life/Mind/Rangarajan_Krishnan up.png";
// import Sachin from "../../assetsPng/Life/Mind/Sachin_Agrawal up.png";
// import Sharad from "../../assetsPng/Life/Mind/Sharad_Verma up.png";
// import Sandeep from "../../assetsPng/Life/Mind/sandeep (3) up.png";
// import Arvind from "../../assetsPng/Life/Mind/arvind new (2) up.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1440 },
    items: 4,
  },
  smalldesktop: {
    breakpoint: { max: 1439, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 480 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 1,
  },
};

const Life_5 = () => {
  return (
    <div className="Life_5_container">
      <div>
        <title className="heading">
          Our<span className="color_text">&nbsp;Advisors</span>
        </title>
      </div>

      <div className="advisors_container">
        {/* <div>
          <div className="card1">
            <div className="top">
              <img src={image} alt="" />
              <div className="box"></div>
            </div>
            <div className="bottom1">
              <h5 className="name">Rishabh Jain</h5>
              <p className="text">
                Co-founder & CEO, <br />
                Labour Law Advisor
              </p>
            </div>
          </div>
        </div> */}
        <div>
          <Carousel
            responsive={responsive}
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={300000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "8px" }}>
                  <img
                    src={Abhinav}
                    alt=""
                    className="abhinav"
                    style={{ height: "265px", width: "260px" }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">Abhinav Bansal</h5>
                  <p className="text">
                    MD & Partner, <br />
                    BCG
                  </p>
                </div>
              </div>
            </div>
            {/* <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "33px" }}>
                  <img
                    src={Amit}
                    alt=""
                    className="Amit"
                    style={{
                      height: "240px",
                      width: "280px",
                      marginLeft: "-10px",
                    }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1" style={{ marginTop: "-27px" }}>
                  <h5 className="name">Amit Karna</h5>
                  <p className="text">
                    Professor, <br />
                    IIM Ahmedabad
                  </p>
                </div>
              </div>
            </div> */}
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "33px" }}>
                  <img
                    src={Amrit}
                    alt=""
                    style={{ height: "240px", width: "270px" }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1" style={{ marginTop: "-27px" }}>
                  <h5 className="name">Amrit Acharya</h5>
                  <p className="text">
                    Co-founder & CEO, <br />
                    Zetwerk
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "13px" }}>
                  <img
                    src={Aniket}
                    alt=""
                    style={{ height: "260px", width: "265px" }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1" style={{ marginTop: "-7px" }}>
                  <h5 className="name">Aniket Deb</h5>
                  <p className="text">
                    Co-founder & COO, <br />
                    Bizongo
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "34px" }}>
                  <img
                    src={Ankit}
                    alt=""
                    className="ankit"
                    style={{ height: "240px", width: "265px" }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1" style={{ marginTop: "-30px" }}>
                  <h5 className="name">Ankit Tomar</h5>
                  <p className="text">
                    Co-founder & CTPO, <br />
                    Bizongo
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "34px" }}>
                  <img
                    src={Arvind}
                    alt=""
                    style={{ height: "240px", width: "265px" }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1" style={{ marginTop: "-30px" }}>
                  <h5 className="name"> CA Arvind Kalra</h5>
                  <p className="text">
                    ex-Director, <br />
                    MMTC Ltd.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "3px" }}>
                  <img src={Kunal} alt="" />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">Kunal Upadhyay</h5>
                  <p className="text">
                    CEO, CIIE <br />
                    IIM Ahmedabad
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "2px" }}>
                  <img
                    src={Nitin}
                    alt=""
                    style={{
                      height: "270px",
                      width: "258px",
                      marginLeft: "7px",
                    }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">Nitin Gupta</h5>
                  <p className="text">
                    Founder & CEO, <br />
                    Uni
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "-3px" }}>
                  <img
                    src={Pranay}
                    alt=""
                    style={{ height: "275px", width: "265px" }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1" style={{ marginTop: "5px" }}>
                  <h5 className="name">Pranay Mehrotra</h5>
                  <p className="text">
                    MD & Senior Partner, <br />
                    BCG
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "2px" }}>
                  <img
                    src={Raghunandan}
                    alt=""
                    style={{
                      height: "270px",
                      width: "250px",
                      marginLeft: "14px",
                    }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">Raghunandan G</h5>
                  <p className="text">
                    Founder, <br />
                    Zolve
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "32px" }}>
                  <img
                    src={Rahul}
                    alt=""
                    style={{ height: "240px", width: "260px" }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1" style={{ marginTop: "-31px" }}>
                  <h5 className="name">Rahul Sharma</h5>
                  <p className="text">
                    Co-founder,
                    <br />
                    Zetwerk
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "1px" }}>
                  <img src={Rajeev} alt="" />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">Rajeev Kapoor</h5>
                  <p className="text">
                    ex-CEO, <br />
                    Fiat India
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "-19px" }}>
                  <img
                    src={Rangarajan}
                    alt=""
                    style={{
                      height: "290px",
                      width: "284px",
                      marginLeft: "-14px",
                    }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1" style={{ marginTop: "20px" }}>
                  <h5 className="name">Rangarajan Krishnan</h5>
                  <p className="text">
                    CEO, <br />
                    Five Star Business Finance
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top">
                  <img src={Sachin} alt="" />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">Sachin Agrawal</h5>
                  <p className="text">
                    Co-founder & CEO, <br />
                    Bizongo
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top">
                  <img
                    src={Sandeep}
                    alt=""
                    className="Sandeep"
                    style={{ marginLeft: "8px" }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1">
                  <h5 className="name">Sandeep Bhalla</h5>
                  <p className="text">
                    ex-MD of APAC and EMEA, <br />
                    Citi
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card1">
                <div className="top" style={{ marginTop: "30px" }}>
                  <img
                    src={Sharad}
                    alt=""
                    style={{ height: "240px", width: "240px" }}
                  />
                  <div className="box"></div>
                </div>
                <div className="bottom1" style={{ marginTop: "-30px" }}>
                  <h5 className="name">Sharad Verma</h5>
                  <p className="text">
                    MD & Senior Partner, <br />
                    BCG
                  </p>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      <div className="advisors_container_mobile">
        {/* <div>
          <div className="card1">
            <div className="top">
              <img src={image} alt="" />
              <div className="box"></div>
            </div>
            <div className="bottom1">
              <h5 className="name">Rishabh Jain</h5>
              <p className="text">
                Co-founder & CEO, <br />
                Labour Law Advisor
              </p>
            </div>
          </div>
        </div> */}

        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={300000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <div>
            <div className="card1">
              <div className="top">
                <img
                  src={Abhinav}
                  alt=""
                  style={{
                    width: "202.8px",
                    height: "187.2px",
                    marginLeft: "25px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "20px" }}>
                <h5 className="name">Abhinav Bansal</h5>
                <p className="text">
                  MD & Partner, <br />
                  BCG
                </p>
              </div>
            </div>
            {/* <div className="card1">
              <div className="top" style={{ marginTop: "27px" }}>
                <img
                  src={Amit}
                  alt=""
                  style={{
                    width: "202px",
                    height: "156.4px",
                    marginLeft: "17px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "-6px" }}>
                <h5 className="name">Amit Karna</h5>
                <p className="text">
                  Professor, <br />
                  IIM Ahmedabad
                </p>
              </div>
            </div> */}
             <div className="card1">
              <div className="top" style={{ marginTop: "34px" }}>
                <img
                  src={Amrit}
                  alt=""
                  style={{
                    width: "175.24px",
                    height: "151.76px",
                    marginLeft: "33px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "-12px" }}>
                <h5 className="name">Amrit Acharya</h5>
                <p className="text">
                  Co-founder & CEO, <br />
                  Zetwerk
                </p>
              </div>
            </div>
          </div>
          <div>
            {/* new */}
            {/* <div className="card1">
              <div className="top" style={{ marginTop: "34px" }}>
                <img
                  src={Amrit}
                  alt=""
                  style={{
                    width: "175.24px",
                    height: "151.76px",
                    marginLeft: "33px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "-12px" }}>
                <h5 className="name">Amrit Acharya</h5>
                <p className="text">
                  Co-founder & CEO, <br />
                  Zetwerk
                </p>
              </div>
            </div> */}
             <div className="card1">
              <div className="top" style={{ marginTop: "23px" }}>
                <img
                  src={Sandeep}
                  alt=""
                  style={{
                    width: "192px",
                    height: "165px",
                    marginLeft: "30px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1">
                <h5 className="name">Sandeep Bhalla</h5>
                <p className="text">
                  ex-MD of APAC and EMEA, <br />
                  Citi
                </p>
              </div>
            </div>

            <div className="card1">
              <div className="top" style={{ marginTop: "-28px" }}>
                <img
                  src={Aniket}
                  alt=""
                  style={{
                    width: "202.4px",
                    height: "179.4px",
                    marginLeft: "18px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "18px" }}>
                <h5 className="name">Aniket Deb</h5>
                <p className="text">
                  Co-founder & COO, <br />
                  Bizongo
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="top" style={{ marginTop: "0px" }}>
                <img
                  src={Ankit}
                  alt=""
                  style={{ width: "208px", height: "187.2px" }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "22px" }}>
                <h5 className="name">Ankit Tomar</h5>
                <p className="text">
                  Co-founder & CTPO, <br />
                  Bizongo
                </p>
              </div>
            </div>
            <div className="card1">
              <div className="top" style={{ marginTop: "40px" }}>
                <img
                  src={Arvind}
                  alt=""
                  style={{
                    width: "191.8px",
                    height: "147.2px",
                    marginLeft: "25px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "-14px" }}>
                <h5 className="name">CA Arvind Kalra</h5>
                <p className="text">
                  ex-Director, <br />
                  MMTC Ltd.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="top" style={{ marginTop: "0px" }}>
                <img
                  src={Kunal}
                  alt=""
                  style={{
                    width: "202.8px",
                    height: "187.2px",
                    marginLeft: "26px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "24px" }}>
                <h5 className="name">Kunal Upadhyay</h5>
                <p className="text">
                  CEO, CIIE <br />
                  IIM Ahmedabad
                </p>
              </div>
            </div>
            <div className="card1">
              <div className="top" style={{ marginTop: "18px" }}>
                <img
                  src={Nitin}
                  alt=""
                  style={{
                    width: "165.2px",
                    height: "169px",
                    marginLeft: "50px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "8px" }}>
                <h5 className="name">Nitin Gupta</h5>
                <p className="text">
                  Founder & CEO, <br />
                  Uni
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="top" style={{ marginTop: "16px" }}>
                <img
                  src={Pranay}
                  alt=""
                  style={{
                    width: "190px",
                    height: "170px",
                    marginLeft: "26px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "10px" }}>
                <h5 className="name">Pranay Mehrotra</h5>
                <p className="text">
                  MD & Senior Partner, <br />
                  BCG
                </p>
              </div>
            </div>
            <div className="card1">
              <div className="top" style={{ marginTop: "-16px" }}>
                <img
                  src={Raghunandan}
                  alt=""
                  style={{
                    width: "202.8px",
                    height: "187.2px",
                    marginLeft: "26px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "24px" }}>
                <h5 className="name">Raghunandan G</h5>
                <p className="text">
                  Founder, <br />
                  Zolve
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="top" style={{ marginTop: "36px" }}>
                <img
                  src={Rahul}
                  alt=""
                  style={{
                    width: "179px",
                    height: "149.4px",
                    marginLeft: "32px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "-12px" }}>
                <h5 className="name">Rahul Sharma</h5>
                <p className="text">
                  Co-founder, <br />
                  Zetwerk
                </p>
              </div>
            </div>
            <div className="card1">
              <div className="top" style={{ marginTop: "-32px" }}>
                <img
                  src={Rajeev}
                  alt=""
                  style={{
                    width: "190px",
                    height: "185px",
                    marginLeft: "26px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "20px" }}>
                <h5 className="name">Rajeev Kapoor</h5>
                <p className="text">
                  ex-CEO, <br />
                  Fiat India
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="card1">
              <div className="top" style={{ marginTop: "-10px" }}>
                <img
                  src={Rangarajan}
                  alt=""
                  style={{ width: "234px", height: "197.6px" }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "32px" }}>
                <h5 className="name">Rangarajan Krishnan</h5>
                <p className="text">
                  CEO, <br />
                  Five Star Business Finance
                </p>
              </div>
            </div>
            <div className="card1">
              <div className="top" style={{ marginTop: "28px" }}>
                <img
                  src={Sachin}
                  alt=""
                  style={{
                    width: "189.52px",
                    height: "171.68px",
                    marginLeft: "21px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "6px" }}>
                <h5 className="name">Sachin Agrawal</h5>
                <p className="text">
                  Co-founder & CEO, <br />
                  Bizongo
                </p>
              </div>
            </div>
          </div>
          <div>
            {/* <div className="card1">
              <div className="top" style={{ marginTop: "23px" }}>
                <img
                  src={Sandeep}
                  alt=""
                  style={{
                    width: "192px",
                    height: "165px",
                    marginLeft: "30px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1">
                <h5 className="name">Sandeep Bhalla</h5>
                <p className="text">
                  ex-MD of APAC and EMEA, <br />
                  Citi
                </p>
              </div>
            </div> */}
            <div className="card1">
              <div className="top" 
              // style={{ marginTop: "-11px" }}
              >
                <img
                  src={Sharad}
                  alt=""
                  style={{
                    width: "192.4px",
                    height: "176.8px",
                    marginLeft: "26px",
                  }}
                />
                <div className="box"></div>
              </div>
              <div className="bottom1" style={{ marginTop: "14px" }}>
                <h5 className="name">Sharad Verma</h5>
                <p className="text">
                  MD & Senior Partner, <br />
                  BCG
                </p>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Life_5;
