import React, { useEffect, useRef, useState } from "react";
import {Helmet} from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import "./Home_1.scss";
import '../Common/Common.scss'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Group1 from "../../assetsSvg/Home/Left/Group1.svg";
import Group2 from "../../assetsSvg/Home/Left/Group2.svg";
import Group3 from "../../assetsSvg/Home/Left/Group3.svg";
import Group4 from "../../assetsSvg/Home/Left/Group4.svg";
import Group5 from "../../assetsSvg/Home/Left/Group5.svg";

// import Tier2 from "../../assetsSvg/Home/Right/Tier2.svg";
// import Tier1 from "../../assetsSvg/Home/Right/Tier1.svg";
// import Anchor from "../../assetsSvg/Home/Right/Anchor.svg";
// import Lender from "../../assetsSvg/Home/Right/Lender.svg";

import Tier2 from "../../assetsPng/Home/Right/Tier2.png";
import Tier1 from "../../assetsPng/Home/Right/Tier1.png";
import Anchor from "../../assetsPng/Home/Right/Anchor.png";
import Lender from "../../assetsPng/Home/Right/Lender.png";

import Truck from "../../assetsSvg/Home/Right/Truck gif.gif";

import Truck1 from "../../assetsSvg/Home/Right/Truck1.gif";
import Truck2 from "../../assetsSvg/Home/Right/Truck2.gif";

import PathL2R from "../../assetsSvg/pathL2R.svg";
import PathR2L from "../../assetsSvg/pathR2L.svg";
import TruckL2R from "../../assetsSvg/Truck right.svg";
import TruckR2L from "../../assetsSvg/Truck left.svg";

import TruckD from "../../assetsSvg/R2L truck.svg";

import startD from "../../assetsSvg/coin upwards.svg";
import coinl2r from "../../assetsSvg/coin l2r.svg";
import coin1R2L from "../../assetsSvg/coindemo1.svg";

import Star2upward from "../../assetsSvg/star2upward.svg";

import Arrow1 from "../../assetsSvg/Home/Right/Arrow1.svg";
import Arrow2 from "../../assetsSvg/Home/Right/Arrow2.svg";
import Arrow3 from "../../assetsSvg/Home/Right/Arrow3.svg";

import Star1 from "../../assetsSvg/Home/Right/star1.gif";
import Star2 from "../../assetsSvg/Home/Right/star2.gif";
import Star3 from "../../assetsSvg/Home/Right/star3.gif";

// vcfirm
import Rocket from "../../assetsSvg/vcfirm/Saly-43.svg";
import media from "../../assetsSvg/vcfirm/media.svg";

//footer
import footerImg1 from "../../assetsSvg/footer/Saly-10.svg";
import footerImg from "../../assetsSvg/footer/Saly-17.svg";

import insta from "../../assetsSvg/footer/insta.svg";
import browser from "../../assetsSvg/footer/browser.svg";
import twitter from "../../assetsSvg/footer/twitter.svg";
import youtube from "../../assetsSvg/footer/youtube.svg";

import Accel from "../../assetsPng/Home/Accel-1.png";
import Advant from "../../assetsPng/Home/Advant-1.png";
import matrixPartners from "../../assetsPng/Home/matrix-2.png";

import ET from "../../assetsPng/Home/ET.webp";
import vcCircle from "../../assetsPng/Home/vcCircle.jpeg";

import ETlogo from "../../assetsPng/Home/ETlogo.png";
import TechAsialogo from "../../assetsPng/Home/TechAsialogo.png";
import Inc42logo from "../../assetsPng/Home/inclogo.png";
import vcCirclelogo from "../../assetsPng/Home/VCcircle logo.png";

import linkedin from "../../assetsSvg/Life/Mind/linkedin.svg";

// import scrollGif from "../../assetsSvg/Home/Left/scroll-down-mouse.gif";
import scrollGif from "../../assetsSvg/Home/Left/scroll-gif.gif";

import { gsap } from "gsap";
import Footer from "../Footer/Footer";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

const sections = [
  {
    title: "Title1",
  },
  {
    title: "Title2",
  },
  {
    title: "Title3",
  },
  {
    title: "Title4",
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Home_1 = ({ setUsername }) => {
  // const [showMedia, setShowMedia] = useState(false)
  let visibleText = 0;
  let animationState = "";
  // let showMedia = false;

  const setAnimationState = (str) => {
    animationState = str;
  };

  const setVisibleText = (num) => {
    visibleText = num;
    setUsername(visibleText);
    console.info("visible", visibleText);
  };

  // const setShowMedia = (res) => {
  //   showMedia = res
  //   console.info("res, visibleText", res, visibleText);
  // }

  const sectionRef = useRef(null);

  const revealRefs = useRef([]);

  const myRef = useRef();

  revealRefs.current = [];

  useEffect(() => {
    console.log("called");
    window.addEventListener("touchstart", handleTouchStart, false);
    window.addEventListener("touchmove", handleTouchMove, false);

    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
      return (
        evt.touches || // browser API
        evt.originalEvent.touches
      ); // jQuery
    }

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          /* right swipe */
        } else {
          /* left swipe */
        }
      } else {
        if (yDiff > 0) {
          /* down swipe */
          scrollDownController();
        } else {
          /* up swipe */
          scrollUpController();
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    }

    const down1 = gsap
      .timeline({
        paused: true,
        defaults: { duration: 0.5 },

        onComplete: function () {
          setVisibleText(visibleText + 1); // +
          setAnimationState("");
        },
        onReverseComplete: function () {
          setVisibleText(visibleText - 1); // -
          console.log("down1--");
          setAnimationState("");
        },
      })
      .to("#divtest1", { opacity: 0 , y:-100, },);

    down1.fromTo("#divtest2", {opacity: 0, y: "100px"}, {opacity: 1, y: "0px"}, );

    const down2 = gsap
      .timeline({
        paused: true,
        defaults: { duration: 0.5 },
        //onStart: setAnimationState("animating"),
        onComplete: function () {
          // const eleL2R = document.getElementById("truckL2R");
          // eleL2R.classList.remove("truck1");
          // eleL2R.classList.add("truck1_opacity_less");

          // const eleR2L = document.getElementById("truckR2L");
          // eleR2L.classList.remove("truck2");
          // eleR2L.classList.add("truck2_opacity_less");
          setVisibleText(visibleText + 1); // +
          setAnimationState("");
        },
        onReverseComplete: function () {
          // const eleL2R = document.getElementById("truckL2R");
          // eleL2R.classList.remove("truck1_opacity_less");
          // eleL2R.classList.add("truck1");

          // const eleR2L = document.getElementById("truckR2L");
          // eleR2L.classList.remove("truck2_opacity_less");
          // eleR2L.classList.add("truck2");

          setVisibleText(visibleText - 1); // -
          console.log("down2--");
          setAnimationState("");
        },
      })
      .to("#divtest2", { opacity: 0 , y:-100});
    down2.fromTo("#divtest3", {opacity: 0, y: "100px"}, {opacity: 1, y: "0px"}, );
    down2.to(".pathL2R, .pathR2L", { opacity: 0.4 }, 0);
    down2.to(
      " #img_2, #img_3, .tier1_text_box, .anchor_text_box",
      { opacity: 0.4 },
      0
    );

    const down3 = gsap
      .timeline({
        paused: true,
        defaults: { duration: 0.5 },
        //onStart: setAnimationState("animating"),
        onComplete: function () {
          // const eleL2R = document.getElementById("truckL2R");
          // eleL2R.classList.remove("truck1_opacity_less");
          // eleL2R.classList.add("truck1_opacity_less");

          // const eleR2L = document.getElementById("truckR2L");
          // eleR2L.classList.remove("truck2_opacity_less");
          // eleR2L.classList.add("truck2_opacity_less");
          setVisibleText(visibleText + 1); // +
          setAnimationState("");
        },
        onReverseComplete: function () {
          // const eleL2R = document.getElementById("truckL2R");
          // eleL2R.classList.remove("truck1_opacity_less");
          // eleL2R.classList.add("truck1_opacity_less");

          // const eleR2L = document.getElementById("truckR2L");
          // eleR2L.classList.remove("truck2_opacity_less");
          // eleR2L.classList.add("truck2_opacity_less");
          setVisibleText(visibleText - 1); // -
          console.log("down3--");
          setAnimationState("");
        },
      })
      .to("#divtest3", { opacity: 0 , y:-100});
    down3.fromTo("#divtest4", {opacity: 0, y: "100px"}, {opacity: 1, y: "0px"}, );
    down3.to(
      "#img_1, #img_3, .tier2_text_box, .anchor_text_box, .pathL2R, .pathR2L",
      { opacity: 0.4 },
      0
    );
    down3.to("#img_2, .tier1_text_box", { opacity: 1 }, 0);

    const down4 = gsap
      .timeline({
        paused: true,
        defaults: { duration: 0.5 },
        // onStart: setAnimationState("animating"),
        onComplete: function () {
          // const eleL2R = document.getElementById("truckL2R");
          // eleL2R.classList.remove("truck1_opacity_less");
          // eleL2R.classList.add("truck1_opacity_less");

          // const eleR2L = document.getElementById("truckR2L");
          // eleR2L.classList.remove("truck2_opacity_less");
          // eleR2L.classList.add("truck2_opacity_less");
          setVisibleText(visibleText + 1); // +
          setAnimationState("");
        },
        onReverseComplete: function () {
          // const eleL2R = document.getElementById("truckL2R");
          // eleL2R.classList.remove("truck1_opacity_less");
          // eleL2R.classList.add("truck1_opacity_less");

          // const eleR2L = document.getElementById("truckR2L");
          // eleR2L.classList.remove("truck2_opacity_less");
          // eleR2L.classList.add("truck2_opacity_less");
          setVisibleText(visibleText - 1); // -
          setAnimationState("");
        },
      })
      .to("#divtest4", { opacity: 0 , y:-100});
    down4.fromTo("#divtest5", {opacity: 0, y: "100px"}, {opacity: 1, y: "0px"}, );
    down4.to(
      "#img_2, #img_1, .tier1_text_box, .tier2_text_box, .pathL2R, .pathR2L ",
      { opacity: 0.4 },
      0
    );
    down4.to("#img_3, .anchor_text_box", { opacity: 1 }, 0);

    const down5 = gsap
      .timeline({
        paused: true,
        defaults: { duration: 0.5 },
        // onStart: setAnimationState("animating"),
        onComplete: function () {
          // const eleL2R = document.getElementById("truckL2R");
          // eleL2R.classList.remove("truck1_opacity_less");
          // eleL2R.classList.add("truck1_stop");

          // const eleR2L = document.getElementById("truckR2L");
          // eleR2L.classList.remove("truck2_opacity_less");
          // eleR2L.classList.add("truck2_stop");
          //  ele.style.opacity = 0;
          // console.log("ele", eleL2R);

          // const eleUpward = document.getElementById("star2upward");
          // eleUpward.classList.remove("coin_upward");
          // eleUpward.classList.add("coin_upward_visible");

          // const eleSmall_coin2 = document.getElementById("star1Small_coin2");
          // eleSmall_coin2.classList.remove("Small_coin2");
          // eleSmall_coin2.classList.add("Small_coin2_visible");

          // const elesmall_coin1 = document.getElementById("star1small_coin1");
          // elesmall_coin1.classList.remove("small_coin1");
          // elesmall_coin1.classList.add("small_coin1_visible");

          // const eleDownward = document.getElementById("star3downward");
          // eleDownward.classList.remove("coin_downward");
          // eleDownward.classList.add("coin_downward_visible");
          setVisibleText(visibleText + 1); // +
          setAnimationState("");
        },
        onReverseComplete: function () {
          // const eleL2R = document.getElementById("truckL2R");
          // eleL2R.classList.remove("truck1_stop");
          // eleL2R.classList.add("truck1_opacity_less");

          // const eleR2L = document.getElementById("truckR2L");
          // eleR2L.classList.remove("truck2_stop");
          // eleR2L.classList.add("truck2_opacity_less");

          // const eleUpward = document.getElementById("star2upward");
          // eleUpward.classList.remove("coin_upward_visible");
          // eleUpward.classList.add("coin_upward");

          // const eleSmall_coin2 = document.getElementById("star1Small_coin2");
          // eleSmall_coin2.classList.remove("Small_coin2_visible");
          // eleSmall_coin2.classList.add("Small_coin2");

          // const elesmall_coin1 = document.getElementById("star1small_coin1");
          // elesmall_coin1.classList.remove("small_coin1_visible");
          // elesmall_coin1.classList.add("small_coin1");

          // const eleDownward = document.getElementById("star3downward");
          // eleDownward.classList.remove("coin_downward_visible");
          // eleDownward.classList.add("coin_downward");

          setVisibleText(visibleText - 1); // -
          setAnimationState("");
        },
      })
      .to("#divtest5", { opacity: 0 , y:-100});
    down5.fromTo("#divtest6", {opacity: 0, y: "100px"}, {opacity: 1, y: "0px"}, );
    down5.to(".arrow1, .arrow2, .arrow3", { opacity: 0.4 }, 0);
    down5.to(
      "#img_1, #img_2, #img_3, #img_4, .tier2_text_box, .tier1_text_box, .anchor_text_box, .lender_text_box, .pathR2L, .pathL2R",
      { opacity: 1 },
      0
    );
    // down5.to(document.getElementsByClassName('truck1').classList.toggle('truck1_1'))
    const down6 = gsap
      .timeline({
        paused: true,
        defaults: { duration: 0.5 },
        // onStart: setAnimationState("animating"),
        onComplete: function () {
          // const eleUpward = document.getElementById("star2upward");
          // eleUpward.classList.remove("coin_upward_visible");
          // eleUpward.classList.add("coin_upward");

          // const eleSmall_coin2 = document.getElementById("star1Small_coin2");
          // eleSmall_coin2.classList.remove("Small_coin2_visible");
          // eleSmall_coin2.classList.add("Small_coin2");

          // const elesmall_coin1 = document.getElementById("star1small_coin1");
          // elesmall_coin1.classList.remove("small_coin1_visible");
          // elesmall_coin1.classList.add("small_coin1");

          // const eleDownward = document.getElementById("star3downward");
          // eleDownward.classList.remove("coin_downward_visible");
          // eleDownward.classList.add("coin_downward");
          setVisibleText(visibleText + 1); // +
          setAnimationState("");
        },
        onReverseComplete: function () {
          const eleUpward = document.getElementById("star2upward");
          eleUpward.classList.remove("coin_upward");
          eleUpward.classList.add("coin_upward_visible");

          const eleSmall_coin2 = document.getElementById("star1Small_coin2");
          eleSmall_coin2.classList.remove("Small_coin2");
          eleSmall_coin2.classList.add("Small_coin2_visible");

          const elesmall_coin1 = document.getElementById("star1small_coin1");
          elesmall_coin1.classList.remove("small_coin1");
          elesmall_coin1.classList.add("small_coin1_visible");

          const eleDownward = document.getElementById("star3downward");
          eleDownward.classList.remove("coin_downward");
          eleDownward.classList.add("coin_downward_visible");

          setVisibleText(visibleText - 1); // -
          setAnimationState("");
        },
      })
      .to(
        ".left, .right, .tier2_text_box, .tier1_text_box, .anchor_text_box, .lender_text_box, .truck1, .pathL2R, .truck2, .pathR2L, .arrow1, .arrow2, .arrow3",
        { opacity: 0 },
        0
      );
    down6.to(".ecosystem, .ecosystem_mobile", { opacity: 1 }, 0);
    // .set("#sectiontest7", { opacity: 1 });
    // down6.to("#img_1, #img_2, #img_3, #img_4", { opacity: 0 }, 0);

    // down6.to(".ecosystem", { opacity: 1 }, 0.3);
    // .to("#sectiontest9", { opacity: 0 })

    // const down7 = gsap
    //   .timeline({
    //     paused: true,
    //     defaults: { duration: 0.5 },
    //     // onStart: setAnimationState("animating"),
    //     onComplete: function () {
    //       setVisibleText(visibleText + 1); // +
    //       setShowMedia(true);
    //       setAnimationState("");
    //     },
    //     onReverseComplete: function () {
    //       console.log("down7--");
    //       setVisibleText(visibleText - 1); // -
    //       setShowMedia(false);
    //       setAnimationState("");
    //     },
    //   })
    //   .to(".ecosystem, .ecosystem_mobile", { opacity: 0 },0);
    //   down7.to(".media_page_new", { opacity: 1 }, 0);

    const down7 = gsap
      .timeline({
        paused: true,
        defaults: { duration: 0.5 },
        // onStart: setAnimationState("animating"),
        onComplete: function () {
          // setShowMedia(false);
          setVisibleText(visibleText + 1); // +
          setAnimationState("");
        },
        onReverseComplete: function () {
          console.log("down7--");
          // setShowMedia(true);
          setVisibleText(visibleText - 1); // -
          setAnimationState("");
        },
      })
      .to("#sectiontest1, .scscrollGif_box ", { opacity: 0 });
    down7.to("#linkedin_home", { zIndex: 2 });
    down7.to("#footerBtn_home", { zIndex: 3 });
    // down7.to("#sectiontest9", {  zIndex:1 }, 0);

    document.addEventListener("keydown", function doFade(e) {
      switch (e.keyCode) {
        case 38:
          //  alert('up');
          console.log("insideuppress", visibleText);
          console.log("animationState", animationState);
          // if (visibleText === 1 && animationState !== "animating") {
          //   // setVisibleText(visibleText - 1);
          //   setAnimationState("animating");
          //   down1.reverse();
          // } else if (visibleText === 2 && animationState !== "animating") {
          //   // setVisibleText(visibleText - 1);
          //   setAnimationState("animating");
          //   down2.reverse();
          // } else if (visibleText === 3 && animationState !== "animating") {
          //   // setVisibleText(visibleText - 1);
          //   setAnimationState("animating");
          //   down3.reverse();
          // } else if (visibleText === 4 && animationState !== "animating") {
          //   // setVisibleText(visibleText - 1);
          //   setAnimationState("animating");
          //   down4.reverse();
          // } else if (visibleText === 5 && animationState !== "animating") {
          //   // setVisibleText(visibleText - 1);
          //   setAnimationState("animating");
          //   down5.reverse();
          // } else if (visibleText === 6 && animationState !== "animating") {

          //   setAnimationState("animating");
          //   down6.reverse();

          // } else if (visibleText === 7 && animationState !== "animating") {

          //   setAnimationState("animating");
          //   console.log("upKey7")
          //   down7.reverse();

          // }
          scrollUpController();
          break;

        case 40:
          // alert('down');
          console.log("insidedownpress", visibleText);
          console.log("animationState", animationState);
          // if (visibleText === 0 && animationState !== "animating") {
          //   setAnimationState("animating");
          //   down1.restart();
          //   console.log("down1", visibleText)
          // } else if (visibleText === 1 && animationState !== "animating") {
          //   // setVisibleText(visibleText + 1);
          //   setAnimationState("animating");
          //   down2.restart();
          //   console.log("down2", visibleText)
          // } else if (visibleText === 2 && animationState !== "animating") {
          //   // setVisibleText(visibleText + 1);
          //   setAnimationState("animating");
          //   down3.restart();
          //   console.log("down3", visibleText)
          // } else if (visibleText === 3 && animationState !== "animating") {
          //   // setVisibleText(visibleText + 1);
          //   setAnimationState("animating");
          //   down4.restart();
          //   console.log("down4", visibleText)
          // } else if (visibleText === 4 && animationState !== "animating") {
          //   // setVisibleText(visibleText + 1);
          //   setAnimationState("animating");
          //   down5.restart();
          //   console.log("down5", visibleText)
          // } else if (visibleText === 5 && animationState !== "animating") {
          //   // setVisibleText(visibleText + 1);
          //   setAnimationState("animating");
          //   down6.restart();
          //   console.log("down6", visibleText)
          // } else if (visibleText === 6 && animationState !== "animating") {
          //   // setVisibleText(visibleText + 1);
          //   setAnimationState("animating");
          //   down7.restart();
          //   console.log("down7", visibleText)
          // }
          scrollDownController();
          break;
      }
    });

    function scrollUpController() {
      if (visibleText === 1 && animationState !== "animating") {
        // setVisibleText(visibleText - 1);
        setAnimationState("animating");
        down1.reverse();

        const dot1 = document.getElementById("dot1");
        dot1.classList.remove("dot_white");
        dot1.classList.add("dot_color");

        const dot2 = document.getElementById("dot2");
        dot2.classList.remove("dot_color");
        dot2.classList.add("dot_white");
      } else if (visibleText === 2 && animationState !== "animating") {
        // setVisibleText(visibleText - 1);
        setAnimationState("animating");
        down2.reverse();
        const eleL2R = document.getElementById("truckL2R");
          eleL2R.classList.remove("truck1_opacity_less");
          eleL2R.classList.add("truck1");

          const eleR2L = document.getElementById("truckR2L");
          eleR2L.classList.remove("truck2_opacity_less");
          eleR2L.classList.add("truck2");

          const dot2 = document.getElementById("dot2");
          dot2.classList.remove("dot_white");
          dot2.classList.add("dot_color");

          const dot3 = document.getElementById("dot3");
          dot3.classList.remove("dot_color");
          dot3.classList.add("dot_white");
      } else if (visibleText === 3 && animationState !== "animating") {
        // setVisibleText(visibleText - 1);
        setAnimationState("animating");
        down3.reverse();
        const eleL2R = document.getElementById("truckL2R");
          eleL2R.classList.remove("truck1_opacity_less");
          eleL2R.classList.add("truck1_opacity_less");

          const eleR2L = document.getElementById("truckR2L");
          eleR2L.classList.remove("truck2_opacity_less");
          eleR2L.classList.add("truck2_opacity_less");

          const dot3 = document.getElementById("dot3");
          dot3.classList.remove("dot_white");
          dot3.classList.add("dot_color");

          const dot4 = document.getElementById("dot4");
          dot4.classList.remove("dot_color");
          dot4.classList.add("dot_white");
          
      } else if (visibleText === 4 && animationState !== "animating") {
        // setVisibleText(visibleText - 1);
        setAnimationState("animating");
        down4.reverse();
        const eleL2R = document.getElementById("truckL2R");
          eleL2R.classList.remove("truck1_opacity_less");
          eleL2R.classList.add("truck1_opacity_less");

          const eleR2L = document.getElementById("truckR2L");
          eleR2L.classList.remove("truck2_opacity_less");
          eleR2L.classList.add("truck2_opacity_less");

          const dot4 = document.getElementById("dot4");
          dot4.classList.remove("dot_white");
          dot4.classList.add("dot_color");

          const dot5 = document.getElementById("dot5");
          dot5.classList.remove("dot_color");
          dot5.classList.add("dot_white");
      } else if (visibleText === 5 && animationState !== "animating") {
        // setVisibleText(visibleText - 1);
        setAnimationState("animating");
        // const ele = document.getElementById('truckL2R');
        // ele.classList.remove('truck1_1');
        // ele.classList.add('truck1')
        down5.reverse();
        const eleL2R = document.getElementById("truckL2R");
          eleL2R.classList.remove("truck1_stop");
          eleL2R.classList.add("truck1_opacity_less");

          const eleR2L = document.getElementById("truckR2L");
          eleR2L.classList.remove("truck2_stop");
          eleR2L.classList.add("truck2_opacity_less");

          const eleUpward = document.getElementById("star2upward");
          eleUpward.classList.remove("coin_upward_visible");
          eleUpward.classList.add("coin_upward");

          const eleSmall_coin2 = document.getElementById("star1Small_coin2");
          eleSmall_coin2.classList.remove("Small_coin2_visible");
          eleSmall_coin2.classList.add("Small_coin2");

          const elesmall_coin1 = document.getElementById("star1small_coin1");
          elesmall_coin1.classList.remove("small_coin1_visible");
          elesmall_coin1.classList.add("small_coin1");

          const eleDownward = document.getElementById("star3downward");
          eleDownward.classList.remove("coin_downward_visible");
          eleDownward.classList.add("coin_downward");

          const dot5 = document.getElementById("dot5");
          dot5.classList.remove("dot_white");
          dot5.classList.add("dot_color");

          const dot6 = document.getElementById("dot6");
          dot6.classList.remove("dot_color");
          dot6.classList.add("dot_white");

      } else if (visibleText === 6 && animationState !== "animating") {
        setAnimationState("animating");
        down6.reverse();
        // const eleUpward = document.getElementById("star2upward");
        // eleUpward.classList.remove("coin_upward");
        // eleUpward.classList.add("coin_upward_visible");

        // const eleSmall_coin2 = document.getElementById("star1Small_coin2");
        // eleSmall_coin2.classList.remove("Small_coin2");
        // eleSmall_coin2.classList.add("Small_coin2_visible");

        // const elesmall_coin1 = document.getElementById("star1small_coin1");
        // elesmall_coin1.classList.remove("small_coin1");
        // elesmall_coin1.classList.add("small_coin1_visible");

        // const eleDownward = document.getElementById("star3downward");
        // eleDownward.classList.remove("coin_downward");
        // eleDownward.classList.add("coin_downward_visible");

        const dot6 = document.getElementById("dot6");
          dot6.classList.remove("dot_white");
          dot6.classList.add("dot_color");

          const dot7 = document.getElementById("dot7");
          dot7.classList.remove("dot_color");
          dot7.classList.add("dot_white");
      }
      //  else if (visibleText === 7 && animationState !== "animating") {
      //   setAnimationState("animating");
      //   console.log("upKey7");
      //   down7.reverse();
      //   const dot7 = document.getElementById("dot7");
      //   dot7.classList.remove("dot_white");
      //   dot7.classList.add("dot_color");

      //     const dot8 = document.getElementById("dot8");
      //     dot8.classList.remove("dot_color");
      //     dot8.classList.add("dot_white");
      // }
      else if (visibleText === 7 && animationState !== "animating") {
        setAnimationState("animating");
        console.log("upKey7");
        down7.reverse();

        const dot7 = document.getElementById("dot7");
        dot7.classList.remove("dot_white");
        dot7.classList.add("dot_color");

        const dot8 = document.getElementById("dot8");
        dot8.classList.remove("dot_color");
        dot8.classList.add("dot_white");
      }
    }

    function scrollDownController() {
      if (visibleText === 0 && animationState !== "animating") {
        setAnimationState("animating");
        down1.restart();
        console.log("down1", visibleText);

        const dot1 = document.getElementById("dot1");
        dot1.classList.remove("dot_color");
        dot1.classList.add("dot_white");

        const dot2 = document.getElementById("dot2");
        dot2.classList.remove("dot_white");
        dot2.classList.add("dot_color");

      } else if (visibleText === 1 && animationState !== "animating") {
        // setVisibleText(visibleText + 1);
        setAnimationState("animating");
        down2.restart();
        console.log("down2", visibleText);
        const eleL2R = document.getElementById("truckL2R");
          eleL2R.classList.remove("truck1");
          eleL2R.classList.add("truck1_opacity_less");

          const eleR2L = document.getElementById("truckR2L");
          eleR2L.classList.remove("truck2");
          eleR2L.classList.add("truck2_opacity_less");

          const dot2 = document.getElementById("dot2");
          dot2.classList.remove("dot_color");
          dot2.classList.add("dot_white");

          const dot3 = document.getElementById("dot3");
          dot3.classList.remove("dot_white");
          dot3.classList.add("dot_color");

      } else if (visibleText === 2 && animationState !== "animating") {
        // setVisibleText(visibleText + 1);
        setAnimationState("animating");
        down3.restart();
        console.log("down3", visibleText);
        const eleL2R = document.getElementById("truckL2R");
        eleL2R.classList.remove("truck1_opacity_less");
        eleL2R.classList.add("truck1_opacity_less");

        const eleR2L = document.getElementById("truckR2L");
        eleR2L.classList.remove("truck2_opacity_less");
        eleR2L.classList.add("truck2_opacity_less");

        const dot3 = document.getElementById("dot3");
          dot3.classList.remove("dot_color");
          dot3.classList.add("dot_white");

          const dot4 = document.getElementById("dot4");
          dot4.classList.remove("dot_white");
          dot4.classList.add("dot_color");
      } else if (visibleText === 3 && animationState !== "animating") {
        // setVisibleText(visibleText + 1);
        setAnimationState("animating");
        down4.restart();
        console.log("down4", visibleText);
        const eleL2R = document.getElementById("truckL2R");
          eleL2R.classList.remove("truck1_opacity_less");
          eleL2R.classList.add("truck1_opacity_less");

          const eleR2L = document.getElementById("truckR2L");
          eleR2L.classList.remove("truck2_opacity_less");
          eleR2L.classList.add("truck2_opacity_less");

          const dot4 = document.getElementById("dot4");
          dot4.classList.remove("dot_color");
          dot4.classList.add("dot_white");

          const dot5 = document.getElementById("dot5");
          dot5.classList.remove("dot_white");
          dot5.classList.add("dot_color");
      } else if (visibleText === 4 && animationState !== "animating") {
        // setVisibleText(visibleText + 1);
        setAnimationState("animating");
        // const ele = document.getElementById('truckL2R');
        //  ele.classList.remove('truck1');
        //  ele.classList.add('truck1_1')
        //  ele.style.opacity = 0;
        // console.log("ele", ele )
        down5.restart();
        console.log("down5", visibleText);
         const eleL2R = document.getElementById("truckL2R");
          eleL2R.classList.remove("truck1_opacity_less");
          eleL2R.classList.add("truck1_stop");

          const eleR2L = document.getElementById("truckR2L");
          eleR2L.classList.remove("truck2_opacity_less");
          eleR2L.classList.add("truck2_stop");

          const eleUpward = document.getElementById("star2upward");
          eleUpward.classList.remove("coin_upward");
          eleUpward.classList.add("coin_upward_visible");

          const eleSmall_coin2 = document.getElementById("star1Small_coin2");
          eleSmall_coin2.classList.remove("Small_coin2");
          eleSmall_coin2.classList.add("Small_coin2_visible");

          const elesmall_coin1 = document.getElementById("star1small_coin1");
          elesmall_coin1.classList.remove("small_coin1");
          elesmall_coin1.classList.add("small_coin1_visible");

          const eleDownward = document.getElementById("star3downward");
          eleDownward.classList.remove("coin_downward");
          eleDownward.classList.add("coin_downward_visible");

          const dot5 = document.getElementById("dot5");
          dot5.classList.remove("dot_color");
          dot5.classList.add("dot_white");

          const dot6 = document.getElementById("dot6");
          dot6.classList.remove("dot_white");
          dot6.classList.add("dot_color");
      } else if (visibleText === 5 && animationState !== "animating") {
        // setVisibleText(visibleText + 1);
        setAnimationState("animating");
        down6.restart();
        console.log("down6", visibleText);
        const eleUpward = document.getElementById("star2upward");
          eleUpward.classList.remove("coin_upward_visible");
          eleUpward.classList.add("coin_upward");

          const eleSmall_coin2 = document.getElementById("star1Small_coin2");
          eleSmall_coin2.classList.remove("Small_coin2_visible");
          eleSmall_coin2.classList.add("Small_coin2");

          const elesmall_coin1 = document.getElementById("star1small_coin1");
          elesmall_coin1.classList.remove("small_coin1_visible");
          elesmall_coin1.classList.add("small_coin1");

          const eleDownward = document.getElementById("star3downward");
          eleDownward.classList.remove("coin_downward_visible");
          eleDownward.classList.add("coin_downward");

          const dot6 = document.getElementById("dot6");
          dot6.classList.remove("dot_color");
          dot6.classList.add("dot_white");

          const dot7 = document.getElementById("dot7");
          dot7.classList.remove("dot_white");
          dot7.classList.add("dot_color");
      }
      //  else if (visibleText === 6 && animationState !== "animating") {
      //   setAnimationState("animating");
      //   down7.restart();
      //   console.log("down7", visibleText);

      //   const dot7 = document.getElementById("dot7");
      //   dot7.classList.remove("dot_color");
      //   dot7.classList.add("dot_white");

      //   const dot8 = document.getElementById("dot8");
      //   dot8.classList.remove("dot_white");
      //   dot8.classList.add("dot_color");
      // }
      else if (visibleText === 6 && animationState !== "animating") {
        // setVisibleText(visibleText + 1);
        setAnimationState("animating");
        down7.restart();
        console.log("down7", visibleText);

        const dot7 = document.getElementById("dot7");
        dot7.classList.remove("dot_color");
        dot7.classList.add("dot_white");

        const dot8 = document.getElementById("dot8");
        dot8.classList.remove("dot_white");
        dot8.classList.add("dot_color");
      }
    }

    const downArrow = document.getElementById('scscrollGif_box');
    downArrow.addEventListener('click', function downArrowClick () {
      scrollDownController()
    })

    // var prevTime = new Date().getTime();
    var fireEvent = true;
    var newDelta, oldDelta, eventTimeout;
    newDelta = oldDelta = eventTimeout = null;
    window.addEventListener("wheel", (ev) => {
      // console.log("wheel event")
      // const direction_1 = ev.deltaY;
      // var d = new Date().getTime();
      // if(typeof prev_time !== 'undefined')
      //   console.log(d-prev_time);
      // prev_time = d;

      // if (direction_1 < 0) {
      //   // console.log('scrolling up1');
      //   scrollUpController();
      // }
      // if (direction_1 > 0) {
      //   // console.log('scrolling down');
      //   scrollDownController();
      // }
      // ev.preventDefault()

      // Approach 2
      // var curTime = new Date().getTime();
      // if (typeof prevTime !== 'undefined') {
      //   var timeDiff = curTime - prevTime;
      //   if (timeDiff > 180) {
      //     const direction_1 = ev.deltaY;
      //     if (direction_1 < 0) {
      //       // console.log('scrolling up1');
      //       scrollUpController();
      //     }
      //     if (direction_1 > 0) {
      //       // console.log('scrolling down');
      //       scrollDownController();
      //     }
      //     console.log('New kinetic scroll has started!');
      //   }
      // }
      // prevTime = curTime;

      // Approach 3
      if (!fireEvent) return; // if fireEvent is not allowed => stop execution here ('return' keyword stops execution of the function), else, execute code below:
      newDelta = ev.deltaY;
      console.log("here", newDelta, oldDelta, fireEvent);
      if (oldDelta != null && oldDelta * newDelta > 0) {
        console.log("here 1", newDelta, oldDelta, eventTimeout); // (1.1) if it's not the first event and directions are the same => prevent possible dublicates for further 50ms:
        fireEvent = false;
        clearTimeout(eventTimeout); // clear previous timeouts. Important!
        eventTimeout = setTimeout(function () {
          fireEvent = true;
        }, 2000);
      }
      oldDelta = newDelta;
      // xsomeEventCallback(); // (1.2) fire further functions...
      if (newDelta < 0) {
        // console.log('scrolling up1');
        scrollUpController();
      }
      if (newDelta > 0) {
        // console.log('scrolling down');
        scrollDownController();
      }
      // ev.preventDefault()
    });
  }, []);

  //gif animation

  // function DelayloadingImages() {
  //   var imgDiv = document.getElementById("divImages");
  //   var images = imgDiv.getElementsByTagName("img");

  //   for (var i = 0; i < images.length; i++) {
  //     images[i].setAttribute("src");
  //   }
  // }
  // setTimeout(() => {
  //   DelayloadingImages();
  // }, 3000);

  return (
    <div className="home_page">
      {/* <Helmet>
            <title>NAKAD | Pioneers of tokenized supply chain finance</title>
            <meta name="description" content="Unlocking small businesses of cash flow burden with unique and proprietary MicroBill technology enabling lending and loans to MSMEs through invoice discounting, invoice factoring, invoice reverse factoring and thereby getting early payment. The software has in-built reconciliation for account receivable, account payable, payments, debit note, credit note and banking." />
      </Helmet> */}
      <div
        className="home_1_container section01"
        id="sectiontest1"
        style={{
          zIndex: 1,
          opacity: 1,
        }}
      >
        <div className="left">
          <img src={Group1} alt="" className="group1" />
          <img src={Group2} alt="" className="group2" />
          <img src={Group3} alt="" className="group3" />
          <img src={Group4} alt="" className="group4" />
          <img src={Group5} alt="" className="group5" />

          <div className="text_container_unlock" id="divtest1">
            <div className="text">
              <span>Unlocking small businesses </span>
              <br />
              <span>
                of <span className="color_text">cash flow</span> burden
              </span>
            </div>
          </div>

          {/* --------mobile-start------ */}
          <div className="text_container_unlock_mobile" id="divtest1">
            <div className="text">
              Unlocking small businesses of
              <span className="color_text"> cash flow </span>burden
            </div>
          </div>
          {/* ---------mobile-end------ */}

          <div className="text_container" id="divtest2" style={{ opacity: 0 }}>
            <div className="text second_text">
              <span>
                <span className="color_text">Working Capital</span> Constraint
              </span>{" "}
              <br />
              <span>of Tier 2</span> <br />
              <span>
                Impacts the <span className="color_text">Supply chain </span>
                of
              </span>
              <br />
              <span>Tier-1 and Anchor</span>
            </div>
          </div>

          <div className="text_container" id="divtest3" style={{ opacity: 0 }}>
            <div className="text">
              <div>
                <span>
                  <span className="color_text">Working Capital</span> Constraint
                </span>{" "}
                <br />
                <span>of Tier 2</span> <br />
              </div>
              <div className="box">
                <div className="left_box"></div>
                <div className="right_box">
                  <ul>
                    <li>High cost of credit</li>
                    <li>Inability to procure and supply on time</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="text_container" id="divtest4" style={{ opacity: 0 }}>
            <div className="text">
              <span>
                <span className="color_text">Supply Chain</span> Problem of
              </span>{" "}
              <br />
              <span>Tier 1</span> <br />
              <div className="box">
                <div className="left_box"></div>
                <div className="right_box">
                  <ul>
                    <li>Procurement bottlenecks</li>
                    <li>Higher cost</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="text_container" id="divtest5" style={{ opacity: 0 }}>
            <div className="text">
              <span>
                <span className="color_text">Supply Chain</span> Problem of
              </span>{" "}
              <br />
              <span>Anchor</span> <br />
              <div className="box">
                <div className="left_box"></div>
                <div className="right_box">
                  <ul>
                    <li>Supply chain management issues</li>
                    <li>Higher cost</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            className="text_container_cashflow"
            id="divtest6"
            style={{ opacity: 0 }}
          >
            <div className="text">
              <span>
                <span className="color_text">NAKAD</span> - the{" "}
              </span>
              <br />
              <span>secret sauce to</span> <br />
              <span>
                the <span className="color_text">cashflow</span>
              </span>
              <br />
              <span className="color_text">problem</span>
            </div>
          </div>

          {/* --------mobile-start------ */}
          <div
            className="text_container_cashflow_mobile"
            id="divtest6"
            style={{ opacity: 0 }}
          >
            <div className="text">
              <span>
                <span className="color_text">NAKAD</span> - the{" "}
              </span>
              {/* <br /> */}
              <span>secret sauce to </span>
              <span>
                the <span className="color_text"> cashflow</span>
              </span>
              {/* <br /> */}
              <span className="color_text"> problem</span>
            </div>
          </div>
          {/* --------mobile-end------ */}
        </div>

        <div className="right">
          <img src={Tier2} alt="" className="tier2" id="img_1" />
          <img src={Tier1} alt="" className="tier1" id="img_2" />
          <img src={Anchor} alt="" className="anchor" id="img_3" />
          <img src={Lender} alt="" className="lender" id="img_4" />
        </div>

        <div className="lender_text_box">
          <p className="lender_text">LENDER</p>
          <p className="lender_sub_text">Provides Early payment </p>
        </div>
        <div className="anchor_text_box">
          <p className="anchor_text">ANCHOR</p>
          <p className="anchor_sub_text">Major OEM Player</p>
        </div>

        <div className="tier1_text_box">
          <p className="tier1_text">TIER 1</p>
          <p className="tier1_sub_text">Supplier to Anchor</p>
        </div>

        <div className="tier2_text_box">
          <p className="tier2_text">TIER 2</p>
          <p className="tier2_sub_text">Supplier to Tier 1</p>
        </div>

        {/* <img src={Truck1} alt="" className="truck1" />
        <img src={Truck2} alt="" className="truck2" /> */}

        <img src={PathL2R} alt="" className="pathL2R" />
        <img src={PathR2L} alt="" className="pathR2L" />
        <img src={TruckL2R} alt="" className="truck1" id="truckL2R" />
        <img src={TruckR2L} alt="" className="truck2" id="truckR2L" />

        <img src={Arrow1} alt="" className="arrow1" />
        <img src={Arrow2} alt="" className="arrow2" />
        <img src={Arrow3} alt="" className="arrow3" />

        {/* <div id="divImages" style={{position:'absolute'}}> */}
        {/* <img src={Star1} alt="" className="star1" />
        <img src={Star2} alt="" className="star2" />
        <img src={Star3} alt="" className="star3" /> */}

        {/* </div> */}

        {/* <img src={TruckD} alt="" className="truckD L2R_truck"/> */}
        <img
          src={Star2upward}
          alt=""
          className="coin_upward"
          id="star2upward"
        />
        <img
          src={coin1R2L}
          alt=""
          className="Small_coin2"
          id="star1Small_coin2"
        />
        <img
          src={coin1R2L}
          alt=""
          className="small_coin1"
          id="star1small_coin1"
        />
        <img
          src={coin1R2L}
          alt=""
          className="coin_downward"
          id="star3downward"
        />

        {/* ecosystem */}

        <div className="ecosystem" style={{ opacity: 0 }}>
          <div className="title">
            <div className="text">
              <span className="color_text">Benefiting </span>{" "}
              <span>the Entire </span>{" "}
              <span className="color_text">Ecosystem</span>
            </div>
          </div>
          <div className="eco_box">
            <div className="inside_eco">
              <div className="img_box">
                <img src={Anchor} alt="" className="anchor" />
              </div>
              <div className="eco_text">
                <span>ANCHOR</span>
              </div>
              <div className="box">
                <div className="left_box"></div>
                <div className="right_box">
                  <ul className="listStyleCircle">
                    <li>Cheaper input cost</li>
                    <li>Build Treasury Income</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="inside_eco">
              <div className="img_box">
                <img src={Tier1} alt="" className="anchor" />
              </div>
              <div className="eco_text">
                <span>TIER 1</span>
              </div>
              <div className="box">
                <div className="left_box"></div>
                <div className="right_box">
                  <ul>
                    <li>Eradicate procurement bottleneck</li>
                    <li>Control quality issues</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="inside_eco">
              <div className="img_box">
                <img src={Tier2} alt="" className="anchor" />
              </div>
              <div className="eco_text">
                <span>TIER 2</span>
              </div>
              <div className="box">
                <div className="left_box"></div>
                <div className="right_box">
                  <ul>
                    <li>Cheaper working capital</li>
                    <li>Reliable credit line</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="inside_eco">
              <div className="img_box">
                <img src={Lender} alt="" className="lender" />
              </div>
              <div className="eco_text">
                <span>LENDER</span>
              </div>
              <div className="box">
                <div className="left_box"></div>
                <div className="right_box">
                  <ul>
                    <li>Large untapped market</li>
                    <li>~0 OpEx and ~0 NPA</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ecosystem_mobile" style={{ opacity: 0 }}>
          <div className="title">
            <div className="text">
              <span className="color_text">Benefiting </span>{" "}
              <span>the Entire </span>{" "}
              <span className="color_text">Ecosystem</span>
            </div>
          </div>
          <div>
            <Carousel
              responsive={responsive}
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={300000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              <div>
                <div className="card1">
                  <div className="inside_eco">
                    <div>
                      <img src={Anchor} alt="" className="anchor" />
                    </div>
                    <div className="eco_text">
                      <span>ANCHOR</span>
                    </div>
                    <div className="box">
                      <div className="left_box"></div>
                      <div className="right_box">
                        <ul className="listStyleCircle">
                          <li>Cheaper input cost</li>
                          <li>Build Treasury Income</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="card1">
                  <div className="inside_eco">
                    <div>
                      <img src={Tier1} alt="" className="anchor" />
                    </div>
                    <div className="eco_text">
                      <span>TIER 1</span>
                    </div>
                    <div className="box">
                      <div className="left_box"></div>
                      <div className="right_box">
                        <ul>
                          <li>Eradicate procurement bottleneck</li>
                          <li>Control quality issues</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="card1">
                  <div className="inside_eco">
                    <div>
                      <img src={Tier2} alt="" className="anchor" />
                    </div>
                    <div className="eco_text">
                      <span>TIER 2</span>
                    </div>
                    <div className="box">
                      <div className="left_box"></div>
                      <div className="right_box">
                        <ul>
                          <li>Cheaper working capital</li>
                          <li>Reliable credit line</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="card1">
                  <div className="inside_eco">
                    <div>
                      <img src={Lender} alt="" className="lender" />
                    </div>
                    <div className="eco_text">
                      <span>LENDER</span>
                    </div>
                    <div className="box">
                      <div className="left_box"></div>
                      <div className="right_box">
                        <ul>
                          <li>Large untapped market</li>
                          <li>~0 OpEx and ~0 NPA</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
        {/* <div className="media_page_new" style={{ opacity: 0 }}>
        <div className="media_page_section">
          <div className="vcfirm">
            <div className="vcfirm_left">
              <div className="vcfirm_text">
                <span>Backed by World’s</span> 
                <span className="vcfirm_color_text">top VC firms</span>
                
                <div className="vcfirm_img">
                  <div className="vcfirm_circle"><img src={matrixPartners} className="matrix" alt="" /></div>
                  <div className="vcfirm_circle"><img src={Accel} className="accel" alt="" /></div>
                  <div className="vcfirm_circle"><img src={Advant} className="advant" alt="" /></div>
                </div>
              </div>
            </div>
            <div className="vcfirm_right">
              <div className="rocket_box">
                <img src={Rocket} alt="" className="rocket" />
               
              </div>
            </div>
          </div>
          <div className="media">
                  <div className="media_text">
                    <span>Nakad in </span>{" "}
                    <span className="media_color_text">Media</span>
                  </div>
                  {showMedia ? 
                   <div>
                   <Carousel
                     responsive={responsive}
                     additionalTransfrom={0}
                     arrows
                     autoPlaySpeed={3000000}
                     centerMode={false}
                     className=""
                     containerClass="container-with-dots"
                     dotListClass=""
                     draggable
                     focusOnSelect={false}
                     infinite
                     itemClass=""
                     keyBoardControl
                     minimumTouchDrag={80}
                     renderButtonGroupOutside={false}
                     renderDotsOutside={false}
                     showDots={false}
                     sliderClass=""
                     slidesToSlide={1}
                     swipeable
                   >
                     <a href="https://economictimes.indiatimes.com/tech/funding/supply-chain-financing-provider-nakad-raises-7-million-funding/articleshow/91773253.cms" target='_blank'>
                     <div className="media_img">
                       <img src={ETlogo} alt="" />
                       <p className="img_text">
                       Supply-chain financing provider Nakad raises $7 million funding
                       </p>
                     </div>
                     </a>
                     <a href="https://www.techinasia.com/exclusive-accel-matrix-colead-7m-indian-fintech-firm" target='_blank'>
                     <div className="media_img">
                       <img src={TechAsialogo} alt="" />
                       <p className="img_text">
                       Nakad raises $7m in seed round co-led by Accel, Matrix
                       </p>
                     </div>
                     </a>
                     <a href="https://inc42.com/buzz/fintech-startup-nakad-raises-7-mn-to-offer-working-capital-to-msmes/" target='_blank'>
                     <div className="media_img">
                       <img src={Inc42logo} alt="" />
                       <p className="img_text">
                       Fintech Startup NAKAD Raises $7 Mn To Offer Working Capital To MSMEs
                       </p>
                     </div>
                     </a>
                     <a href="https://www.vccircle.com/matrix-parners-accel-bet-on-msme-fintech-startup-nakad" target='_blank'>
                     <div className="media_img">
                       <img src={vcCirclelogo} className="vcCircle" alt="" />
                       <p className="img_text">
                       Matrix Parners, Accel bet on MSME fintech startup NAKAD
                       </p>
                     </div>
                     </a>
                   </Carousel>
                 </div>
                  : null}
                 
          </div>
        </div>
      </div> */}
      </div>

      

      {/* dots */}
      <div className="dots" id="allDots">
        <p className="dot_color" id="dot1"></p>
        <p className="dot_white" id="dot2"></p>
        <p className="dot_white" id="dot3"></p>
        <p className="dot_white" id="dot4"></p>
        <p className="dot_white" id="dot5"></p>
        <p className="dot_white" id="dot6"></p>
        <p className="dot_white" id="dot7"></p>
        <p className="dot_white" id="dot8"></p>
        {/* <p className="dot_white" id="dot9"></p> */}
      </div>

      <div
        className="media_page section09"
        id="sectiontest9"
         >
         <div className="join_nakad container">
          <div className="rect row">
            <div className="left_rect col-md-6 col">
              <img src={footerImg1} alt="" className="footerImg1"/>
            </div>
            <div className="right_rect col-md-6 col" id="footerBtn_home">
              <div className="text_box">
                <p className="text">Join the NAKAD tribe!</p>
                <p className="footer_text">We are looking for talented and highly driven folks on the journey to achieve 4000X growth!</p>
              </div>
              <div>
                <button className="footerBtn">
                 <a href="https://nakad.turbohire.co/" target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                  <p className="color_text">Are you one? Find a role <i className="bi bi-arrow-up-right"></i></p> 
                  </a>
                  </button>
              </div>
            </div>
          </div>
        </div>
        {/* <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-xs-12">
            <div className="copyright">
            <div className="copyright_text">
            © 2022 NAKAD. All rights reserved
            </div>
            </div>
           </div>
            <div className="col-md-4 col-xs-12">
            <div className="footer_img">
            <img src={footerImg} alt="footer" className='img-fluid' />
            </div>
           </div>
            <div className="col-md-4 col-xs-12" id="linkedin_home">
              <div className="quick_links">
              <div className='d_flex text_center mb_10 '>
              <div className='mr_20 fs_12_quick_links'>
              <Link to="/privacy-policy" target="_blank" style={{ color: 'wheat' }}>Privacy Policy</Link>
                </div>
              <div className='mr_20 fs_12_quick_links'>
                  <Link to="/terms-of-use" target="_blank" style={{ color: 'wheat' }}>Terms of Use</Link>
                </div>
              <div className='mr_20 fs_12_quick_links'>
                  <Link to="/disclaimer" target="_blank" style={{ color: 'wheat' }}>Disclaimer</Link>
                </div>
                <div className="fs_12_quick_links">
                  <Link to="/faqs" target="_blank" style={{ color: 'wheat' }}>FAQs</Link>
                </div>
                </div>
            <div className="social">
                <div className="icon_div">
                <div className="icon">   
                <a href="https://www.linkedin.com/company/nakadco/" target='_blank' rel="noreferrer">
                    <img src={linkedin} alt="" />
                </a>
              </div>
                  <div className="icon">
                  <a href="https://www.instagram.com/nakad.co/" target='_blank' rel="noreferrer">
                      <img src={insta} alt="" />
                   </a>
                  </div>
               
                    
             
                  <div className="icon">
                   <a href="https://twitter.com/NAKADaaj?s=20&t=TQNoi8vsZDO0mXy0hQ3YeQ" target='_blank' rel="noreferrer">
                   <img src={twitter} alt="" />
                   </a>
                  </div>
            
            </div>
          </div>
          </div>
         </div>
          </div>
     </div>
        </footer> */}
        <Footer />

      </div>

      {/* scroll down arrow gif */}
      <div className="scscrollGif_box">
        {/* <img src={scrollGif} alt="" id="scscrollGif_box" style={{cursor:'pointer'}}/> */}
        <svg className="arrows" id="scscrollGif_box"  transform="scale(0.7)">
          <path className="a1" d="M0 0 L30 32 L60 0"></path>
          <path className="a2" d="M0 20 L30 52 L60 20"></path>
          <path className="a3" d="M0 40 L30 72 L60 40"></path>
        </svg>
      </div>
    </div>
  );
};

export default Home_1;
